import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import Axios from 'axios';
import axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { subjectiveAuditsService } from '../../utils/rxjs-sharing';
import {
  SUBJECTIVE_INFRMATION,
  SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS,
  CUSTOM_SUBJECTIVE_INFRMATION,
  VIEW_VISIT,
  INSTRUCTION_SEARCH,
  LAB_TEST_SEARCH,
  LAB_TEST_FAVOURITE,
  LOADER_RED,
  LOADER,
  PATIENT_DATA,
  MEDICINES_GETALL,
  PLAN_TEST_ADVICED_LIST,
  PATIENT_OBS_GYNAE_HX,
  ALLERGIES_GETALL,
  BSA_CALCULATE,
  COPY_VISIT_APPLY,
  ASSESSMENT_STATUS_UPDATE,
  PREVIOUS_VISIT_DETAILS,
  HOSPITALDEFAULTS,
  VIEW_TEMPLATE, BMI_CALCULATE,
  FETCH_SUPPORTING_MATERIAL,
  UPDATE_SUPPORTING_MATERIAL,
  CIMS_INTERACTION_EXISTS,
  CIMSINTERACTION,
  CIMSALLOW,
  CIMS_CAREPLAN_INTERACTION_EXISTS,
  CIMS_CAREPLAN_INTERACTION,
  SOAP_DETAILS,
  PARENT_INFRMATION_CUSTOM,
  SUBJECTIVE_INFRMATION_CLINICAL_NOTES
} from '../../utils/constant';
import moment from "moment";
import { Form, Modal, Input, Button, Select, DatePicker, Badge, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { notification } from 'antd';
import AsideLeft from '../../components/aside/asideleft';
import selecAllImg from '../../assets/images/ic-checkunselected.png';
import selecAllImg2 from '../../assets/images/ic-checkunselected2.png';
import SearchImg from '../../assets/images/ic-search.svg';
import icJpg from '../../assets/images/ic-jpg.png';
import icPdf from '../../assets/images/ic-pdf.png';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link } from 'react-router-dom';
import { headerService$, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../speech-to-text/speech-recognition";
import { jQueryLabels, FixedCta, AntSelectLabels, AntClearLabels } from '../../utils/jQueryLabels';
import './copy.visit.scss'
import AssessmentTable from '../../components/assessments/assessment-table';
import MedicineTable from '../../components/drugs/medicine-table';
import TestTable from '../../components/tests/test-table';
import InstructionTable from '../../components/instructions/instructions-table';
import SupportingMaterialTable from '../../components/supporting-material/supporting-material-table';
import Templatetests from '../create-template/template-test';
import AssessmentForm from '../../components/assessments/assessment-form';
import MedicineForm from '../../components/drugs/medicine-form';
import TestForm from '../../components/tests/test-form';
import InstructionsForm from '../../components/instructions/instructions-form';
import SupportingMaterial from '../../components/supporting-material/supporting-material-form';
import MedicineAlertPopup from './medicine-alert-popup';
const { Option } = Select;
const { RangePicker } = DatePicker;
let count = 0;
class CopyToVisitSS extends Component {
  constructor(props) {
    super(props);

    const doctor_id = LS_SERVICE.get("staff_id");
    const { match: { params }, location: { state } } = this.props;
    // QMS_TOKEN_ID IS COPY PRESCRIPTION REFERENCE ID AND REFERENCE ID IS CURRENT APPOINTMENT REFERNCE
    let { qms_token_id, reference_id, template_id } = params;
    reference_id = reference_id.split('?')[0];
    const { sameRxdate, patient } = state;
    let pathName = this.props.location.pathname;
    pathName = pathName.split('/');
    this.state = {
      type: this.props?.type || null,
      visible: this.props?.visible || null,
      patient,
      doctor_id,
      sameRxdate,
      qms_token_id,
      reference_id,
      testAdvisedDuplicateOrders: [],
      template_id,
      copyType: pathName.includes('template') ? 'template' : 'visit',
      DataLoaded: false,
      showAssessmentModal: false,
      already_prescribed_drugs: [],
      already_prescribed_tests: [],
      showMedicineModal: false,
      showTestModal: false,
      showInstructionModal: false,
      showSupportingModal: false,
      showDrugAllergyModal: false,
      showDrugPolyPharmacyModal: false,
      drugsnotcustom: 0,
      editData: null,
      editIndex: -1,
      subjectiveData: {},
      customSubjectiveData: {},
      constants: [],
      smallest_protocol: '',
      smallest_due_date: '',
      patientDrugAllergies: [],
      visitSubjectiveData: [], //visit from where data is copied
      assessmentData: [],
      addedAssessmentList: [],
      drugsData: [],
      addedDrugsList: [],
      testData: [],
      template: null,
      addedTestList: [],
      instructionsData: [],
      supportingMaterial: [],
      allSupportingMaterial: [],
      instructionsCheckSelected: false,
      supportingCheckSelected: false,
      chiefCompCheckSelected: false,
      physicalExaminationSelected: false,
      clinicalNotesSelected: false,
      actionText: '',
      deleteConfirmModal: false,
      deleteMessage: '',
      deleteActionType: null,
      deleteObjId: null,
      cimsallow: CIMSALLOW,
      drug_id_Interaction_Modalurl:[],
      referenceType:0,
      isValueChanged: false,
      patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
      FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
      enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
      
      // instructionOption: [],
      // isNewInstruction: false,
      // instructionselected: [],
      // searchval:'',
      spin_loading: false,
      isSelectAllChecked: false,
      CheckSelect: [],
      isOverRule: false,
      disableConfirmBtn: true,
      polyPharmacyWarningShown: false,
      allergiesMessage: '',
      polyPharmacyMessage: '',
      patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      speech: {
        identifier: null,
        state: false
      },
      customChildFieldData:[],
      customParentFieldData:[],
      customSubjectiveParentData: {},
      finalClinicalNotes: "",
      isEventBasedSS: false
    };
    this.checkCimsInteraction = false;
    this.chief_complaint = React.createRef();
    this.hpi = React.createRef();
    this.review_of_systems = React.createRef();
    this.subjectiveDataInitial = [];
    this.customSubjectiveDataInitial = [];
    this.customParentSubjectiveDataInitial = [];
    this.getMaterialData = this.getMaterialData.bind(this);
    this.updateSelect = this.updateSelect.bind(this);
    this.handleOnConfirmSubmit = this.handleOnConfirmSubmit.bind(this);
    this.customParentSubjectiveDataInitial = [];

  }

  onChangeCustomParentSubjectives = (e) => {
    let { customSubjectiveParentData } = this.state;
    var index = e.target.attributes.name.nodeValue;
    let data = e.target.value
    data = data.charAt(0).toUpperCase() + data.slice(1);
    customSubjectiveParentData.details[index] = data;
    this.setState({ customSubjectiveParentData });
  }

  setCustomParentSubjectiveLabel(custom_subject_id) {
    let name = '';
    let { ss_configuration, consult_type } = this.state;
    let custom_data = ss_configuration.find(p => ((p?.id === custom_subject_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
    if (custom_data) {
      name = custom_data?.custom?.value
    }
    return name;
  }

  setCustomParentSubjectiveValues(index, sameRxdate, customSubjectiveData, dataid, input_name) {
    let value = '';
    if (customSubjectiveData.details) {
      value = customSubjectiveData.details[input_name] !== null ? customSubjectiveData.details[input_name] : '';
    }
    this.customParentSubjectiveDataInitial[index] = { id: dataid, value: value };
    return this.customParentSubjectiveDataInitial[index] != undefined ? this.customParentSubjectiveDataInitial[index]['value'] : '';
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match: { params } } = nextProps;
    const { hospital_id, reference_id } = params;
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return reference_id;
  }

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }

  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const ss_configuration = LS_SERVICE.get('ss_configuration');
    const patient_full_data = LS_SERVICE.get('call_patient_detail');
    this.setState({
      ss_configuration: ss_configuration,
      consult_type: patient_full_data?.consult_type == 1 ? 'vc' : 'opd'
    })
    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }

    this.setState({
      hospital_id,
      patient_id,
      patient
    },async () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        await this.getPatientData();
      }
    });

    this.subscription = speechTextService.status().subscribe(data => {
      const { patient } = this.state;
      if (this.state.speech.identifier === 'chief_complaint') {
        let info = this.state.subjectiveData;
        info.details[0].description = data.text;
        this.setState({ subjectiveData: info });
        // LS_SERVICE.set("hpi_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
      }
      if (this.state.speech.identifier === 'review_of_systems') {
        let info = this.state.subjectiveData;
        info.details[1].description = data.text;
        this.setState({ subjectiveData: info });
        // LS_SERVICE.set("review_of_systems_" + patient.patient_id + "_" + patient.qms_token_id, data.text);
      }
      if(this.state.customSubjectiveData?.details){
        if (this.state.speech.identifier === 'custom1') {
          let info = this.state.customSubjectiveData;
          info.details[this.state.speech.identifier] = data.text;
          this.setState({ customSubjectiveData: info });
        }
        if (this.state.speech.identifier === 'custom2') {
          let info = this.state.customSubjectiveData;
          info.details[this.state.speech.identifier] = data.text;
          this.setState({ customSubjectiveData: info });
        }
        if (this.state.speech.identifier === 'custom3') {
          let info = this.state.customSubjectiveData;
          info.details[this.state.speech.identifier] = data.text;
          this.setState({ customSubjectiveData: info });
        }
        if (this.state.speech.identifier === 'custom4') {
          let info = this.state.customSubjectiveData;
          info.details[this.state.speech.identifier] = data.text;
          this.setState({ customSubjectiveData: info });
        }
        if (this.state.speech.identifier === 'custom5') {
          let info = this.state.customSubjectiveData;
          info.details[this.state.speech.identifier] = data.text;
          this.setState({ customSubjectiveData: info });
        }
      }
      if(this.state.customSubjectiveParentData?.details){
        if (this.state.speech.identifier === 'parent_custom1') {
          let info = this.state.customSubjectiveParentData;
          info.details["parent_custom1"] = data.text;
          this.setState({ customSubjectiveParentData: info });
        }
        if (this.state.speech.identifier === 'parent_custom2') {
          let info = this.state.customSubjectiveParentData;
          info.details["parent_custom2"] = data.text;
          this.setState({ customSubjectiveParentData: info });
        }
        if (this.state.speech.identifier === 'parent_custom3') {
          let info = this.state.customSubjectiveParentData;
          info.details["parent_custom3"] = data.text;
          this.setState({ customSubjectiveParentData: info });
        }
        if (this.state.speech.identifier === 'parent_custom4') {
          let info = this.state.customSubjectiveParentData;
          info.details["parent_custom4"] = data.text;
          this.setState({ customSubjectiveParentData: info });
        }
        if (this.state.speech.identifier === 'parent_custom5') {
          let info = this.state.customSubjectiveParentData;
          info.details["parent_custom5"] = data.text;
          this.setState({ customSubjectiveParentData: info });
        }
      }
      if (this.state.speech.identifier === 'clinical_notes') {
        let info = this.state.finalClinicalNotes;
        info = data.text;
        this.setState({ finalClinicalNotes: info });
      }
      this.setState({
        datetime: new Date()
      });
      // document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
    });

    this.getVisitTestList();

    // this.setState({supportingMaterial: this.state.supportingMaterial});
  }


  getVisitTestList = async _ => {
    const { patient_id, qms_token_id } = this.state.patient;
    // this.setState({testDataLoaded:false})

    const planTestAdvPromise = await Axios.get(PLAN_TEST_ADVICED_LIST({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id, patient_id }))
    if (planTestAdvPromise.data.status === 'success') {
      const { constants, details, duplicateOrderTests } = planTestAdvPromise.data.data;
      this.setState({
        testAdvisedDuplicateOrders: duplicateOrderTests,
      })
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      jQueryLabels();
      FixedCta();
      let Elem = document.querySelectorAll('.subjective-field');
      Elem.forEach(function (el) {
        el.style.height = el.scrollHeight + "px";
      })
    }, 100);
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'chief_complaint') {
      initialText = this.subjectiveDataInitial[0] != undefined ? this.subjectiveDataInitial[0]['value'] : '';
    }
    /* if (info.identifier === 'hpi') {
      initialText = this.subjectiveDataInitial[1] != undefined ? this.subjectiveDataInitial[1]['value'] : '';
    } */
    if (info.identifier === 'review_of_systems') {
      initialText = this.subjectiveDataInitial[1] != undefined ? this.subjectiveDataInitial[1]['value'] : '';
    }
    if (info.identifier === 'custom1') {
      initialText = this.customSubjectiveDataInitial[0] != undefined ? this.customSubjectiveDataInitial[0]['value'] : '';
    }
    if (info.identifier === 'custom2') {
      initialText = this.customSubjectiveDataInitial[1] != undefined ? this.customSubjectiveDataInitial[1]['value'] : '';
    }
    if (info.identifier === 'custom3') {
      initialText = this.customSubjectiveDataInitial[2] != undefined ? this.customSubjectiveDataInitial[2]['value'] : '';
    }
    if (info.identifier === 'custom4') {
      initialText = this.customSubjectiveDataInitial[3] != undefined ? this.customSubjectiveDataInitial[3]['value'] : '';
    }
    if (info.identifier === 'custom5') {
      initialText = this.customSubjectiveDataInitial[4] != undefined ? this.customSubjectiveDataInitial[4]['value'] : '';
    }

    if(info.identifier == "parent_custom1"){
      initialText = this.customParentSubjectiveDataInitial[0] != undefined ? this.customParentSubjectiveDataInitial[0]['value'] : '';
    }
    if(info.identifier == "parent_custom2"){
      initialText = this.customParentSubjectiveDataInitial[1] != undefined ? this.customParentSubjectiveDataInitial[1]['value'] : '';
    }
    if(info.identifier == "parent_custom3"){
      initialText = this.customParentSubjectiveDataInitial[2] != undefined ? this.customParentSubjectiveDataInitial[2]['value'] : '';
    }
    if(info.identifier == "parent_custom4"){
      initialText = this.customParentSubjectiveDataInitial[3] != undefined ? this.customParentSubjectiveDataInitial[3]['value'] : '';
    }
    if(info.identifier == "parent_custom5"){
      initialText = this.customParentSubjectiveDataInitial[4] != undefined ? this.customParentSubjectiveDataInitial[4]['value'] : '';
    }
    if(info.identifier == "clinical_notes"){
      initialText = this.state.finalClinicalNotes != undefined ? this.state.finalClinicalNotes : "";
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {
        speechService.init(this.state.speech);
      });
    } else {
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);
        // setTimeout(() => {
        this.setState({
          speech: {
            identifier: info.identifier,
            state: true,
            initialText: initialText
          }
        }, () => {
          speechService.init(this.state.speech);
        });

        // }, 1000);
      });
    }
  }

  // initSpeech(info) {
  //   let initialText = '';
  //   if (info.identifier === 'chief_complaint') {
  //     initialText = this.subjectiveDataInitial[0] != undefined ? this.subjectiveDataInitial[0]['value'] : '';
  //   }
  //   /* if (info.identifier === 'hpi') {
  //     initialText = this.subjectiveDataInitial[1] != undefined ? this.subjectiveDataInitial[1]['value'] : '';
  //   } */
  //   if (info.identifier === 'review_of_systems') {
  //     initialText = this.subjectiveDataInitial[1] != undefined ? this.subjectiveDataInitial[1]['value'] : '';
  //   }
  //   if (info.identifier === 'custom1') {
  //     initialText = this.customSubjectiveDataInitial[0] != undefined ? this.customSubjectiveDataInitial[0]['value'] : '';
  //   }
  //   if (info.identifier === 'custom2') {
  //     initialText = this.customSubjectiveDataInitial[1] != undefined ? this.customSubjectiveDataInitial[1]['value'] : '';
  //   }
  //   if (info.identifier === 'custom3') {
  //     initialText = this.customSubjectiveDataInitial[2] != undefined ? this.customSubjectiveDataInitial[2]['value'] : '';
  //   }
  //   if (info.identifier === 'custom4') {
  //     initialText = this.customSubjectiveDataInitial[3] != undefined ? this.customSubjectiveDataInitial[3]['value'] : '';
  //   }
  //   if (info.identifier === 'custom5') {
  //     initialText = this.customSubjectiveDataInitial[4] != undefined ? this.customSubjectiveDataInitial[4]['value'] : '';
  //   }

  //   if (info.identifier === this.state.speech.identifier) {
  //     this.setState({
  //       speech: {
  //         identifier: info.identifier,
  //         state: !this.state.speech.state,
  //         initialText: initialText
  //       }
  //     }, () => {
  //       speechService.init(this.state.speech);
  //     });
  //   } else {
  //     this.setState({
  //       speech: {
  //         identifier: this.state.speech.identifier,
  //         state: false,
  //         initialText: ''
  //       }
  //     }, () => {
  //       speechService.init(this.state.speech);
  //       // setTimeout(() => {
  //       this.setState({
  //         speech: {
  //           identifier: info.identifier,
  //           state: true,
  //           initialText: initialText
  //         }
  //       }, () => {
  //         speechService.init(this.state.speech);
  //       });

  //       // }, 1000);
  //     });
  //   }
  // }

  // updateModalStatus = (property) =>{
  //   this.setState({[property]:!this.state[property]})
  // }


  updateAssessmentData = (data) => {
    data.selected = true;
    let assessmentData = this.state.assessmentData;
    let addedAssessmentList = [];
    var index = data.i;
    if (index == -1)
      index = assessmentData.length;
    if (data.image_uploaded == -1)
      data.image = assessmentData[index].image

    else
      data.image = data.image_uploaded;
    delete data.image_uploaded;
    assessmentData[index] = data;

    addedAssessmentList = assessmentData.map((v, k) => { return v.diagnosis_id });

    this.setState({
      assessmentData, addedAssessmentList, editData: null, editIndex: -1
    }, () => {
      this.handleModalPopup(null, 'showAssessmentModal'); this.checkSelectedStatus();
    })
  }

  updateMedicineData = async (data, showTaper) => {
    data.selected = true;
    let drugsData = this.state.drugsData;
    let addedDrugsList = [];
    var index = data.i;
    if (index == -1)
      index = drugsData.length;
    drugsData[index] = data;

    addedDrugsList = drugsData.map((v, k) => {
      return v.drug_id
    });

    let previousEndDate = null;
    for (let i = (index); i >= 0; i--) {
      if (drugsData[i].end_date != null && previousEndDate == null)
        previousEndDate = drugsData[i].end_date;
    }
    var final_list_drug = [];
    for (var thisdrug of drugsData) {
      if (thisdrug.drug_id == data.drug_id) {
        if (data.correction_dose == 'Y' || data.correction_dose_applicable == 'Y') {
          thisdrug.correction_dose = 'Y';
          thisdrug.correction_dose_applicable = 'Y';
          thisdrug.correction_dose_data = data.correction_dose_data;
        }
        final_list_drug.push(thisdrug)
      }
      else {
        final_list_drug.push(thisdrug)
      }
    }

    await this.updateMedicineStartEndDate(final_list_drug, previousEndDate, index + 1, drugsData[index].drug_id);
    var start_date_array = [];
    var protocol_array = [];
    for (var thisdrug of drugsData) {
      if (thisdrug.event && thisdrug.event > 0) {
        if (thisdrug.event == 1) {
          protocol_array.push('LMP + ' + thisdrug?.lmp_event_duration + ' ' + thisdrug?.lmp_event_duration_type)
        }
        else if (thisdrug.event == 2) {
          protocol_array.push('Birth Date + ' + thisdrug?.lmp_event_duration + ' ' + thisdrug?.lmp_event_duration_type)
        }
        else if (thisdrug.event == 3) {
          protocol_array.push('System Date')
        }
        else if (thisdrug.event == 4) {
          protocol_array.push('Custom Date')
        }
        start_date_array.push(new Date(thisdrug.start_date))
      }
    }
    // var smallest_index = start_date_array.map(Number).indexOf(+new Date(Math.min.apply(null, start_date_array)))
    // var smallest_protocol = protocol_array.slice(smallest_index,smallest_index+1);
    var smallest_start_date = new Date(Math.min.apply(null, start_date_array));
    this.setState({
      // smallest_protocol: smallest_protocol,
      smallest_due_date: moment(smallest_start_date)
    })
    this.setState({
      drugsData, addedDrugsList, editData: null, editIndex: -1
    }, () => {
      if (!showTaper) this.handleModalPopup(null, 'showMedicineModal'); this.checkSelectedStatus();
    })
  }

  updateMedicineDate = async (data) => {
    let medicine_data = data.medicine_data;
    let start_data = data.start_data;
    let this_drug_id = data.this_drug_id;
    var updateddrug = [];
    var previousDrugID = null;
    var previousDate = null;
    for (var thisdrug of medicine_data) {
      if (thisdrug.drug_id == this_drug_id) {
        let { start_date, end_date } = this.getStartEndDate(thisdrug, (previousDrugID == thisdrug.drug_id && thisdrug.taper_row == true), previousDate, this.state.constants, start_data)
        thisdrug.start_date = start_date;
        thisdrug.end_date = end_date;
        updateddrug.push(thisdrug);
      }
      else {
        updateddrug.push(thisdrug);
      }
      previousDrugID = thisdrug.drug_id;
      previousDate = thisdrug.start_date;
    }
    this.setState({
      drugsData: updateddrug
    });
  }

  updateTestDate = async (test_data, start_data, this_test_id) => {
    var updatedtest = [];
    for (var thistest of test_data) {
      if (thistest.test_id == this_test_id) {
        thistest.start_date = start_data;
        updatedtest.push(thistest);
      }
      else {
        updatedtest.push(thistest);
      }
    }
    this.setState({
      testData: updatedtest
    });
  }

  updateMedicineStartEndDate = (drugsArray, previousDate, index, drugId) => {
    var dailyFrequency = [];
    this.state.constants.dailyFrequency.map((obj, i) => {
      dailyFrequency[obj.id] = obj;
    });

    var defaultDate = moment().format('YYYY-MM-DD');
    let restTaperDrugs = drugsArray;
    var taperStartDate = previousDate != null ? previousDate : moment(defaultDate, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD');
    for (const [key, taper] of Object.entries(restTaperDrugs)) {
      if (key < index || drugId != restTaperDrugs[key].drug_id)
        continue;
      else {
        var taper_day_interval;
        var taper_times_correction;
        var taper_endDatedays;
        var taper_dosage_per_day;
        if (taper.daily_frequency) {
          taper_day_interval = dailyFrequency[taper.daily_frequency].interval;
          taper_day_interval = taper_day_interval == "weeks" ? 7 : (taper_day_interval == 'months' ? 30 : (taper_day_interval == 'years' ? 365 : 1));
          taper_times_correction = dailyFrequency[taper.daily_frequency].total_times;
          taper_dosage_per_day = dailyFrequency[taper.daily_frequency].total_repeats;
        }
        else {
          taper_day_interval = 1;
          taper_times_correction = 1;
        }

        var number_of_days = taper.number_of_days;
        if (taper_day_interval != 1)
          number_of_days = Math.round(taper.number_of_days / taper_dosage_per_day);
        taper_endDatedays = (number_of_days * taper_day_interval * taper_times_correction) - 1;
        var newStartDate = moment(taperStartDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
        var newEndDate = taper.drug_frequency != 3 && taper_times_correction != 0 ? moment(newStartDate, 'YYYY-MM-DD').add(taper_endDatedays, 'days').format('YYYY-MM-DD') : null;

        drugsArray[key].start_date = taper.drug_frequency != 3 && taper_times_correction != 0 ? newStartDate : null;
        drugsArray[key].end_date = newEndDate

        taperStartDate = newEndDate != null ? newEndDate : taperStartDate;
      }
    }
    this.setState({ drugsData: drugsArray })

  }


  updateTestData = (data) => {
    data.selected = true;
    let testData = this.state.testData;
    let addedTestList = [];
    var index = data.i;
    if (index == -1)
      index = testData.length;
    testData[index] = data;

    addedTestList = testData.map((v, k) => { return v.test_id });

    this.setState({
      testData, addedTestList, editData: null, editIndex: -1
    }, () => {
      this.handleModalPopup(null, 'showTestModal'); this.checkSelectedStatus();
    })
  }

  updateInstructionsData = (data) => {
    this.setState({
      instructionsData: data, instructionsCheckSelected: true
    }, () => {
      this.handleModalPopup(null, 'showInstructionModal'); this.checkSelectedStatus();
    })
  }

  getPatientData = _ => {
    const { match: { params } } = this.props;
    const { reference_id, qms_token_id } = params;

    console.log(params)
    var reference_type = LS_SERVICE.get('reference_type');
    Axios.get(PATIENT_DATA({ reference_id, reference_type }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken.qms_token_id
        }
        this.setState({
          patient,
          qms_token_id: qms_token_id
        }, async () => {
          await this.loadData();
        })
      })
      .catch(err => console.log(err))
  }

  makeCustomData(currentStr,copyStr){
    return currentStr;
    // let string = "";
    // if(currentStr!="" && currentStr!=null && copyStr!="" && copyStr!=null){
    //   string = currentStr + ", " + copyStr;
    // }else if(currentStr!="" && currentStr!=null && (copyStr == "" || copyStr == null) ){
    //   string = currentStr;
    // }else if((currentStr == "" || currentStr == null) && copyStr!= "" && copyStr!=null){
    //   string = copyStr;
    // }
    // return string;
  }


  loadData = async () => {
    const { qms_token_id, reference_id, template_id, patient, doctor_id } = this.state;
    const { patient_id } = patient;
    const subjectivePromise = Axios.get(SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS({ reference_type: LS_SERVICE.get('reference_type'), reference_id }));
    const currentCustomSubjectivePromise = Axios.get(CUSTOM_SUBJECTIVE_INFRMATION({ reference_type: LS_SERVICE.get('reference_type'), reference_id }));
    const currentCustomSubjectiveParentPromise = Axios.get(PARENT_INFRMATION_CUSTOM({ reference_type: LS_SERVICE.get('reference_type'), reference_id }));
    const currentClinicalNotesPromise = Axios.get(SUBJECTIVE_INFRMATION_CLINICAL_NOTES({ reference_type: LS_SERVICE.get('reference_type'), reference_id }))
    const customSubjectivePromise = qms_token_id ? Axios.get(CUSTOM_SUBJECTIVE_INFRMATION({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id })) : null;
    const presCustomSubjectiveParentPromise = qms_token_id ? Axios.get(PARENT_INFRMATION_CUSTOM({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id })) : null;
    let getPagePromise;
    let getVisitSubjectivePromise = null;
    if (this.state.copyType == 'template') {
      let PARAMS = {
        id: template_id
      }
      PARAMS.store_id = LS_SERVICE.get("STORE_ID");
      PARAMS.facility_id = LS_SERVICE.get("FACILITY_ID");
      PARAMS.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");

      getPagePromise = Axios.post(VIEW_TEMPLATE, qs.stringify(PARAMS));
    } else {
      getPagePromise = Axios.post(VIEW_VISIT, qs.stringify({ referenceType: LS_SERVICE.get('reference_type'), referenceId: qms_token_id }));
      getVisitSubjectivePromise = Axios.get(SUBJECTIVE_INFRMATION({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id }));
    }
    const medicinesPromise = Axios.get(MEDICINES_GETALL({ patient_id: patient_id }));
    const allergiesPromise = Axios.get(ALLERGIES_GETALL({ patient_id: patient_id }));
    const instructionsPromise = Axios.get(`${INSTRUCTION_SEARCH}?doctor_id=${doctor_id}`);
    let supportingMaterialPromise;
    if (this.state.copyType == 'template') {
      supportingMaterialPromise = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=template-copy&templateId=${template_id}`);
    } else {
      supportingMaterialPromise = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=all&referenceId=${qms_token_id}`);
    }
    const patient_obs_gynae_info = Axios.post(PATIENT_OBS_GYNAE_HX, { patient_id: patient_id, type: ['mensural', 'obstetric'] })
    const previous_visit_details = Axios.post(PREVIOUS_VISIT_DETAILS, { patient_id: patient_id })
    await Axios.all([subjectivePromise, getPagePromise, medicinesPromise, allergiesPromise, instructionsPromise, getVisitSubjectivePromise, patient_obs_gynae_info, previous_visit_details, supportingMaterialPromise, customSubjectivePromise,currentCustomSubjectivePromise, currentCustomSubjectiveParentPromise, currentClinicalNotesPromise, presCustomSubjectiveParentPromise])
      .then(Axios.spread(async (...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseThree = responses[2];
        const responseFour = responses[3];
        const responseFive = responses[4];
        const responseEight = responses[5];
        const responseNine = responses[6];
        const responseTen = responses[7];
        const responseEleven = responses[8];
        const responseTweleve = responses[9];
        const responseThirteen = responses[10];   //current child
        const responseFourteen = responses[11];   //current parent
        const responseFifteen = responses[12];
        const responseSixteen = responses[13];

        if (responseSixteen?.data?.hasOwnProperty("custom_data") === true) {
          let currentDetails = {}
          let finalData = {};
          let details = responseSixteen.data.custom_data;
          let parent_subjectives = responseSixteen.data.parent_subjectives;
          if (details){
            currentDetails["parent_custom1"] = details.parent_custom1 != null ? details.parent_custom1 : "";
            currentDetails["parent_custom2"] = details.parent_custom2 != null ? details.parent_custom2 : "";
            currentDetails["parent_custom3"] = details.parent_custom3 != null ? details.parent_custom3 : "";
            currentDetails["parent_custom4"] = details.parent_custom4 != null ? details.parent_custom4 : "";
            currentDetails["parent_custom5"] = details.parent_custom5 != null ? details.parent_custom5 : "";
          }
          let templateParentData = {}
          this.state.customParentFieldData.forEach(item=>{
            if(item.custom_parent_field_id == 11){
              templateParentData["parent_custom1"] = item.custom_parent_field;
            }
            if(item.custom_parent_field_id == 12){
              templateParentData["parent_custom2"] = item.custom_parent_field;
            }
            if(item.custom_parent_field_id == 13){
              templateParentData["parent_custom3"] = item.custom_parent_field;
            }
            if(item.custom_parent_field_id == 14){
              templateParentData["parent_custom4"] = item.custom_parent_field;
            }
            if(item.custom_parent_field_id == 15){
              templateParentData["parent_custom5"] = item.custom_parent_field;
            }
          })  

          finalData["details"] = {
            parent_custom1: this.makeCustomData(currentDetails["parent_custom1"], templateParentData["parent_custom1"]),
            parent_custom2: this.makeCustomData(currentDetails["parent_custom2"], templateParentData["parent_custom2"]),
            parent_custom3: this.makeCustomData(currentDetails["parent_custom3"], templateParentData["parent_custom3"]),
            parent_custom4: this.makeCustomData(currentDetails["parent_custom4"], templateParentData["parent_custom4"]),
            parent_custom5: this.makeCustomData(currentDetails["parent_custom5"], templateParentData["parent_custom5"]),
          };
          finalData["parent_subjectives"] = parent_subjectives;

          let { ss_configuration, consult_type } = this.state;
          let parent_custom1 = ss_configuration.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
          if (!parent_custom1) {
            finalData.details.parent_custom1 = ""
          }
          let parent_custom2 = ss_configuration.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
          if (!parent_custom2) {
            finalData.details.parent_custom2 = ""
          }
          let parent_custom3 = ss_configuration.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
          if (!parent_custom3) {
            finalData.details.parent_custom3 = ""
          }
          let parent_custom4 = ss_configuration.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
          if (!parent_custom4) {
            finalData.details.parent_custom4 = ""
          }
          let parent_custom5 = ss_configuration.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
          if (!parent_custom5) {
            finalData.details.parent_custom5 = ""
          }
          await this.setState({
            customSubjectiveParentData: finalData,
          });
        }

        if (responseTweleve?.data?.hasOwnProperty("data") === true) {
          const { data } = responseTweleve.data;
          if(!data.details)
          {
            let details = {
              custom1 : '',
              custom2 : '',
              custom3 : '',
              custom4 : '',
              custom5 : '',
            }
            data.details = details;
          }

          let { ss_configuration, consult_type } = this.state;
          var custom_parent = ss_configuration.find(o => o?.id === 3)
          if (custom_parent && custom_parent.sub_items) {
            let custom1 = custom_parent.sub_items.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if(!custom1)
            {
              data.details.custom1 = "";
            }
            let custom2 = custom_parent.sub_items.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if(!custom2)
            {
              data.details.custom2 = "";
            }
            let custom3 = custom_parent.sub_items.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if(!custom3)
            {
              data.details.custom3 = "";
            }
            let custom4 = custom_parent.sub_items.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if(!custom4)
            {
              data.details.custom4 = "";
            }
            let custom5 = custom_parent.sub_items.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if(!custom5)
            {
              data.details.custom5 = "";
            }
          }
          await this.setState({
            customSubjectiveData: data,
          });
        }

        var already_prescribed_drugs = []
        if (responseTen?.data?.visit_details?.medicine_list?.length > 0) {
          for (var this_med of responseTen?.data?.visit_details?.medicine_list) {
            already_prescribed_drugs.push(this_med.drug_id)
          }
          this.setState({
            already_prescribed_drugs: already_prescribed_drugs
          })
        }
        var already_prescribed_tests = []
        if (responseTen?.data?.visit_details?.test_list?.length > 0) {
          for (var this_test of responseTen?.data?.visit_details?.test_list) {
            already_prescribed_tests.push(this_test.test_id)
          }
          this.setState({
            already_prescribed_tests: already_prescribed_tests
          })
        }
        if (responseTwo?.data?.hasOwnProperty("data") === true) {
          let responseAssessments = [];
          let responseDrugs = [];
          let responseTests = [];
          let responseInstructions = [];
          let responseTemplate;
          if (this.state.copyType == 'template') {
            responseAssessments = responseTwo.data.data.diagnosisList;
            responseDrugs = responseTwo.data.data.drugList;
            responseTests = responseTwo.data.data.testList;
            responseInstructions = responseTwo.data.data.templateInstruction;
            responseTemplate = responseTwo.data.data.template;
            if (this.props.updateCPName) {
              this.props.updateCPName(responseTemplate.name);
            }
            if (responseTemplate?.isEventBased == 'Y') {
              let isEventBasedSS = responseNine?.data?.result?.mensural?.lmpDate && responseNine?.data?.result?.mensural?.lmpDate != null ? true : false;
              this.setState({
                isEventBasedSS 
              })
            }
          }
          else {
            responseAssessments = responseTwo.data.data.diagnosis_data;
            responseDrugs = responseTwo.data.data.drugs_data;
            responseTests = responseTwo.data.data.tests_data;
            responseInstructions = responseTwo.data.data.instructions_data;
            responseTemplate = responseTwo.data.data.prescriptionDrug;
          }
          if (responseTemplate?.isEventBased == 'Y') {
            this.setState({
              obsGynae: responseNine?.data?.result || {},
            })
          }
          let assessmentTempArray = [];
          let addedAssessmentList = [];
          if (responseAssessments || responseAssessments.length > 0) {
            responseAssessments.map((data, i) => {
              var tempAssessmentObj = {
                description: this.state.copyType == 'template' ? data.description : data?.diagnosisInfo?.diagnose_description,
                diagnosis_id: this.state.copyType == 'template' ? data?.diagnosis_id : data?.diagnosisId,
                icd_code: this.state.copyType == 'template' ? data?.icd_code : data?.diagnosisInfo?.icd_code,
                id: data.id,
                image_comment: this.state.copyType == 'template' ? data.image_comment : data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : null,
                // image_uploaded: this.state.copyType=='template'?data.image_uploaded:data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
                image: this.state.copyType == 'template' ? data.image_uploaded : data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
                stage: this.state.copyType == 'template' ? 0 : data.stage,
                type: this.state.copyType == 'template' ? 0 : data.type,
                status: this.state.copyType == 'template' ? 0 : data.status,
                diagnosis_notes: this.state.copyType == 'template' ? data.diagnosis_notes : data.diagnosisNotes,
                selected: false
              }
              assessmentTempArray.push(tempAssessmentObj);
              addedAssessmentList.push(tempAssessmentObj.diagnosis_id)
            });
          }
          var previousDrugID = null;
          var previousDate = null;
          let addedDrugsList = [];
          var drugsnotcustom = 0;
          var start_date_array = [];
          var protocol_array = [];
          responseDrugs.map((v, k) => {
            if (responseDrugs[k].event && responseDrugs[k].event > 0) {
              if (responseDrugs[k].event && responseDrugs[k].event != 4) {
                drugsnotcustom++;
              }
              var predefined_start_date;
              if (responseDrugs[k].event == 1 || responseDrugs[k].event == 2) {
                var days = 0;
                if (responseDrugs[k]?.lmp_event_duration_type == 'Months') {
                  days = responseDrugs[k]?.lmp_event_duration * 30
                }
                else if (responseDrugs[k]?.lmp_event_duration_type == 'Weeks') {
                  days = responseDrugs[k]?.lmp_event_duration * 7
                }
                else {
                  days = responseDrugs[k]?.lmp_event_duration
                }
                if (responseDrugs[k].event == 1) {
                  if (this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null) {
                    var lmp_date = new Date(this.state.obsGynae?.mensural?.lmpDate)
                    var predefined_start_date_unformatted = new Date(lmp_date.setDate(lmp_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                  else {
                    predefined_start_date = moment().format('YYYY-MM-DD')
                  }
                }
                else {
                  if (this.state.patient?.dob && this.state.patient?.dob != null) {
                    var birth_date = new Date(this.state.patient?.dob)
                    var predefined_start_date_unformatted = new Date(birth_date.setDate(birth_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                  else {
                    predefined_start_date = moment().format('YYYY-MM-DD')
                  }
                }
              }
              if (responseDrugs[k].event == 1) {
                protocol_array.push('LMP + ' + responseDrugs[k]?.lmp_event_duration + ' ' + responseDrugs[k]?.lmp_event_duration_type)
                start_date_array.push(new Date(predefined_start_date))
              }
              else if (responseDrugs[k].event == 2) {
                protocol_array.push('Birth Date + ' + responseDrugs[k]?.lmp_event_duration + ' ' + responseDrugs[k]?.lmp_event_duration_type)
                start_date_array.push(new Date(predefined_start_date))
              }
              else if (responseDrugs[k].event == 3) {
                protocol_array.push('System Date')
                start_date_array.push(new Date())
              }
              else if (responseDrugs[k].event == 4) {
                protocol_array.push('Custom Date')
                start_date_array.push(new Date())
              }
            }
            responseDrugs[k].start_date_protocol = predefined_start_date
            predefined_start_date = moment(predefined_start_date).diff(moment(), 'days') < 0 ? moment().format('YYYY-MM-DD') : predefined_start_date;
            let { start_date, end_date } = this.getStartEndDate(v, (previousDrugID == v.drug_id && v.taper_row == true), previousDate, responseTwo.data.data.constants, predefined_start_date)
            let dosageObj = responseTwo.data.data.constants.drugDosage.find(item => item.id == responseDrugs[k].dosage);
            responseDrugs[k].start_date = start_date
            responseDrugs[k].end_date = end_date
            //responseDrugs[k].dosage_value = responseDrugs[k].dosage_value == null || responseDrugs[k].dosage_value == '' ? dosageObj?.description : responseDrugs[k].dosage_value;
            responseDrugs[k].dosage_value = responseDrugs[k].dosage_value;
            previousDrugID = v.drug_id;
            previousDate = responseDrugs[k].end_date != null ? responseDrugs[k].end_date : (v.taper_row ? previousDate : null);
            responseDrugs[k].selected = false;
            addedDrugsList.push(v.drug_id);
          });

          var smallest_index = start_date_array.map(Number).indexOf(+new Date(Math.min.apply(null, start_date_array)))
          var smallest_protocol = protocol_array.slice(smallest_index, smallest_index + 1);
          var smallest_start_date = new Date(Math.min.apply(null, start_date_array));
          this.setState({
            smallest_protocol: smallest_protocol,
            smallest_due_date: moment(smallest_start_date)
          })

          let addedTestList = [];
          responseTests.map((v, k) => {
            var predefined_start_date;
            if (responseTests[k].event && responseTests[k].event > 0) {
              if (responseTests[k].event == 1 || responseTests[k].event == 2) {
                var days = 0;
                if (responseTests[k]?.lmp_event_duration_type == 'Months') {
                  days = responseTests[k]?.lmp_event_duration * 30
                }
                else if (responseTests[k]?.lmp_event_duration_type == 'Weeks') {
                  days = responseTests[k]?.lmp_event_duration * 7
                }
                else {
                  days = responseTests[k]?.lmp_event_duration
                }
                if (responseTests[k].event == 1) {
                  if (this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null) {
                    var lmp_date = new Date(this.state.obsGynae?.mensural?.lmpDate)
                    var predefined_start_date_unformatted = new Date(lmp_date.setDate(lmp_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                }
                else {
                  if (this.state.patient?.dob && this.state.patient?.dob != null) {
                    var birth_date = new Date(this.state.patient?.dob)
                    var predefined_start_date_unformatted = new Date(birth_date.setDate(birth_date.getDate() + days));
                    predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                  }
                }
              }
            }
            responseTests[k].start_date_protocol = moment(predefined_start_date).format('YYYY-MM-DD');
            predefined_start_date = moment(predefined_start_date).diff(moment(), 'days') < 0 ? moment().format('YYYY-MM-DD') : predefined_start_date;
            responseTests[k].is_diabetic = 0;
            responseTests[k].is_metformin = 0;
            responseTests[k].risk = 0;
            responseTests[k].start_date = moment(predefined_start_date).format('YYYY-MM-DD');
            responseTests[k].selected = false;
            addedTestList.push(v.test_id);
          });

          responseInstructions.map((v, k) => {
            responseInstructions[k]['id'] = this.state.copyType == 'template' ? v.instructionId : v.instruction_id;
            delete responseInstructions[k]['instruction_id'];
            delete responseInstructions[k]['instructionId'];
          });

          this.setState({
            constants: responseTwo.data.data.constants,
            assessmentData: assessmentTempArray,
            addedAssessmentList,
            drugsData: responseDrugs,
            drugsnotcustom: drugsnotcustom,
            addedDrugsList,
            testData: responseTests,
            template: responseTemplate || {},
            addedTestList,
            instructionsData: responseInstructions,
            chiefComplaintData: responseTwo.data.data.cheifComplaint,
            physicalExaminationData: responseTwo.data.data.physicalExamination,
            customChildFieldData: responseTwo.data.data.CustomChildField?.CustomChildFieldData,
            customParentFieldData: responseTwo.data.data.CustomParentField?.CustomParentFieldData,
            clinicalNotesData: responseTwo.data.data.ClinicalNotes
          })
        }

        if (responseThree.data.hasOwnProperty("data") === true) {
          let currentMedsList = responseThree.data.data.details.current_medicines;
          var tempArr = [];
          if (currentMedsList) {
            tempArr = currentMedsList.map(function (v) { return v.drug_id });
            tempArr = tempArr.filter(function (item, pos) { return tempArr.indexOf(item) == pos; })
          }
          this.setState({
            currentMedicinesData: tempArr
          })
        }

        // if (responseFour.data.hasOwnProperty("data") === true) {
        //   let allergiesTemp = responseFour.data.data.details.allergies;
        //   let patientDrugAllergies = [];
        //   allergiesTemp.map((v, k) => {
        //     if (v.allergyType == "drug")
        //       patientDrugAllergies.push({
        //         intensity: v.intensity,
        //         reaction: v.reaction,
        //         allergy_code: v.allergy_code
        //       })
        //   })
        //   this.setState({
        //     patientDrugAllergies
        //   })
        // }

        if (responseFive.data.hasOwnProperty("data") === true) {
          this.setState({
            allInstructionsData: responseFive.data.data.details
          })

        }

        if (responseEleven.data.supportingFiles) {

          if (this.state.copyType == 'template') {
            this.setState({
              allSupportingMaterial: responseEleven.data.supportingFiles.filter(file => file.deletedAt == null),
              supportingMaterial: responseEleven.data.supportingFiles.filter(file => file.selected)
            })

            await Axios.post(UPDATE_SUPPORTING_MATERIAL, this.state.supportingMaterial).then(res => {
              let supporting = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=template-copy&templateId=${template_id}`);
              supporting.then(resp => {
                this.setState({
                  allSupportingMaterial: resp.data.supportingFiles.filter(file => file.deletedAt == null),
                  supportingMaterial: resp.data.supportingFiles.filter(file => file.selected)
                })
              })
            })
          } else {
            this.setState({
              allSupportingMaterial: responseEleven.data.supportingFiles,
              supportingMaterial: responseEleven.data.supportingFiles.filter(file => file.selected)
            })
            await Axios.post(UPDATE_SUPPORTING_MATERIAL, this.state.supportingMaterial).then(res => {
              let supporting = Axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=all&referenceId=${qms_token_id}`);
              supporting.then(resp => {
                this.setState({
                  allSupportingMaterial: resp.data.supportingFiles,
                  supportingMaterial: resp.data.supportingFiles.filter(file => file.selected)
                })
              })
            })
          }

        }

        if (responseEight != null && responseEight.data.hasOwnProperty("data") === true) {
          if (responseEight.data.data.details.length === 0) {
            responseEight.data.data.details = [
              {
                "subjective_id": 0,
                "description": "",
                "subjective_title": "Chief Complaints & HPI"
              },
              {
                "subjective_id": 2,
                "description": "",
                "subjective_title": "Physical Examination"
              }
            ]
          }
          if (responseEight.data.data.details.length > 0) {
            let HPIValue = '';
            responseEight.data.data.details.map((v, k) => {
              if (v.subjective_id == 1) HPIValue = v.description;
            });
            let currentSubjectiveData = responseEight.data.data;
            currentSubjectiveData.details.map((v, k) => {
              // if (v.subjective_id == 0 && this.state.sameRxdate) currentSubjectiveData.details[k].description = 'Follow up from the visit dated ' + this.state.sameRxdate + '\r\n';
              if (v.subjective_id == 1 && this.state.sameRxdate) currentSubjectiveData.details[k].description = HPIValue;
            });
            this.setState({
              subjectiveData: currentSubjectiveData,
            })
          }
        }

        if (responseOne.data.hasOwnProperty("data") === true) {

          const { data } = responseOne.data;

          if(data.details.length > 0 )
          {
            // if (data.details.length === 0) {
            //   data.details = [
            //     {
            //       "subjective_id": 0,
            //       "description": "",
            //       "subjective_title": "Chief Complaints & HPI"
            //     },
            //     {
            //       "subjective_id": 2,
            //       "description": "",
            //       "subjective_title": "Physical Examination"
            //     }
            //   ]
            // }

           
            let subjective_previous = true;
            if(data.details[0]?.description == '' && data.details[1]?.description == ''){
              subjective_previous =false
            }
            if(subjective_previous){
            if (this.state.sameRxdate) {
                data.details.forEach(sub => {
                  if (sub.subjective_id === 0) {
                    sub.description = `Follow up from the visit dated  ${this.state.sameRxdate}, ` + sub.description;
                  }
                });
            }
            this.setState({
              subjectiveData: data,
            })
          }
          }
        }


        if (this.state.copyType == "template") {
          if (responseOne.data.hasOwnProperty("data") === true) {
            const { data } = responseOne.data;
            let {chiefComplaintData, physicalExaminationData} = this.state;
            if (data.details.length === 0) {
              data.details = [
                {
                  "subjective_id": 0,
                  "description": "",
                  "subjective_title": "Chief Complaints & HPI"
                },
                {
                  "subjective_id": 2,
                  "description": "",
                  "subjective_title": "Physical Examination"
                }
              ]
            }
            if (data.details.length > 0) {
              let tempCC = typeof chiefComplaintData != 'undefined' && chiefComplaintData!= null && typeof chiefComplaintData.chiefComplaint != 'undefined' ? chiefComplaintData.chiefComplaint : "";
              let tempPE =typeof physicalExaminationData != "undefined" && physicalExaminationData!=null && typeof physicalExaminationData.physicalExamination != 'undefined' ? physicalExaminationData.physicalExamination : "";
              data.details.forEach(sub => {
                if (sub.subjective_id === 0) {
                  sub.description = this.makeCustomData(sub.description, tempCC);
                }
                if (sub.subjective_id === 2) {
                  sub.description = this.makeCustomData(sub.description,tempPE);
                }
              });
              this.setState({
                subjectiveData: data
              })
            }
          }

          if (responseThirteen.data.hasOwnProperty("data") === true) {
            const { data } = responseThirteen.data;
            if(!data.details)
            {
              let details = {
                custom1 : '',
                custom2 : '',
                custom3 : '',
                custom4 : '',
                custom5 : '',
              }
              data.details = details;
            }

            let templateData = {
              custom1: "",
              custom2: "",
              custom3: "",
              custom4: "",
              custom5: ""
            }
            this.state.customChildFieldData.forEach(item=>{
              if(item.custom_child_field_id == 11){
                templateData["custom1"] = item.custom_child_field;
              }
              if(item.custom_child_field_id == 12){
                templateData["custom2"] = item.custom_child_field;
              }
              if(item.custom_child_field_id == 13){
                templateData["custom3"] = item.custom_child_field;
              }
              if(item.custom_child_field_id == 14){
                templateData["custom4"] = item.custom_child_field;
              }
              if(item.custom_child_field_id == 15){
                templateData["custom5"] = item.custom_child_field;
              }
            })


            data.details =  {
              custom1: this.makeCustomData(data.details["custom1"], templateData["custom1"]),
              custom2: this.makeCustomData(data.details["custom2"], templateData["custom2"]),
              custom3: this.makeCustomData(data.details["custom3"], templateData["custom3"]),
              custom4: this.makeCustomData(data.details["custom4"], templateData["custom4"]),
              custom5: this.makeCustomData(data.details["custom5"], templateData["custom5"]),
            }

            let { ss_configuration, consult_type } = this.state;
            var custom_parent = ss_configuration.find(o => o?.id === 3)
            if (custom_parent && custom_parent.sub_items) {
              let custom1 = custom_parent.sub_items.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
              if(!custom1)
              {
                data.details.custom1 = "";
              }
              let custom2 = custom_parent.sub_items.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
              if(!custom2)
              {
                data.details.custom2 = "";
              }
              let custom3 = custom_parent.sub_items.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
              if(!custom3)
              {
                data.details.custom3 = "";
              }
              let custom4 = custom_parent.sub_items.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
              if(!custom4)
              {
                data.details.custom4 = "";
              }
              let custom5 = custom_parent.sub_items.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
              if(!custom5)
              {
                data.details.custom5 = "";
              }
            }
            
            await this.setState({
              customSubjectiveData: data,
            });
          }

          if (responseFourteen.data.hasOwnProperty("custom_data") === true) {
            let currentDetails = {}
            let finalData = {};
            let details = responseFourteen.data.custom_data;
            let parent_subjectives = responseFourteen.data.parent_subjectives;
            if (details){
              currentDetails["parent_custom1"] = details.parent_custom1 != null ? details.parent_custom1 : "";
              currentDetails["parent_custom2"] = details.parent_custom2 != null ? details.parent_custom2 : "";
              currentDetails["parent_custom3"] = details.parent_custom3 != null ? details.parent_custom3 : "";
              currentDetails["parent_custom4"] = details.parent_custom4 != null ? details.parent_custom4 : "";
              currentDetails["parent_custom5"] = details.parent_custom5 != null ? details.parent_custom5 : "";
            }
            let templateParentData = {}
            this.state.customParentFieldData.forEach(item=>{
              if(item.custom_parent_field_id == 11){
                templateParentData["parent_custom1"] = item.custom_parent_field;
              }
              if(item.custom_parent_field_id == 12){
                templateParentData["parent_custom2"] = item.custom_parent_field;
              }
              if(item.custom_parent_field_id == 13){
                templateParentData["parent_custom3"] = item.custom_parent_field;
              }
              if(item.custom_parent_field_id == 14){
                templateParentData["parent_custom4"] = item.custom_parent_field;
              }
              if(item.custom_parent_field_id == 15){
                templateParentData["parent_custom5"] = item.custom_parent_field;
              }
            })  

            finalData["details"] = {
              parent_custom1: this.makeCustomData(currentDetails["parent_custom1"], templateParentData["parent_custom1"]),
              parent_custom2: this.makeCustomData(currentDetails["parent_custom2"], templateParentData["parent_custom2"]),
              parent_custom3: this.makeCustomData(currentDetails["parent_custom3"], templateParentData["parent_custom3"]),
              parent_custom4: this.makeCustomData(currentDetails["parent_custom4"], templateParentData["parent_custom4"]),
              parent_custom5: this.makeCustomData(currentDetails["parent_custom5"], templateParentData["parent_custom5"]),
            };
            finalData["parent_subjectives"] = parent_subjectives;

            let { ss_configuration, consult_type } = this.state;
            let parent_custom1 = ss_configuration.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if (!parent_custom1) {
              finalData.details.parent_custom1 = ""
            }
            let parent_custom2 = ss_configuration.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if (!parent_custom2) {
              finalData.details.parent_custom2 = ""
            }
            let parent_custom3 = ss_configuration.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if (!parent_custom3) {
              finalData.details.parent_custom3 = ""
            }
            let parent_custom4 = ss_configuration.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if (!parent_custom4) {
              finalData.details.parent_custom4 = ""
            }
            let parent_custom5 = ss_configuration.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
            if (!parent_custom5) {
              finalData.details.parent_custom5 = ""
            }
            await this.setState({
              customSubjectiveParentData: finalData,
            });
          }

          if (responseFifteen.data.hasOwnProperty("data") === true) {
            let {data} = responseFifteen.data;
            let {clinicalNotesData} = this.state;
            let currentClinicalNotes = typeof data.clinical_notes != "undefined" ? data.clinical_notes : "";
            let templateClinicalNotes = typeof clinicalNotesData?.ClinicalNotes != "undefined" ? clinicalNotesData.ClinicalNotes : "";
            let finalClinicalNotes = this.makeCustomData(currentClinicalNotes,templateClinicalNotes);
            this.setState({finalClinicalNotes});
          }
        }

        this.setState({
          DataLoaded: true,
        })


        this.checkSelectedStatus();

      }))
    //this.viewCopyVisit()
  }

  makeCustomData(currentStr,copyStr){
    let string = "";
    if(currentStr!="" && currentStr!=null && copyStr!="" && copyStr!=null){
      string = currentStr + ", " + copyStr;
    }else if(currentStr!="" && currentStr!=null && (copyStr == "" || copyStr == null) ){
      string = currentStr;
    }else if((currentStr == "" || currentStr == null) && copyStr!= "" && copyStr!=null){
      string = copyStr;
    }
    return string;
  }

  viewCopyVisit = async () => {
    if (this.state.visible == 'false') {
      await this.setState({
        isSelectAllChecked: true,
      })
      this.handleSelectAllClick();
      this.handleOnConfirmSubmit();
    }
  }

  getStartEndDate(data, isTaper, previousDate, constants, predefinedStartDate) {
    let start_date = null;
    let end_date = null;
    let day_interval = null;
    let times_correction = null;
    let dosage_per_day = null;
    let number_of_days = data.number_of_days;

    if (data.daily_frequency) {
      let frequencyObjKey = Object.keys(constants.dailyFrequency).find(key => constants.dailyFrequency[key].id === data.daily_frequency)
      frequencyObjKey = constants.dailyFrequency[parseInt(frequencyObjKey)]
      // day_interval = frequencyObjKey.interval;
      day_interval = data.number_of_days_type && data.number_of_days_type != null ? data.number_of_days_type.toLowerCase() : '';
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
      times_correction = frequencyObjKey?.total_times;
      dosage_per_day = frequencyObjKey?.total_repeats;
    }
    if (isTaper && previousDate)
      start_date = moment(previousDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    else {
      if (predefinedStartDate && predefinedStartDate != null && predefinedStartDate != undefined) {
        start_date = predefinedStartDate
      }
      else {
        start_date = moment().format('YYYY-MM-DD');
      }
    }
    //if (day_interval != 1)
    //   number_of_days = Math.round(number_of_days / dosage_per_day);
    // var endDatedays = (number_of_days * day_interval * times_correction) - 1;
    var endDatedays = (number_of_days * day_interval);
    end_date = moment(start_date, 'YYYY-MM-DD').add(endDatedays, 'days').format('YYYY-MM-DD');

    if (data.drug_frequency == 3 || times_correction == 0) {
      start_date = null;
      end_date = null;
    }
    return { start_date: start_date, end_date: end_date }

  }

  checkSelectedStatus = () => {
    // if ((this.subjectiveData[0].value === '') || (this.subjectiveData[1].value === '')) {
    //   notification.success({
    //     message: 'Please complete subjective',
    //     placement: 'topRight'
    //   })
    // }

    const { assessmentData, subjectiveData,customSubjectiveData, customSubjectiveParentData, drugsData, testData, instructionsCheckSelected, supportingCheckSelected, clinicalNotesSelected } = this.state;

    let assessmentSelectedCount = 0;
    assessmentData.map((v, k) => {
      if (v.selected) assessmentSelectedCount++;
    })
    let drugsSelectedCount = 0;
    drugsData.map((v, k) => {
      if (v.selected) drugsSelectedCount++;
    })
    let testSelectedCount = 0;
    testData.map((v, k) => {
      if (v.selected) testSelectedCount++;
    })


    let chiefComplaint = false;
    let PhysicalExamination = false;
    if( subjectiveData && subjectiveData.subjectives && Object.values(subjectiveData.subjectives).length > 0 ){
      Object.values(subjectiveData.subjectives).map((v, k) => {
        if(v.id == 0 && v.selected){
          chiefComplaint = true;
        }
        if(v.id == 2 && v.selected){
          PhysicalExamination = true;
        }
      })
    }

    let childSelectedCount = 0;
    if( customSubjectiveData && customSubjectiveData.subjectives && Object.values(customSubjectiveData.subjectives).length > 0 ){
      Object.values(customSubjectiveData.subjectives).map((v, k) => {
        if (v.selected) childSelectedCount++;
      })
    }
    let parentSelectedCount = 0;
    if(customSubjectiveParentData && customSubjectiveParentData.parent_subjectives && Object.values(customSubjectiveParentData.parent_subjectives).length>0){
      Object.values(customSubjectiveParentData.parent_subjectives).map((v, k) => {
        if (v.selected) parentSelectedCount++;
      })
    }
   
    if (this.state.copyType == "template") {
      if(assessmentSelectedCount > 0 || testSelectedCount >0 || drugsSelectedCount >0 || chiefComplaint || PhysicalExamination || childSelectedCount > 0 || parentSelectedCount > 0 || clinicalNotesSelected || instructionsCheckSelected || supportingCheckSelected){
        this.setState({ disableConfirmBtn: false})
      }else{
        this.setState({ disableConfirmBtn: true, isSelectAllChecked: false})
      }

    } else {
      if (assessmentData.length == assessmentSelectedCount && (drugsData?.length == drugsSelectedCount) && testData.length == testSelectedCount && instructionsCheckSelected && supportingCheckSelected)
        this.setState({ disableConfirmBtn: false, isSelectAllChecked: true })
      else if (assessmentData.length == assessmentSelectedCount && (drugsData?.length > 0 ? drugsSelectedCount > 0 : true) && testData.length == testSelectedCount && instructionsCheckSelected && supportingCheckSelected)
        this.setState({ disableConfirmBtn: false, isSelectAllChecked: false })
      else
        this.setState({ disableConfirmBtn: true, isSelectAllChecked: false })
    }
  }

  handleSelectCheck = (index, array) => {
    let {copyType} = this.state;
    if(copyType == "template"){
      if(array == "subjectiveData"){
        let data = this.state[array];
        let dataArray = data.subjectives;
        dataArray[index].selected = !dataArray[index].selected;
        data.subjectives = dataArray;
        this.setState({[array]:data},()=>{this.checkSelectedStatus();})
      }else if(array == "customSubjectiveData"){
        // console.log("array>>>>>>>>>>>>>>>>>", array);
        let data = this.state[array];
        let dataArray = data.subjectives;
        dataArray[index].selected = !dataArray[index].selected;
        data.subjectives = dataArray;
        this.setState({[array]:data},()=>{this.checkSelectedStatus();})
      }else if(array == "customSubjectiveParentData"){
        let data = this.state[array];
        let dataArray = data.parent_subjectives;
        dataArray[index].selected = !dataArray[index].selected;
        data.parent_subjectives = dataArray;
        this.setState({[array]:data},()=>{this.checkSelectedStatus();})
      }else{
        let dataArray = this.state[array];
        dataArray[index].selected = !dataArray[index].selected;
        this.setState({ [array]: dataArray }, () => {
          if (array == 'drugsData' && dataArray[index].selected)
            this.handleDrugAllergy(index);
          this.checkSelectedStatus();
        })
      }
    }else{
      let dataArray = this.state[array];
      dataArray[index].selected = !dataArray[index].selected;
      this.setState({ [array]: dataArray }, () => {
        if (array == 'drugsData' && dataArray[index].selected)
          this.handleDrugAllergy(index);
        this.checkSelectedStatus();
      })
    }
    
    

    
  }
  handleInstructionsSelectCheck = () => {
    this.setState({ instructionsCheckSelected: !this.state.instructionsCheckSelected }, () => this.checkSelectedStatus())
  }

  handleSupportingSelectCheck = () => {
    this.setState({ supportingCheckSelected: !this.state.supportingCheckSelected }, () => this.checkSelectedStatus())
  }
  handleCheifComplaintSelectCheck = () => {
    this.setState({ chiefCompCheckSelected: !this.state.chiefCompCheckSelected }, () => this.checkSelectedStatus())
  }
  handlephysicalExaminationSelectCheck = () => {
    this.setState({ physicalExaminationSelected: !this.state.physicalExaminationSelected }, () => this.checkSelectedStatus())
  }
  handleclinicalNotesSelectCheck = () => {
    this.setState({ clinicalNotesSelected: !this.state.clinicalNotesSelected }, () => this.checkSelectedStatus())
  }

  polyPharmacyWarningShown = () => {
    this.setState({
      polyPharmacyWarningShown: true,
    })
  }

  clearEdit = () => {
    this.setState({
      editData: null,
      editIndex: -1
    })
  }

  // For Modal popup
  handleModalPopup = (e, popupName, data = null, actionType = '', objId = null, patientDocs) => {
    if (e !== null)
      e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    },
      () => {

        this.setState({
          actionText: "Add", editData: null, editIndex: -1
        }, () => {
          const { showAssessmentModal, showMedicineModal, showInstructionModal, showTestModal, deleteConfirmModal, showDrugAllergyModal, showDrugPolyPharmacyModal, showSupportingModal } = this.state;

          if (showAssessmentModal) {

            if (actionType === 'editAssessment') {
              this.setState({
                actionText: "Edit",
                editData: this.state.assessmentData[objId],
                editIndex: objId
              })
              AntSelectLabels();
            }

          }
          if (showMedicineModal) {
            if (actionType === 'editMedicine') {
              this.setState({ actionText: "Edit" })
            }

            if (data !== null) {
              let editData = this.state.drugsData[objId];
              editData.previousEndDate = null;
              for (let index = (objId - 1); index >= 0; index--) {
                if (this.state.drugsData[index].end_date != null && editData.previousEndDate == null)
                  editData.previousEndDate = this.state.drugsData[index].end_date;
                if (editData.start_date) {
                  editData.end_date = moment(editData.start_date, 'YYYY-MM-DD').add(editData.number_of_days - 1, 'days').format('YYYY-MM-DD');
                }

              }
              this.setState({
                actionText: "Edit",
                editData: editData,
                editIndex: objId
              })
            }
          }

          if (showInstructionModal) {
            // const { allInstructionsData } = this.state;
            // this.setState({
            //   isNewInstruction: false,
            //   instructionOption: allInstructionsData
            // });

          }


          if (deleteConfirmModal) {
            // this.setState({ actionType, objId });
            // For Assessment
            if (actionType === 'deleteAssessment') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete assessment?' });
            }

            // For Medicines
            if (actionType === 'deleteMedicine') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete medicine?' });
            }

            // For Tests
            if (actionType === 'deleteTest') {
              this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete test?' });
            }

          }

          if (showDrugAllergyModal) {
            this.setState({ allergiesMessage: data.message, deleteObjId: data.type == 'all' ? data.deleteObj : objId })
          }

          if (showDrugPolyPharmacyModal) {
            this.setState({ polyPharmacyMessage: data.message, deleteObjId: data.type == 'all' ? null : objId, isSelectAllChecked: false, disableConfirmBtn: true })
          }


        })
      })

  }
  /*
    resetMedicineModal = () => {
      this.setState({updateMedicineModal:false,medicineEditData:null})
    }
    resetUpdateMedicineModal = (val) => {
      this.setState({updateMedicineModal:val})
    }*/

  // ############################## Tests Data #################################

  /*testOnFocus = (e) => {
    this.setState({
      testOnFocus: true
    })

  }*/

  handleOnItemDelete = (e, actionType, objId) => {
    e.preventDefault();

    var stateVariable = null;
    var stateAddedVariable = null;
    var propKey = null;
    // For Assessment
    if (actionType === 'deleteAssessment') {
      stateVariable = 'assessmentData';
      stateAddedVariable = 'addedAssessmentList';
      propKey = 'diagnosis_id';
    }
    // For Medicine
    if (actionType === 'deleteMedicine') {
      stateVariable = 'drugsData';
      stateAddedVariable = 'addedDrugsList';
      propKey = 'drug_id';
    }
    // For Test
    if (actionType === 'deleteTest') {
      stateVariable = 'testData';
      stateAddedVariable = 'addedTestList';
      propKey = 'test_id';
    }

    let dataArray = this.state[stateVariable];
    let drugId;
    let previousEndDate;
    if (actionType === 'deleteMedicine') {
      drugId = dataArray[objId].drug_id;
      previousEndDate = null;
      for (let index = (objId - 1); index >= 0; index--) {
        if (dataArray[index].end_date != null && previousEndDate == null)
          previousEndDate = dataArray[index].end_date;
      }
    }

    dataArray.splice(objId, 1);
    let addedArray = [];
    var start_date_array = [];
    var protocol_array = [];
    dataArray.map((v, k) => {
      if (actionType === 'deleteMedicine') {
        if (dataArray[k].event && dataArray[k].event > 0) {
          if (dataArray[k].event == 1) {
            protocol_array.push('LMP + ' + dataArray[k]?.lmp_event_duration + ' ' + dataArray[k]?.lmp_event_duration_type)
          }
          else if (dataArray[k].event == 2) {
            protocol_array.push('Birth Date + ' + dataArray[k]?.lmp_event_duration + ' ' + dataArray[k]?.lmp_event_duration_type)
          }
          else if (dataArray[k].event == 3) {
            protocol_array.push('System Date')
          }
          else if (dataArray[k].event == 4) {
            protocol_array.push('Custom Date')
          }
          start_date_array.push(new Date(dataArray[k].start_date))
        }
      }
      addedArray.push(v[propKey])
    })
    if (actionType === 'deleteMedicine') {
      // var smallest_index = start_date_array.map(Number).indexOf(+new Date(Math.min.apply(null, start_date_array)))
      // var smallest_protocol = protocol_array.slice(smallest_index,smallest_index+1);
      var smallest_start_date = start_date_array ? new Date(Math.min.apply(null, start_date_array)) : undefined;
      this.setState({
        // smallest_protocol: smallest_protocol,
        smallest_due_date: smallest_start_date ? moment(smallest_start_date) : null
      })
    }
    if (actionType === 'deleteMedicine')
      this.updateMedicineStartEndDate(dataArray, previousEndDate, objId, drugId);

    this.setState({
      deleteConfirmModal: false,
      [stateVariable]: dataArray,
      [stateAddedVariable]: addedArray
    }, () => this.checkSelectedStatus());
  }

  handleDrugAllergy = (i) => {
    var drugGeneric = this.state.drugsData[i].drug_generic;
    var drugAllergic = false;
    var drugAllergicText = '';
    var drugAllergicIntensity = '';
    const { patientDrugAllergies } = this.state;
    const allergyIntensity = this.state.constants.allergyIntensity;
    if (drugGeneric && drugGeneric.length > 0) {
      drugGeneric.map((v, k) => {
        patientDrugAllergies.map((allergy, k) => {
          if (allergy.allergy_code == v.generic_id.code) {
            drugAllergic = true;
            drugAllergicText = v.generic_id.generic_name;
            drugAllergicIntensity = allergy.intensity ? allergyIntensity[parseInt(allergy.intensity)] : allergyIntensity[0];
          }
        })
      });
    }
    if (drugAllergic) {
      this.handleModalPopup(null, 'showDrugAllergyModal', { message: 'The patient has ' + drugAllergicIntensity.name + ' severity allergy to ' + drugAllergicText + '. Are you sure you want to continue?' }, i);
    }
    else if (this.state.currentMedicinesData.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
      this.handleModalPopup(null, 'showDrugPolyPharmacyModal', { message: 'The patient is already on ' + this.state.currentMedicinesData.length + ' medications. Are you sure you want to prescribe more?' }, i);
      // this.setState({polyPharmacyWarningShown:true})
    }
    else {
      // cimsAPI($ggpi);
    }
  }

  handleSelectAllPolyAllergy = () => {
    const { drugsData, currentAllergiesData, currentMedicinesData } = this.state;
    var drugAllergic = false;
    var drugAllergicText = '';
    var drugAllergicIntensity = '';
    const { patientDrugAllergies } = this.state;
    const allergyIntensity = this.state.constants.allergyIntensity;
    var allergyDrugs = [];
    var allergiesMessage = '';
    allergiesMessage += `<p className="mb-0 font-weight-bold">The patient has:</p> <ul className='custom-list list-success'>`;
    drugsData.map((v, k) => {
      var drugGeneric = v.drug_generic;
      if (drugGeneric && drugGeneric.length > 0) {
        drugGeneric.map((v, k) => {
          patientDrugAllergies.map((allergy, k) => {
            if (allergy.allergy_code == v.generic_id.code) {
              allergyDrugs.push(v.drug_id);
              drugAllergic = true;
              allergiesMessage += `<li>` + allergyIntensity[parseInt(allergy.intensity)].name + ` severity allergy to ` + v.generic_id.generic_name + `</li>`
            }
          })
        });
      }
    })
    allergiesMessage += '</ul><p className="mb-0">Are you sure you want to continue?</p>';

    if (drugAllergic) {
      this.handleModalPopup(null, 'showDrugAllergyModal', { message: allergiesMessage, type: 'all', deleteObj: allergyDrugs });
    }
    else if (this.state.currentMedicinesData.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
      this.handleModalPopup(null, 'showDrugPolyPharmacyModal', { message: 'The patient is already on ' + this.state.currentMedicinesData.length + ' medications. Are you sure you want to prescribe more?', type: 'all' });
      // this.setState({polyPharmacyWarningShown:true})
    }
    else {
      // cimsAPI($ggpi);
    }
  }

  handleSelectAllClick = (e) => {
    this.setState({ isSelectAllChecked: !this.state.isSelectAllChecked }, () => {
      let { assessmentData, drugsData, testData, instructionsCheckSelected, supportingCheckSelected, isSelectAllChecked, chiefCompCheckSelected, physicalExaminationSelected, clinicalNotesSelected, medicineAlertPopup, customChildFieldData, customParentFieldData, ss_configuration, consult_type, customSubjectiveData, customSubjectiveParentData , subjectiveData } = this.state;

      assessmentData.map((v, k) => {
        assessmentData[k].selected = isSelectAllChecked;
      })

      drugsData.map((v, k) => {
        drugsData[k].selected = isSelectAllChecked;
      })

      testData.map((v, k) => {
        testData[k].selected = isSelectAllChecked;
      })

      let chiefSubjective = subjectiveData.subjectives;
      if(chiefSubjective && Object.values(chiefSubjective).length > 0){
        let subjective = {}
        Object.values(chiefSubjective).map((v, k) => {
          chiefSubjective[k].selected = isSelectAllChecked;
          subjective[k] = v;
        })
        subjectiveData.subjectives = subjective;
      }

      let {subjectives} = customSubjectiveData;
      if(subjectives && Object.values(subjectives).length > 0){
        let childSubjective = {}
        Object.values(subjectives).map((v, k) => {
          if(ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === v.custom_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ){
            subjectives[k].selected = isSelectAllChecked;
          }
          childSubjective[k] = v;
        })
        customSubjectiveData.subjectives = childSubjective;
      }
      
      // if(customChildFieldData && customChildFieldData.length>0){
      //   customChildFieldData.map((v, k) => {
      //     if(ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === v.custom_child_field_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ){
      //       customChildFieldData[k].selected = isSelectAllChecked;
      //     }
      //   })
      // }

      let {parent_subjectives} = customSubjectiveParentData;
      if(parent_subjectives && Object.values(parent_subjectives).length>0){
        let parentSubjective = {}
        Object.values(parent_subjectives).forEach((v, k) => {
          if(ss_configuration.some(o => (o?.id === v.custom_id && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))){
            parent_subjectives[k].selected = isSelectAllChecked;
          }
          parentSubjective[k] = v;
        })
        customSubjectiveParentData.parent_subjectives = parentSubjective;
      }
     
      // if(customParentFieldData && customParentFieldData.length>0){
      //   customParentFieldData.map((v, k) => {
      //     if(ss_configuration.some(o => (o?.id === v.custom_parent_field_id && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))){
      //       customParentFieldData[k].selected = isSelectAllChecked;
      //     }
      //   })
      // }
     

      instructionsCheckSelected = isSelectAllChecked;

      supportingCheckSelected = isSelectAllChecked;

      chiefCompCheckSelected = isSelectAllChecked;

      physicalExaminationSelected = isSelectAllChecked;
      
      clinicalNotesSelected = isSelectAllChecked;

      medicineAlertPopup = isSelectAllChecked;
      this.setState({
        assessmentData, drugsData, testData, instructionsCheckSelected, supportingCheckSelected, chiefCompCheckSelected, physicalExaminationSelected, clinicalNotesSelected, customChildFieldData, customParentFieldData, customSubjectiveData, customSubjectiveParentData,subjectiveData
      }, () => { this.checkSelectedStatus();})
      if (isSelectAllChecked)
        this.handleSelectAllPolyAllergy()
    })
  }

  handelMedicineAlert = () => {

    this.setState({
      medicineAlertPopup: true,
    })
  }

  handleAllergyOverrule = (e, index) => {
    this.handlePolyPharmacyMessage(index);
    this.setState({
      showDrugAllergyModal: false,
    })
  }

  handlePolyPharmacyMessage = (index) => {
    if (this.state.currentMedicinesData.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
      this.handleModalPopup(null, 'showDrugPolyPharmacyModal', { message: 'The patient is already on ' + this.state.currentMedicinesData.length + ' medications. Are you sure you want to prescribe more?' }, index);

    }
  }

  handleChangeAllergyDrug = (e) => {
    let dataArray = this.state.drugsData;
    if (typeof this.state.deleteObjId.length != 'undefined' && this.state.deleteObjId.length > 0) {
      dataArray.map((v, k) => {
        if (this.state.deleteObjId.includes(v.drug_id))
          dataArray.splice(k, 1);
      })
    }
    else {
      dataArray.splice(this.state.deleteObjId, 1);
    }
    this.setState({
      drugsData: dataArray, deleteObjId: null
    }, () => { this.handleModalPopup(null, 'showDrugAllergyModal'); this.checkSelectedStatus() });

  }

  handlePolyPharmacyNo = async (e) => {
    //  debugger;
    await this.setState({
      disableConfirmBtn: true,
      isSelectAllChecked: false
    })
    let drugsData = this.state.drugsData;
    if (this.state.deleteObjId != null && typeof this.state.deleteObjId.length != 'undefined') {

      drugsData.splice(this.state.deleteObjId, 1);
    }
    else {
      drugsData.map((v, k) => {
        drugsData[k].selected = false;
      })
    }
    this.setState({
      drugsData, deleteObjId: null
    }, () => { this.handleModalPopup(null, 'showDrugPolyPharmacyModal'); });// this.checkSelectedStatus() 
  }

  handlePolyPharmacyYes = (e) => {
    // debugger;
    this.setState({
      polyPharmacyWarningShown: true,
      polyPharmacyModal: false
    }, () => { this.handleModalPopup(null, 'showDrugPolyPharmacyModal'); this.checkSelectedStatus() });


  }

  onChangeSubjectives = (e) => {
    let { subjectiveData } = this.state;
    var index = e.target.attributes.dataindex.value;
    let data = e.target.value;
    data = data.charAt(0).toUpperCase() + data.slice(1);
    subjectiveData.details[parseInt(index)].description = data;
    this.setState({ subjectiveData });

  }

  setSubjectiveValues(index, sameRxdate, subjectiveData, dataid) {
    let value = '';
    if (subjectiveData.details.length) {
      let findex = subjectiveData.details.findIndex((data) => data.subjective_id == dataid);
      if (findex !== -1) {
        value = subjectiveData.details[findex].description !== null ? subjectiveData.details[findex].description : '';
      }
    }

    //let value = subjectiveData.details[index] && subjectiveData.details[index].description !== null ? subjectiveData.details[index].description : '';

    /* if (parseInt(dataid) === index) {
      this.subjectiveDataInitial[index] = { id: dataid, value: value };
    } */

    this.subjectiveDataInitial[index] = { id: dataid, value: value };
    return this.subjectiveDataInitial[index] != undefined ? this.subjectiveDataInitial[index]['value'] : '';
  }

  IsApplyCareProtoCaol = async (e) => {

    
    let drugIds;
    let addedAssessmentList;
    let {template_id,copyType,drugsData,assessmentData,referenceId,StoreId,FacilityId,enterpriseId,patientId} = this.state;

    drugsData = drugsData.filter((drug) => drug.selected);
    assessmentData = assessmentData.filter((ass) => ass.selected);

    
    if (copyType == 'visit' || copyType == 'template' ) {
      if(drugsData && drugsData.length > 0){
        var drugId = [];
        drugsData.map((v, j) => {
          drugId.push(
            v.drug_id
          );
        })
        drugIds = drugId.join('-');
      }else{
        drugIds     = drugIds == undefined?0:0;
      }
      if(assessmentData && assessmentData.length > 0){
        var assessmentDataID = [];
        assessmentData.map((d, i) => {
          assessmentDataID.push(
            d.diagnosis_id
          );
        })
        addedAssessmentList = assessmentDataID.join('-');
      }else{
        addedAssessmentList   = addedAssessmentList == undefined?0:0;
      }
    }else{
      drugIds               = drugIds == undefined?0:0;
      addedAssessmentList   = addedAssessmentList == undefined?0:0;
    }

    let referenceType       = 0;
    let orederSetId         = 0;
    let medicineScreen      = 'careplanform';

   if (CIMSALLOW){
    await axios.get(CIMS_CAREPLAN_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, template_id, medicineScreen, addedAssessmentList }))
      .then(success => {
        if (success.data.interaction) {
          this.checkCimsInteraction = true;
          this.setState({drug_id_Interaction_Modalurl:drugIds, careProtoColId: template_id, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId,addedAssessmentList:addedAssessmentList, isModalInteractionVisible: this.checkCimsInteraction });
        }
        else {
          this.checkCimsInteraction = false
          //this.handleOnConfirmSubmit(e);
        }
      })
      .catch(err => console.log(err))
  }else{
    this.checkCimsInteraction = false;
  }

  }
  handleInteractionCancel = () => {
    this.setState({ isModalInteractionVisible: false });
    this.checkCimsInteraction = false
  };

  handleInteractionOk = () => {
    this.setState({ isModalInteractionVisible: false },
      () => { this.handleOnConfirmSubmit('', 'No') });
  };

  onChangeCustomSubjectives = (e) => {
    let { customSubjectiveData } = this.state;
    var index = e.target.attributes.name.nodeValue;
    let data = e.target.value;
    data = data.charAt(0).toUpperCase() + data.slice(1);
    customSubjectiveData.details[index] = data;
    this.setState({ customSubjectiveData });
  }

  setCustomSubjectiveLabel(custom_subject_id) {
    let name = '';
    let { ss_configuration, consult_type } = this.state;
    var custom_parent = ss_configuration.find(o => o?.id === 3)
    if (custom_parent && custom_parent.sub_items) {
      let custom_data = custom_parent.sub_items.find(p => ((p?.id === custom_subject_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
      if(custom_data)
      {
        name = custom_data?.custom?.value
      }
    }

    return name;
  }

  setCustomSubjectiveValues(index, sameRxdate, customSubjectiveData, dataid, input_name) {
    let value = '';
    if (customSubjectiveData.details) {
      value = customSubjectiveData.details[input_name] !== null ? customSubjectiveData.details[input_name] : '';
    }

    this.customSubjectiveDataInitial[index] = { id: dataid, value: value };
    return this.customSubjectiveDataInitial[index] != undefined ? this.customSubjectiveDataInitial[index]['value'] : '';
  }

  handleOnConfirmSubmit = async (e, type = null) => {
    //e.preventDefault()
    var visible = this.state.visible;
    if (this.state.visible != 'false' && type == null) {
      e.preventDefault()
    }
    if (type == null && this.checkCimsInteraction == false) {
      await this.IsApplyCareProtoCaol()
    }
    if (this.checkCimsInteraction == true && visible == null && type == null  && this.state.copyType == 'visit'
    ) {
      return;
    }

    if (this.checkCimsInteraction == true && type == null  && this.state.copyType == 'template'
    ) {
      return;
    }
    let { hospital_id, patient_id, patient, spin_loading, reference_id, subjectiveData, customSubjectiveData,chiefComplaintData, physicalExaminationData, chiefCompCheckSelected, physicalExaminationSelected, customChildFieldData, customParentFieldData, clinicalNotesSelected, clinicalNotesData,customSubjectiveParentData, finalClinicalNotes, copyType, assessmentData, testData,instructionsCheckSelected } = this.state;
    const { qms_token_id } = patient;
    if (this.state.visible != 'false') {
      if (this.state.copyType != 'template') {
        if (this.state.subjectiveData.details[0].description.length === 0) {
          notification.error({
            message: 'Please fill subjective details',
            placement: 'topRight'
          })
          return;
        }
      }
    }

    var subjectiveDetails = [];
    if (this.state.copyType != 'template') {
      this.state.subjectiveData.details.map((v, k) => {
        subjectiveDetails.push({
          id: v.subjective_id,
          value: v.description,
        });
      })
    }

    if(copyType == "template"){
      let chiefComplaintSelect = false;
      let PhysicalExaminationSelect = false;
      if(subjectiveData && subjectiveData.subjectives && Object.values(subjectiveData.subjectives).length>0){
        Object.values(subjectiveData.subjectives).forEach(item=>{
          if(item.id == 0){
            chiefComplaintSelect = item.selected;
          }
          if(item.id == 2){
            PhysicalExaminationSelect = item.selected;
          }
        })
      }
      if(subjectiveData && subjectiveData.details && subjectiveData.details.length > 0){
        subjectiveData.details.forEach(item=>{
          if(item.subjective_id == 0 && chiefComplaintSelect){
            subjectiveDetails.push({
              id: item.subjective_id,
              value: item.description
            })
          }
          if(item.subjective_id == 2 && PhysicalExaminationSelect){
            subjectiveDetails.push({
              id: item.subjective_id,
              value: item.description
            })
          }
        })
      }

      let customChildDetails = {}
      if(customSubjectiveData && customSubjectiveData.subjectives && Object.values(customSubjectiveData.subjectives).length>0){
        Object.values(customSubjectiveData.subjectives).forEach(item=>{
          if(item.selected){
            customChildDetails[item.input_name] = customSubjectiveData.details[item.input_name]
          }
        })
        customSubjectiveData.details = customChildDetails;
        this.setState({customSubjectiveData})
      }

      let customParentDetails = {}
      if(customSubjectiveParentData && customSubjectiveParentData.parent_subjectives && Object.values(customSubjectiveParentData.parent_subjectives).length>0){
        Object.values(customSubjectiveParentData.parent_subjectives).forEach(item=>{
          if(item.selected){
            customParentDetails[item.input_name] = customSubjectiveParentData.details[item.input_name]
          }
        })
        customSubjectiveParentData.details = customParentDetails;
        this.setState({customSubjectiveParentData})
      }
    }
  
    if (this.state.copyType == 'template' && clinicalNotesSelected == true ) {
        var selectedClinicalNotesData = finalClinicalNotes;
    }

    const materialObj = this.state.allSupportingMaterial.filter(file => file.selected).map(filtered => ({ id: filtered.id, file_name: filtered.fileName, file_url: filtered.fileUrl }));
    const selected_drugsData = this.state.drugsData?.length > 0 ? await this.state.drugsData?.filter(file => file.selected) : [];

    if(copyType == "template"){
      let assessment_data = assessmentData.filter(item => item.selected)
      let test_data = testData.filter(item=> item.selected)
      if(!instructionsCheckSelected){
        this.setState({instructionsData:[]})
      }
      this.setState({
        assessmentData : assessment_data,
        testData: test_data
      })
    }


    const PARAMS = {
      referenceType: LS_SERVICE.get('reference_type'),
      referenceId: reference_id,
      encounter_id: parseInt(LS_SERVICE.get('PATIENT_ENCOUNTER_ID')),
      copyType: this.state.copyType,
      assessments_data: this.state.assessmentData,
      drugs_data: selected_drugsData,
      tests_data: this.state.testData,
      instruction: this.state.instructionsData,
      supportingMaterial: materialObj,
      template_id: this.state.template_id,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      is_event_based: this.state.template?.isEventBased == 'Y' ? true : false,
      type: 'SS',
      user_name: LS_SERVICE.get("staff_name")
    }
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
    if (this.state.copyType != 'template') {
      PARAMS.subjective_data = subjectiveDetails;
      PARAMS.custom_subjective_data = customSubjectiveData.details;
      PARAMS.custom_parent_subjective_data = customSubjectiveParentData.details;
    }

    if(this.state.copyType == 'template'){
      PARAMS.subjective_data = subjectiveDetails;
      PARAMS.custom_subjective_data = customSubjectiveData.details;
      PARAMS.custom_parent_subjective_data = customSubjectiveParentData.details;
      PARAMS.selectedClinicalNotesData = selectedClinicalNotesData;
      
    }

    const firstElement = subjectiveDetails.find(item => item.id === 0);

    let isChiefComplaintEmpty = true;
    if (firstElement && firstElement.value != undefined && firstElement.value != null && firstElement.value != "") {
      isChiefComplaintEmpty = false
    }

    if (!isChiefComplaintEmpty) {
      this.updateSubjectiveAudits();
    }

    if (this.state.visible != 'false') {
      Axios.post(COPY_VISIT_APPLY, qs.stringify(PARAMS)).then(success => {
        if (!isChiefComplaintEmpty) {
          this.updateSubjectiveAudits();
        }
        if (success.data.status) {
          if (this.state.type == 'popup') {
            // this.checkCimsInteraction ==false
            this.props.updateData();
          } else {
            // this.checkCimsInteraction ==false
            this.props.history.push({
              pathname: `/patient/soapss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`
            });
            /* this.props.history.push({
              pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`
            }); */
          }
        }
      }).catch(err => console.log(err))
    } else {

      this.setState({
        spin_loading: !spin_loading
      }, () => {
        Axios.post(COPY_VISIT_APPLY, qs.stringify(PARAMS)).then(success => {
          if (success.data.status) {
            if (this.state.type == 'popup') {
              this.props.updateData();
              // this.checkCimsInteraction ==false
            } else {
              // this.checkCimsInteraction ==false
              this.props.history.push({
                pathname: `/patient/soapss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`
              });
              /* this.props.history.push({
                pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${reference_id}`
              }); */
            }
            this.setState({
              spin_loading: !spin_loading
            })
          }
        }).catch(err => console.log(err))
      })
    }
  }

  updateSubjectiveAudits = () => {
    subjectiveAuditsService.init({
      CCAudits: true
    });
  }

  handleStatusSwitch = async (e, i, data) => {
    const { id, status } = data;
    let assessmentData = this.state.assessmentData;

    assessmentData[i].status = e ? 0 : 1;
    this.setState({ assessmentData });

    /*const switchPromise = await Axios.get(ASSESSMENT_STATUS_UPDATE({ id, status: !status ? 1 : 0 }))

    if (switchPromise) {
      this.loadData();

      const { data } = switchPromise;

      notification.success({
        message: data.message,
        placement: 'topRight'
      })
    }*/
  }

  //Subjective Feild Focus Handling
  handleOnFocus = (e) => {
    this.setState((prevState) => ({
      speech: {
        ...prevState.speech,
        state: false
      }
    }), () => {
      speechService.init(this.state.speech);
    })
  }
  //Subjective Feild Focus Handling

  getMaterialData = (materialData) => {
    this.setState({ allSupportingMaterial: materialData });
  }

  saveSupportingMaterial = async _ => {
    const { match: { params }, location: { state } } = this.props;
    let { qms_token_id } = params;


    this.setState({ supportingMaterial: this.state.allSupportingMaterial.filter(file => file.selected) });
    this.handleModalPopup(null, 'showSupportingModal');
  }

  updateSelect(data) {
    // const { drugsData } = this.state
    // drugsData.map((v, k) => {
    //   drugsData[k].selected = false;
    // })
    this.setState({
      isSelectAllChecked: data,
      disableConfirmBtn: !data,
    })
  }

  onChangeClinicalNotes(e){
    let {finalClinicalNotes} = this.state;
    let data = e.target.value;
    data = data.charAt(0).toUpperCase() + data.slice(1);
    finalClinicalNotes = data;
    this.setState({finalClinicalNotes})
  }

  setCustomParentSubjectiveLabel(custom_subject_id) {
    let name = '';
    let { ss_configuration, consult_type } = this.state;
    let custom_data = ss_configuration.find(p => ((p?.id === custom_subject_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
    if (custom_data) {
      name = custom_data?.custom?.value
    }
    return name;
  }
  
  
  onChangeCustomParentSubjectives = (e) => {
    let { customSubjectiveParentData } = this.state;
    var index = e.target.attributes.name.nodeValue;
    let data = e.target.value
    data = data.charAt(0).toUpperCase() + data.slice(1);
    customSubjectiveParentData.details[index] = data;
    this.setState({ customSubjectiveParentData });
  }
  
  
  setCustomParentSubjectiveValues(index, sameRxdate, customSubjectiveData, dataid, input_name) {
    let value = '';
    if (customSubjectiveData.details) {
      value = customSubjectiveData.details[input_name] !== null ? customSubjectiveData.details[input_name] : '';
    }
    this.customParentSubjectiveDataInitial[index] = { id: dataid, value: value };
    return this.customParentSubjectiveDataInitial[index] != undefined ? this.customParentSubjectiveDataInitial[index]['value'] : '';
  }
  

  render() {
    const idOfHospital = this.state.hospital_id;
    const { sameRxdate, ss_configuration, consult_type, subjectiveData, customSubjectiveData, patient, CheckSelect, type, copyType,customSubjectiveParentData, finalClinicalNotes, template, obsGynae} = this.state;

    var custom1 = {};
    var custom2 = {};
    var custom3 = {};
    var custom4 = {};
    var custom5 = {};
    var parent_custom1 = {};
    var parent_custom2 = {};
    var parent_custom3 = {};
    var parent_custom4 = {};
    var parent_custom5 = {};

    if (ss_configuration) {
      var custom_parent = ss_configuration.find(o => o?.id === 3)
      if (custom_parent && custom_parent.sub_items) {
        custom1 = custom_parent.sub_items.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom2 = custom_parent.sub_items.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom3 = custom_parent.sub_items.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom4 = custom_parent.sub_items.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom5 = custom_parent.sub_items.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
      }
      if (ss_configuration.some(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom1 = ss_configuration.find(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom2 = ss_configuration.find(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom3 = ss_configuration.find(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom4 = ss_configuration.find(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom5 = ss_configuration.find(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
    }
    let child_field = {
      11: custom1?.custom?.value != undefined && custom1?.custom?.value != '' ? custom1?.custom?.value : " Custom Child Field 1",
      12: custom2?.custom?.value != undefined && custom2?.custom?.value != '' ? custom2?.custom?.value : " Custom Child Field 2",
      13: custom3?.custom?.value != undefined && custom3?.custom?.value != '' ? custom3?.custom?.value : " Custom Child Field 3",
      14: custom4?.custom?.value != undefined && custom4?.custom?.value != '' ? custom4?.custom?.value : " Custom Child Field 4",
      15: custom5?.custom?.value != undefined && custom5?.custom?.value != '' ? custom5?.custom?.value : " Custom Child Field 5"
    }
    let parent_field = {
      11: parent_custom1?.custom?.value != undefined && parent_custom1?.custom?.value != '' ? parent_custom1?.custom?.value : " Custom Parent Field 1",
      12: parent_custom2?.custom?.value != undefined && parent_custom2?.custom?.value != '' ? parent_custom2?.custom?.value : " Custom Parent Field 2",
      13: parent_custom3?.custom?.value != undefined && parent_custom3?.custom?.value != '' ? parent_custom3?.custom?.value : " Custom Parent Field 3",
      14: parent_custom4?.custom?.value != undefined && parent_custom4?.custom?.value != '' ? parent_custom4?.custom?.value : " Custom Parent Field 4",
      15: parent_custom5?.custom?.value != undefined && parent_custom5?.custom?.value != '' ? parent_custom5?.custom?.value : " Custom Parent Field 5"
    }
    return (
      this.state.visible != 'false' ?
        <>

          {/* <!-- Page sidebar--> */}
          {patient != undefined && patient.patient_id != undefined && patient.patient_id != null && type != 'popup' ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

          {/* <MyHcSpeechRecognition /> */}

          <div className="content-wrapper copy_care" style={type != 'popup' ? {} : { marginLeft: '0' }}>
            <div className="container-fluid px-0">
              {this.state.DataLoaded === true ? (
                <>
                  <div className="row mt-0" id="main-page-container">
                    <div className="col-12">
                      <form id="template-select-form" className="emr emrfrm subjective-form" method="POST" action="" acceptCharset="UTF-8" encType="multipart/form-data" autoComplete="off">
                        {copyType != 'template' ?
                          <div className="row">
                            <div className="col-12 mt-2">
                              <div className="float-left">
                                <h1 className="page-title">Subjective</h1>
                              </div>
                              {type != 'popup' ?
                                <div className="float-right">
                                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                                </div>
                                : null}
                            </div>
                            <div className="col-12 mt-2 subjective-data">
                              {
                                typeof subjectiveData !== 'undefined' && this.state.DataLoaded ?
                                  Object.values(subjectiveData.subjectives).map((subjectivObj, index) =>
                                    <>
                                      {/* {subjectiveData.details[index].description !==""? */}
                                      <div className="form-group micwrap normal mb-4" key={index}>
                                        <label htmlFor="Chief Complaints" className="control-label mb-0">{subjectivObj.display_text} <span className="text-danger">{subjectivObj.required === true ? '*' : ''}</span></label>
                                        <>
                                          <Link
                                            onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: subjectivObj.input_name }) }}
                                            className={(this.state.speech.identifier === subjectivObj.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                          <textarea onChange={this.onChangeSubjectives} dataid={subjectivObj.id} dataindex={index} className="form-control subjective-field" ref={subjectivObj.input_name}
                                            style={{ minHeight: (index === 0 && sameRxdate) ? '70px' : '' }}
                                            name={subjectivObj.input_name} row="1" onInput={(e) => this.autoHeight(e)}
                                            value={this.setSubjectiveValues(index, sameRxdate, subjectiveData, subjectivObj.id)}
                                            onFocus={this.handleOnFocus}
                                          />
                                        </>
                                      </div>
                                    </>
                                  ) : null
                              }
                            </div>
                          </div>
                          : null}

                        {copyType != 'template' ?
                        <div className="row">
                          <div className="col-12 mt-2 subjective-data">
                            {
                              typeof customSubjectiveData !== 'undefined' && this.state.DataLoaded ?
                                Object.values(customSubjectiveData.subjectives).map((customSubjectivObj, index) =>
                                  <>
                                  {
                                    ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === customSubjectivObj.custom_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ? 
                                      <div className="form-group micwrap normal mb-4" key={index}>
                                        <label htmlFor="{customSubjectivObj.display_text}" className="control-label mb-0">{this.setCustomSubjectiveLabel(customSubjectivObj.custom_id)}</label>
                                        <>
                                          <Link
                                            onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: customSubjectivObj.input_name }) }}
                                            className={(this.state.speech.identifier === customSubjectivObj.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                          <textarea onChange={this.onChangeCustomSubjectives} dataid={customSubjectivObj.id} dataindex={index} className="form-control subjective-field" ref={customSubjectivObj.input_name}
                                            style={{ minHeight: (index === 0 && sameRxdate) ? '70px' : '' }}
                                            name={customSubjectivObj.input_name} row="1" onInput={(e) => this.autoHeight(e)}
                                            value={this.setCustomSubjectiveValues(index, sameRxdate, customSubjectiveData, customSubjectivObj.id, customSubjectivObj.input_name)}
                                            onFocus={this.handleOnFocus}
                                          />
                                        </>
                                      </div>
                                      :
                                      null
                                  }
                                  </>
                                ) : null
                            }
                          </div>
                        </div>
                        : null}

                      {copyType != 'template' ?
                        <div className="row">
                        <div className="col-12 mt-2 subjective-data">
                          {
                            typeof customSubjectiveParentData !== 'undefined' && this.state.DataLoaded ?
                              Object.values(customSubjectiveParentData.parent_subjectives).map((customSubjectivObj, index) =>
                                <>
                                  {
                                    ss_configuration.some(p => ((p?.id === customSubjectivObj.custom_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) ?
                                      <>
                                        <div className="form-group micwrap normal mb-4" key={index}>
                                          <label htmlFor="{customSubjectivObj.display_text}" className="control-label mb-0">{this.setCustomParentSubjectiveLabel(customSubjectivObj.custom_id)}</label>
                                          <>
                                            <Link
                                              onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: customSubjectivObj.input_name }) }}
                                              className={(this.state.speech.identifier === customSubjectivObj.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                            <textarea
                                              onChange={this.onChangeCustomParentSubjectives}
                                              dataid={customSubjectivObj.id} dataindex={index} className="form-control subjective-field" ref={customSubjectivObj.input_name}
                                              style={{ minHeight: (index === 0 && sameRxdate) ? '70px' : '' }}
                                              name={customSubjectivObj.input_name} row="1" onInput={(e) => this.autoHeight(e)}
                                              value={this.setCustomParentSubjectiveValues(index, sameRxdate, customSubjectiveParentData, customSubjectivObj.id, customSubjectivObj.input_name)}
                                              onFocus={this.handleOnFocus}
                                            />
                                          </>
                                        </div>
                                      </>
                                      :
                                      null
                                  }
                                </>
                              ) : null
                          }
                        </div>
                      </div>
                        : null}

                        <div className="row mt-0">
                          <div className="col-12 mb-3">
                            <div className="float-left">
                              <h1 className="page-title">Assessment</h1>
                            </div>
                            <div className="float-right">
                              <a href="#" className="btn btn-outline-primary small text-uppercase px-2" onClick={e => this.handleModalPopup(e, 'showAssessmentModal')}>+ Assessment</a>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <AssessmentTable assessmentData={this.state.assessmentData} viewType={'copy-to-visit'} statusSwitch={this.handleStatusSwitch} showAction={true} showSelectCheck={true} constants={this.state.constants} updateSelection={this.handleSelectCheck} handlePopUp={this.handleModalPopup}  />
                            </div>
                          </div>
                          <br />
                        </div>

                        {
                          this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null
                            ?
                            <div className="row mt-2">
                              <>
                                <div className="col-3 emr-form emr-medicine-form template-medicine-form">
                                  <div className="form-group hasdata">

                                    <DatePicker
                                      id="protocol_start_date"
                                      name="protocol_start_date"
                                      format="DD MMM, YYYY"
                                      showNow={true}
                                      placeholder={""}
                                      allowClear
                                      defaultPickerValue={this.state.smallest_due_date}
                                      disabled={this.state.drugsnotcustom && this.state.drugsnotcustom > 0 ? true : false}
                                      showTime={{ defaultValue: moment() }}
                                      defaultValue={this.state.smallest_due_date}
                                      value={moment(this.state.smallest_due_date).diff(moment(), 'days') < 0 ? moment() : this.state.smallest_due_date}
                                      className="form-control datetimepicker"
                                      onOk={this.setDateTimeHandler}
                                    />
                                    <label htmlFor="key_finding_date_time">Protocol Start Date</label>
                                  </div>
                                </div>
                                <div className="col-4 emr-form emr-medicine-form template-medicine-form">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={"Protocol: " + this.state.smallest_protocol}
                                  />
                                </div>
                              </>
                              {
                                this.state.obsGynae?.obstetric?.basic?.pregnant == 1
                                  ?
                                  <>
                                    {
                                      (
                                        this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday &&
                                        this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday != null
                                      )
                                        ||
                                        (
                                          this.state.obsGynae?.obstetric?.basic?.gestationalAgeLmpAsOnToday &&
                                          this.state.obsGynae?.obstetric?.basic?.gestationalAgeLmpAsOnToday != null
                                        )
                                        ?
                                        <div className="col-4 emr-form emr-medicine-form template-medicine-form">
                                          <input
                                            type="text"
                                            className="form-control"
                                            disabled={true}
                                            value={
                                              (
                                                this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday &&
                                                this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday != null
                                              )
                                                ?
                                                'Gestational age (USG): ' + this.state.obsGynae?.obstetric?.basic?.gestationalAgeUsgAsOnToday
                                                :
                                                'Gestational age (LMP): ' + this.state.obsGynae?.obstetric?.basic?.gestationalAgeLmpAsOnToday
                                            }
                                          />
                                        </div>
                                        :
                                        null
                                    }
                                  </>
                                  :
                                  null
                              }
                            </div>
                            :
                            <div className="row mt-2"></div>
                        }

                        <div className={this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' ? "row" : "row mt-3"}>
                          <div className="col-12 mb-1 emr-form emr-medicine-form template-medicine-form">
                            <div className="float-left">
                              <h3 className="page-title mt-2">Medicines</h3>
                            </div>
                            <div className="float-right">
                              {
                                LS_SERVICE.get('isObsGynaeDoctor') == 1 && LS_SERVICE.get('isObsGynaePatient') == true && this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null
                                  ?
                                  <>
                                    <Badge className="mr-2 legends-badge" color={"rgb(247, 234, 234)"} text={"Missed"} />
                                    <Badge className="mr-2 legends-badge" color={"rgb(221, 219, 219)"} text={"Previously Prescribed"} />
                                    <Badge className="mr-2 legends-badge" color={"rgb(220, 237, 244)"} text={"Due Now"} />
                                    <Badge className="mr-2 legends-badge" color={"rgb(255 255 255)"} text={"Due Later"} />
                                  </>
                                  :
                                  null
                              }
                              <a href="#" className="btn btn-outline-primary small px-2" onClick={e => this.handleModalPopup(e, 'showMedicineModal')}>+ MEDICINE</a>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <MedicineTable alreadyPrescribedDrugs={this.state.already_prescribed_drugs} updateData={this.updateMedicineDate} patientInfo={this.state.patient} patientObsGynaeInfo={this.state.obsGynae} eventBased={this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null ? true : false} isEventBasedSS = {this.state.isEventBasedSS} drugsData={this.state.drugsData} viewType={'copy-to-visit'} medicineFlag={'copytovisit'} addedAssessmentList={this.state.addedAssessmentList} addedDrugsList={this.state.addedDrugsList} showAction={true} showSelectCheck={true} constants={this.state.constants} updateSelection={this.handleSelectCheck} handlePopUp={this.handleModalPopup} />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 mb-1">
                            <div className="float-left">
                              <h3 className="page-title mt-2">Tests/Procedures</h3>
                            </div>
                            <div className="float-right">
                              <a href="#" className="btn btn-outline-primary small" onClick={e => { this.handleModalPopup(e, 'showTestModal') }}>+ TEST</a>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 mb-1">
                            <TestTable editAllowed="N" pateintInfo={this.state.patient} alreadyPrescribedTests={this.state.already_prescribed_tests} updateData={this.updateTestDate} eventBased={this.state.template?.isEventBased && this.state.template?.isEventBased == 'Y' && this.state.obsGynae?.mensural?.lmpDate && this.state.obsGynae?.mensural?.lmpDate != null ? true : false} testData={this.state.testData} viewType={'copy-to-visit'} showAction={true} showSelectCheck={true} constants={this.state.constants} updateSelection={this.handleSelectCheck} handlePopUp={this.handleModalPopup} isEventBasedSS={this.state.isEventBasedSS}/>
                          </div>

                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="float-left">
                              <h3 className="page-title mt-1">Instructions</h3>
                            </div>
                            <div className="text-right">
                              <a href="#" className="btn btn-outline-primary small" onClick={e => this.handleModalPopup(e, 'showInstructionModal')}>+ INSTRUCTIONS</a>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-10">
                            <ul className="custom-list list-success">
                              <InstructionTable instructionsData={this.state.instructionsData} viewType={'full'} constants={this.state.constants} />
                            </ul>
                          </div>
                          <div className="col-2 text-right">
                            <span className="d-inline mr-4">
                              <i data-checkSelect="instructions" data-type={'instructions'} onClick={this.handleInstructionsSelectCheck}
                                className={this.state.instructionsCheckSelected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                            </span>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="float-left">
                              <h3 className="page-title mt-2">Supporting Material</h3>
                            </div>
                            <div className="text-right">
                              <a href="#" className="btn btn-outline-primary small px-2" onClick={e => this.handleModalPopup(e, 'showSupportingModal')}>+ Supporting Material</a>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-10">
                            <ul className="custom-list list-success">
                              <SupportingMaterialTable
                                supportingMaterialData={this.state.supportingMaterial}
                                viewType={'full'} hospitalId={this.state.hospital_id}
                                constants={this.state.constants} />
                            </ul>
                          </div>
                          <div className="col-2 text-right">
                            <span className="d-inline mr-4 mb-2">
                              <i data-checkSelect="supportingMaterial" data-type={'supportingMaterial'} onClick={this.handleSupportingSelectCheck}
                                className={this.state.supportingCheckSelected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                            </span>
                          </div>
                        </div>

                        {
                          copyType == "template" ?
                            <>
                              <div className="col-12 mt-2 subjective-data">
                                {
                                  typeof subjectiveData !== 'undefined' && this.state.DataLoaded ?
                                    Object.values(subjectiveData.subjectives).map((subjectivObj, index) =>
                                      <>
                                        <div className="form-group micwrap normal mb-4" key={index}>
                                          <label htmlFor="Chief Complaints" className="control-label mb-0">{subjectivObj.display_text} <span className="text-danger">{subjectivObj.required === true ? '*' : ''}</span></label>
                                          <>
                                            <Link
                                              onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: subjectivObj.input_name }) }}
                                              className={(this.state.speech.identifier === subjectivObj.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                            <textarea onChange={this.onChangeSubjectives} dataid={subjectivObj.id} dataindex={index} className="form-control subjective-field" ref={subjectivObj.input_name}
                                              style={{ minHeight: (index === 0 && sameRxdate) ? '70px' : '' }}
                                              name={subjectivObj.input_name} row="1" onInput={(e) => this.autoHeight(e)}
                                              value={this.setSubjectiveValues(index, sameRxdate, subjectiveData, subjectivObj.id)}
                                              onFocus={this.handleOnFocus}
                                            />
                                          </>
                                        </div>
                                        <div className="text-right">
                                          <span className="d-inline mr-4">
                                            <i data-checkSelect={index} data-type={'subjectiveData'}
                                              onClick={() => this.handleSelectCheck(index, "subjectiveData")}
                                              className={subjectivObj.selected ? "btn-true-sm accept" : "btn-true-sm"}
                                            >
                                            </i>
                                          </span>
                                        </div>
                                      </>
                                    ) : null
                                }
                              </div>

                              <div className="row">
                                <div className="col-12 mt-2 subjective-data">
                                  {
                                    typeof customSubjectiveData !== 'undefined' && this.state.DataLoaded ?
                                      Object.values(customSubjectiveData.subjectives).map((customSubjectivObj, index) =>
                                        <>
                                          {
                                            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === customSubjectivObj.custom_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) ?
                                              <>

                                                <div className="form-group micwrap normal mb-4" key={index}>
                                                  <label htmlFor="{customSubjectivObj.display_text}" className="control-label mb-0">{this.setCustomSubjectiveLabel(customSubjectivObj.custom_id)}</label>
                                                  <>
                                                    <Link
                                                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: customSubjectivObj.input_name }) }}
                                                      className={(this.state.speech.identifier === customSubjectivObj.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                                    <textarea onChange={this.onChangeCustomSubjectives} dataid={customSubjectivObj.id} dataindex={index} className="form-control subjective-field" ref={customSubjectivObj.input_name}
                                                      style={{ minHeight: (index === 0 && sameRxdate) ? '70px' : '' }}
                                                      name={customSubjectivObj.input_name} row="1" onInput={(e) => this.autoHeight(e)}
                                                      value={this.setCustomSubjectiveValues(index, sameRxdate, customSubjectiveData, customSubjectivObj.id, customSubjectivObj.input_name)}
                                                      onFocus={this.handleOnFocus}
                                                    />
                                                  </>
                                                </div>

                                                <div className="text-right">
                                                  <span className="d-inline mr-4">
                                                    <i data-checkSelect={index} data-type={'customParentFieldData'}
                                                      onClick={() => this.handleSelectCheck(index, "customSubjectiveData")}
                                                      className={customSubjectivObj.selected ? "btn-true-sm accept" : "btn-true-sm"}
                                                    >
                                                    </i>
                                                  </span>
                                                </div>

                                              </>
                                              :
                                              null
                                          }
                                        </>
                                      ) : null
                                  }
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12 mt-2 subjective-data">
                                  {
                                    typeof customSubjectiveParentData !== 'undefined' && this.state.DataLoaded ?
                                      Object.values(customSubjectiveParentData.parent_subjectives).map((customSubjectivObj, index) =>
                                        <>
                                          {
                                            ss_configuration.some(p => ((p?.id === customSubjectivObj.custom_id && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) ?
                                              <>
                                                <div className="form-group micwrap normal mb-4" key={index}>
                                                  <label htmlFor="{customSubjectivObj.display_text}" className="control-label mb-0">{this.setCustomParentSubjectiveLabel(customSubjectivObj.custom_id)}</label>
                                                  <>
                                                    <Link
                                                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: customSubjectivObj.input_name }) }}
                                                      className={(this.state.speech.identifier === customSubjectivObj.input_name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                                    <textarea
                                                      onChange={this.onChangeCustomParentSubjectives}
                                                      dataid={customSubjectivObj.id} dataindex={index} className="form-control subjective-field" ref={customSubjectivObj.input_name}
                                                      style={{ minHeight: (index === 0 && sameRxdate) ? '70px' : '' }}
                                                      name={customSubjectivObj.input_name} row="1" onInput={(e) => this.autoHeight(e)}
                                                      value={this.setCustomParentSubjectiveValues(index, sameRxdate, customSubjectiveParentData, customSubjectivObj.id, customSubjectivObj.input_name)}
                                                      onFocus={this.handleOnFocus}
                                                    />
                                                  </>
                                                </div>
                                                <div className="text-right">
                                                  <span className="d-inline mr-4">
                                                    <i data-checkSelect={index} data-type={'customParentFieldData'}
                                                      onClick={() => this.handleSelectCheck(index, "customSubjectiveParentData")}
                                                      className={customSubjectivObj.selected ? "btn-true-sm accept" : "btn-true-sm"}
                                                    >
                                                    </i>
                                                  </span>
                                                </div>
                                              </>
                                              :
                                              null
                                          }
                                        </>
                                      ) : null
                                  }
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12 mt-2 subjective-data">
                                  <div className="form-group micwrap normal mb-4" >
                                    <label htmlFor="{customSubjectivObj.display_text}" className="control-label mb-0">Clinical Notes</label>
                                    <>
                                      <Link
                                        onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: "clinical_notes" }) }}
                                        className={(this.state.speech.identifier === "clinical_notes" && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                      <textarea
                                        onChange={e => this.onChangeClinicalNotes(e)}
                                        className="form-control subjective-field"
                                        name="clinical_notes" row="1"
                                        onInput={(e) => this.autoHeight(e)}
                                        value={finalClinicalNotes}
                                        onFocus={this.handleOnFocus}
                                      />
                                    </>
                                  </div>
                                  <div className="text-right">
                                    <span className="d-inline mr-4">
                                      <i onClick={this.handleclinicalNotesSelectCheck}
                                        className={this.state.clinicalNotesSelected ? "btn-true-sm accept" : "btn-true-sm"}>
                                      </i>
                                    </span>
                                  </div>
                                </div>
                              </div>

                            </>
                            : null
                        }

                      <div className="mt-2">&nbsp;</div>
                      <div className={`${type != 'popup' ? "fixed-cta" : "text-center"}`}>
                        <button className="btn btn-primary px-5 text-uppercase" disabled={this.state.disableConfirmBtn || this.state.spin_loading}
                          onClick={(e) => this.handleOnConfirmSubmit(e)}> {this.state.spin_loading ? LOADER : ''} CONFIRM</button>
                          <button type="button" className="btn" onClick={(e) => this.handleSelectAllClick(e)}>
                              Select All&nbsp;&nbsp;&nbsp;<span className="select-check select-all-check">
                                <img src={this.state.isSelectAllChecked ? selecAllImg2 : selecAllImg}
                                  className="img-fluid" alt="check unselected" /></span>
                            </button>
                      </div>
                    </form>
                  </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-12 text-center mt-2">{LOADER_RED}</div>
                </div>

              )
              }
            </div>
          </div>

          {/* ################################## For Modal ####################################  */}
          <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
            title="Drug Interaction"
            visible={this.state.isModalInteractionVisible}
            onOk={() => this.handleInteractionOk()}
            onCancel={() => this.handleInteractionCancel()}
            footer={false}
            style={{ width: 1000 }}
          >
            <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMSINTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.reference_id}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${0}&careProtoColId=${this.state.careProtoColId}&medicineScreen=${'careplanform'}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ORDER</button>
            </div>
          </Modal>


          {/* For Modal Assessment  */}
          {this.state.showAssessmentModal ?
            <Modal
              title={this.state.actionText + " Assessment"}
              visible={this.state.showAssessmentModal}
              onCancel={(e) => this.handleModalPopup(e, 'showAssessmentModal')}
              footer={false}
              width="850px"
            // bodyStyle={{ padding: "10px 20px" }}
            >
              {this.state.showAssessmentModal ? <AssessmentForm formType={"copy-to-visit"} actionText={this.state.actionText} updateData={this.updateAssessmentData} autofocus={false} editData={this.state.editData} editIndex={this.state.editIndex} addedAssessmentList={this.state.addedAssessmentList} handleTemplateRadioSubmit={null} addedDrugsList={this.state.addedDrugsList} clearEdit={this.clearEdit} /> : null}


            </Modal>
            : null
          }

          {/* For Modal Medicine  */}
          {this.state.showMedicineModal ?
            <Modal
              title={this.state.actionText + " Medicine"}
              visible={this.state.showMedicineModal}
              onCancel={(e) => this.handleModalPopup(e, 'showMedicineModal')}
              footer={false}
              width="1500px"
            // bodyStyle={{ padding: "10px 20px" }}
            >
              {this.state.showMedicineModal ?
                
                <MedicineForm formType={"copy-to-visit"} medicineFlag={'copytovisit'} actionText={this.state.actionText} updateData={this.updateMedicineData} autofocus={false} editData={this.state.editData}
                editIndex={this.state.editIndex} addedAssessmentList={this.state.addedAssessmentList} addedDrugsList={this.state.addedDrugsList} constants={this.state.constants} patient={this.state.patient} clearEdit={this.clearEdit}
                polyPharmacyWarningShown={this.state.polyPharmacyWarningShown} updatepolyPharmacyWarningShown={this.polyPharmacyWarningShown}
              />

                : null}

            </Modal>
            : null
          }

          {/* For Modal Test  */}
          {this.state.showTestModal ?
            <Modal
              title={this.state.actionText + " Test"}
              visible={this.state.showTestModal}
              onCancel={(e) => this.handleModalPopup(e, 'showTestModal')}
              footer={false}
              width="850px"
            // bodyStyle={{ padding: "10px 20px" }}
            >
              {this.state.showTestModal ? <TestForm formType={"copy-to-visit"} testAdvisedDuplicateOrders={this.state.testAdvisedDuplicateOrders} actionText={this.state.actionText} updateData={this.updateTestData} autofocus={false} editData={this.state.editData} editIndex={this.state.editIndex} addedTestList={this.state.addedTestList} constants={this.state.constants} clearEdit={this.clearEdit} hospital_id={this.state.hospital_id} /> : null}


            </Modal>
            : null
          }


          {/* For Modal Instruction  */}
          {this.state.showInstructionModal ?
            <Modal
              title={this.state.actionText + " Instructions"}
              visible={this.state.showInstructionModal}
              onCancel={(e) => this.handleModalPopup(e, 'showInstructionModal')}
              footer={false}
            // bodyStyle={{ padding: "10px 20px" }}
            >
              {this.state.showInstructionModal ? <InstructionsForm actionText={this.state.actionText} updateData={this.updateInstructionsData} addedInstructionsData={this.state.instructionsData} handleModalPopup={this.handleModalPopup} /> : null}

            </Modal>
            : null
          }

          {/* For Modal Supporting Material */}
          {this.state.showSupportingModal ?
            <Modal
              destroyOnClose={true}
              title={false}
              visible={this.state.showSupportingModal}
              onCancel={(e) => this.handleModalPopup(e, 'showSupportingModal')}
              width="850px"
              footer={false}
            // bodyStyle={{ padding: "10px 20px" }}
            >
              <form className="template-form" autoComplete="off" encType="multipart/form-data">
                {this.state.showSupportingModal ?
                  <SupportingMaterial
                    actionText={this.state.actionText}
                    referenceId={this.state?.reference_id}
                    templateId={this.state.template_id}
                    formType={this.state.copyType == 'template' ? 'template' : 'all'}
                    isEdit='edit'
                    hospitalId={this.state.hospital_id}
                    setMaterialData={(data) => this.getMaterialData(data)}
                    supportingMaterial={this.state.supportingMaterial}
                    allSupportingMaterial={this.state.allSupportingMaterial}
                  /> : null}
                <div className="modal-footer pb-0">
                  <div className="col-12 text-center instructions_cta">
                    <button type="button" className="btn btn-outline-secondary px-4 mr-2" onClick={(e) => this.handleModalPopup(e, 'showSupportingModal')}>CANCEL</button>
                    <div className="btn btn-primary px-4 text-uppercase confirm-instructions"
                      onClick={this.saveSupportingMaterial}>SAVE</div>
                  </div>
                </div>
              </form>
            </Modal>
            : null
          }

          {/* For Modal Delete Confirm  */}
          <Modal
            title={false}
            visible={this.state.deleteConfirmModal}
            onCancel={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}
            footer={false}
            closable={false}
          >
            <div className="row mb-3">
              <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
            </div>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}>CANCEL</button>
              <button type="button" className="btn btn-primary px-5" onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}>DELETE</button>
            </div>

          </Modal>

          <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
            title="Drug Interaction"
            visible={this.state.isModalInteractionVisible}
            onOk={() => this.handleInteractionOk()}
            onCancel={() => this.handleInteractionCancel()}
            footer={false}
            style={{ width: 1000 }}
            zIndex={9999}
          >
            <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_CAREPLAN_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.reference_id}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${0}&careProtoColId=${this.state.careProtoColId}&medicineScreen=${'careplanform'}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ORDER</button>
            </div>
          </Modal>

          {/* For Modal Allergies Alert  */}
          <Modal
            destroyOnClose={true}
            title={false}
            visible={this.state.showDrugAllergyModal}
            onCancel={(e) => this.handleModalPopup(e, 'showDrugAllergyModal')}
            footer={false}
            closable={false}
          >
            <div className="row mb-3">
              <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.allergiesMessage }} />
            </div>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllergyOverrule(e, this.state.deleteObjId)}>Overrule</button>
              <button type="button" className="btn btn-primary px-4 text-uppercase" onClick={(e) => this.handleChangeAllergyDrug(e)}>Change the drug</button>
            </div>
          </Modal>

          {/* For Modal Poly Pharmacy Alert  */}

          <Modal
            title="Poly Pharmacy Alert"
            visible={this.state.showDrugPolyPharmacyModal}
            onCancel={(e) => this.handleModalPopup(e, 'showDrugPolyPharmacyModal')}
            footer={false}
            closable={false}
          >
            <div className="row mb-3">
              <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.polyPharmacyMessage }} />
            </div>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyPharmacyNo(e)}>No</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyPharmacyYes(e)}>Yes</button>
            </div>
          </Modal>
          {
            this.state.drugsData.length > 0 && this.state.isSelectAllChecked && !this.state.showDrugPolyPharmacyModal && !this.state.showDrugAllergyModal ?
              <MedicineAlertPopup updateSelectState={this.updateSelect} patient={this.state.patient} templateListmedicine={this.state.drugsData} /> : null
          }

          {/* For Modal Allergies Alert  */}
          <Modal
            title={false}
            visible={this.state.showDrugAllergyModal}
            onCancel={(e) => this.handleModalPopup(e, 'showDrugAllergyModal')}
            footer={false}
            closable={false}
          >
            <div className="row mb-3">
              <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.allergiesMessage }} />
            </div>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllergyOverrule(e, this.state.deleteObjId)}>Overrule</button>
              <button type="button" className="btn btn-primary px-4 text-uppercase" onClick={(e) => this.handleChangeAllergyDrug(e)}>Change the drug</button>
            </div>
          </Modal>

          {/* For Modal Poly Pharmacy Alert  */}
          {/* <Modal
            title="Poly Pharmacy Alert"
            visible={this.state.showDrugPolyPharmacyModal}
            onCancel={(e) => this.handleModalPopup(e, 'showDrugPolyPharmacyModal')}
            footer={false}
            closable={false}
          >
            <div className="row mb-3">
              <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.polyPharmacyMessage }} />
            </div>
            <div className="modal-footer justify-content-center emrfrm pb-0">
              <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyPharmacyNo(e)}>No</button>
              <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyPharmacyYes(e)}>Yes</button>
            </div>
          </Modal>
          {
            this.state.drugsData.length > 0 && this.state.isSelectAllChecked && !this.state.showDrugPolyPharmacyModal && !this.state.showDrugAllergyModal ?
              <MedicineAlertPopup updateSelectState={this.updateSelect} patient={this.state.patient} templateListmedicine={this.state.drugsData} /> : null
          } */}

        </>
        : null
    )
  }
}


export default CopyToVisitSS;
