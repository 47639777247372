import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import qs from 'qs';
import {
  Modal,
  notification,
  Radio,
  Checkbox
} from 'antd';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';

import LS_SERVICE from '../../utils/localStorage';
import {
  PROFILE,
  SOAP_CONFIGURATION_LIST,
  DOSE_RULE,
  SOAP_CONFIGURATION_STORE,
  LOADER_RED,
  LOADER,
  USERTYPES,
  SOAP_TYPE_FETCH,
  SOAP_TYPE_STORE,
  SINGLE_SCREEN_CONFIGURATION_FETCH,
  SINGLE_SCREEN_CONFIGURATION_STORE,
  REFERRAL_CONFIGURATION_STORE,
  GET_REFERRAL_CONFIGURATION,
  COPY_PREV_CONFIGURATION_STORE,
  GET_COPYPREV_CONFIGURATION,
} from '../../utils/constant';

const DragHandleTop = sortableHandle(() => <div class="btn_sequence"></div>);
const DragHandleChild = sortableHandle(() => <div class="btn_sequence"></div>);

const SortableContainerTop = sortableContainer(({children}) => {
  return <td colSpan='4' style={{padding: '0', borderTop: '0', borderRight: '0', borderLeft: '0'}}>{children}</td>;
});
const SortableContainerChild = sortableContainer(({children}) => {
  return <td colSpan='4' style={{padding: '0', borderTop: '0', borderBottom: '0'}}>{children}</td>;
});

const SortableItemTop = sortableElement(({ iterable, parent_index, handleSSScreenCheck, handleSSCustomFieldTextbox, onSortEndChild, handleSSCustomFieldRadio }) => {
  var custom_field_value = iterable.custom_field_value;
  if((iterable.custom_field_type == 'radio' || iterable.custom_field_type == 'select' || iterable.custom_field_type == 'checkbox') && iterable.custom_field_value != null){
    custom_field_value = iterable.custom_field_value.split(",")
  }
  return (
    <table className="table emr_config_table" style={{marginBottom: '0px'}} width="100%" id="diagnosis-list">
      {
        iterable.has_child == 'N'
          ?
          <tr className='highlighted_row'>
            <td width="30%" style={{borderBottom: '0', borderTop: '0'}} className="highlight font">
            <div className='d-flex align-items-center'>
              <span className={iterable.custom_field_type == 'radio' ? 'sub_column w-25':'sub_column w-75'}>{iterable.display_title}</span>
              {
                iterable.custom_field_allowed == 'Y'
                  ?
                  <>
                    {
                      iterable.custom_field_type == 'radio' && iterable.custom_field_value != null
                        ?
                        <span className='ml-4'>
                          <Radio.Group onChange={handleSSCustomFieldRadio} disabled={iterable?.custom?.source == 'portal' ? true : false} name={'parent_'+iterable.name} data={iterable} value={iterable?.custom?.value}>
                            {custom_field_value?.map((custom_field, i) => (
                              <Radio value={custom_field} module_type="parent" id={iterable.id} title={custom_field}>{custom_field}</Radio>
                            ))}
                          </Radio.Group>
                        </span>
                        :
                        iterable.custom_field_type == 'textbox'
                          ?
                          <input type="text" 
                           onChange={(e) => handleSSCustomFieldTextbox(e, iterable, 'parent')} 
                           module_type="parent" data={iterable} value={iterable?.custom?.value} disabled={iterable?.custom?.source == 'portal' ? true : false} name={'subitem_' + iterable?.name} className="form-control" placeholder="Field Name" maxLength="30" />
                          :
                          null
                    }
                  </>
                  :
                  null
              }
              </div>
            </td>
            <td width="30%" style={{borderBottom: '0', borderTop: '0'}} className="highlight">
              {
                iterable.opd_add_to_screen == 'Y'
                  ?
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" disabled={iterable.configuration.opd_screen.source == 'portal' && iterable.configuration.opd_screen.selected == 'Y' ? true : false} data-module_type="parent" data-consult_type='opd' data-config_type='screen' data-id={iterable.id} checked={iterable.configuration.opd_screen.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'parent_opd_screen_'+iterable.name} />
                      <label className="custom-control-label" htmlFor={'parent_opd_screen_'+iterable.name}>Add to Screen</label>
                    </div>
                  </div>
                  :
                  null
              }
              {
                iterable.opd_add_to_print == 'Y'
                  ?
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" disabled={iterable.configuration.opd_print.source == 'portal' && iterable.configuration.opd_print.selected == 'Y' ? true : false} data-module_type="parent" data-consult_type='opd' data-config_type='print' data-id={iterable.id} checked={iterable.configuration.opd_print.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'parent_opd_print_'+iterable.name} />
                      <label className="custom-control-label" htmlFor={'parent_opd_print_'+iterable.name}>Add to Print</label>
                    </div>
                  </div>
                  :
                  null
              }
            </td>
            <td width="30%" style={{borderBottom: '0', borderTop: '0'}} className="highlight">
              {
                iterable.vc_add_to_screen == 'Y'
                  ?
                  <div className="float-left">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" disabled={iterable.configuration.vc_screen.source == 'portal' && iterable.configuration.vc_screen.selected == 'Y' ? true : false} data-module_type="parent" data-consult_type='vc' data-config_type='screen' data-id={iterable.id} checked={iterable.configuration.vc_screen.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'parent_vc_screen_'+iterable.name} />
                      <label className="custom-control-label" htmlFor={'parent_vc_screen_'+iterable.name}>Add to Screen</label>
                    </div>
                  </div>
                  :
                  null
              }
              {
                iterable.vc_add_to_print == 'Y'
                  ?
                  <div className="float-left pl-3">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" disabled={iterable.configuration.vc_print.source == 'portal' && iterable.configuration.vc_print.selected == 'Y' ? true : false} data-module_type="parent" data-consult_type='vc' data-config_type='print' data-id={iterable.id} checked={iterable.configuration.vc_print.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'parent_vc_print_'+iterable.name} />
                      <label className="custom-control-label" htmlFor={'parent_vc_print_'+iterable.name}>Add to Print</label>
                    </div>
                  </div>
                  :
                  null
              }
            </td>
            <td width="10%" style={{borderBottom: '0', borderTop: '0'}} className='highlight text-center'>
              {
                iterable.sequence_change_allowed == 'Y'
                  ?
                  <DragHandleTop/>
                  :
                  null
              }
            </td>
          </tr>
          :
          <>
            <tr>
              <td width="90%" style={{borderBottom: '0', borderTop: '0'}} colSpan={3} className="highlight_col">{iterable.display_title}</td>
              <td width="10%" style={{borderBottom: '0', borderTop: '0'}} className='text-center highlight_col'>
                {
                  iterable.sequence_change_allowed == 'Y'
                    ?
                    <DragHandleTop/>
                    :
                    null
                }
              </td>
            </tr>
            <tr>
              <SortableContainerChild helperClass='sortableHelperSSConfig' onSortEnd={onSortEndChild} lockAxis={'y'} lockToContainerEdges={false} disableAutoscroll={true} useDragHandle>
                {
                  iterable.sub_items?.map((iterable_subitem, j) => {
                    return <SortableItemChild
                      key={j}
                      iterable_subitem={iterable_subitem}
                      index={j}
                      index1={j}
                      parent_index={parent_index}
                      iterable={iterable}
                      handleSSScreenCheck={handleSSScreenCheck}
                      handleSSCustomFieldTextbox={handleSSCustomFieldTextbox}
                      collection={parent_index}
                    />
                  })
                }
              </SortableContainerChild>
            </tr>
          </>
      }
    </table>
  )
});
const SortableItemChild = sortableElement(({ iterable_subitem, index1, iterable, parent_index, handleSSScreenCheck, handleSSCustomFieldTextbox }) => {
  var custom_field_value_subitem = iterable_subitem.custom_field_value;
  if((iterable_subitem.custom_field_type == 'radio' || iterable_subitem.custom_field_type == 'select' || iterable_subitem.custom_field_type == 'checkbox') && iterable_subitem.custom_field_value != null){
    custom_field_value_subitem = iterable_subitem.custom_field_value.split(",")
  }
  return (
    <table className="table emr_config_table" style={{marginBottom: '0px'}} width="100%" id="diagnosis-list">
      <tr>
        <td width="30%" style={{borderBottom: '0', borderTop: '0'}}>
          <div className='d-flex align-items-center'>
            <span className='sub_column w-75'>{iterable_subitem.display_title}</span>
            {
              iterable_subitem.custom_field_allowed == 'Y'
                ?
                <>
                  {
                    iterable_subitem.custom_field_type == 'textbox'
                      ?
                      <input type="text" onChange={(e) => handleSSCustomFieldTextbox(e,iterable_subitem,'child')} module_type="child" data={iterable_subitem} value={iterable_subitem?.custom?.value} disabled={iterable_subitem?.custom?.source == 'portal' ? true : false} name={'subitem_'+iterable_subitem?.name} className="form-control" placeholder="Field Name" maxLength="30" />
                      :
                      null
                  }
                </>
                :
                null
            }
          </div>
        </td>
        <td width="30%" style={{borderBottom: '0', borderTop: '0'}}>
          {
            iterable_subitem.opd_add_to_screen == 'Y'
              ?
              <div className="float-left">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" disabled={iterable_subitem.configuration.opd_screen.source == 'portal' && iterable_subitem.configuration.opd_screen.selected == 'Y' ? true : false} data-module_type="subitem" data-consult_type='opd' data-config_type='screen' data-parent={iterable_subitem.parent_id} data-id={iterable_subitem.id} checked={iterable_subitem.configuration.opd_screen.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'subitem_opd_screen_'+iterable_subitem.name} />
                  <label className="custom-control-label" htmlFor={'subitem_opd_screen_'+iterable_subitem.name}>Add to Screen</label>
                </div>
              </div>
              :
              null
          }
          {
            iterable_subitem.opd_add_to_print == 'Y'
              ?
              <div className="float-left pl-3">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" disabled={iterable_subitem.configuration.opd_print.source == 'portal' && iterable_subitem.configuration.opd_print.selected == 'Y' ? true : false} data-module_type="subitem" data-consult_type='opd' data-config_type='print' data-parent={iterable_subitem.parent_id} data-id={iterable_subitem.id} checked={iterable_subitem.configuration.opd_print.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'subitem_opd_print_'+iterable_subitem.name} />
                  <label className="custom-control-label" htmlFor={'subitem_opd_print_'+iterable_subitem.name}>Add to Print</label>
                </div>
              </div>
              :
              null
          }
        </td>
        <td width="30%" style={{borderBottom: '0', borderTop: '0'}}>
          {
            iterable_subitem.vc_add_to_screen == 'Y'
              ?
              <div className="float-left">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" disabled={iterable_subitem.configuration.vc_screen.source == 'portal' && iterable_subitem.configuration.vc_screen.selected == 'Y' ? true : false} data-module_type="subitem" data-consult_type='vc' data-config_type='screen' data-parent={iterable_subitem.parent_id} data-id={iterable_subitem.id} checked={iterable_subitem.configuration.vc_screen.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'subitem_vc_screen_'+iterable_subitem.name} />
                  <label className="custom-control-label" htmlFor={'subitem_vc_screen_'+iterable_subitem.name}>Add to Screen</label>
                </div>
              </div>
              :
              null
          }
          {
            iterable_subitem.vc_add_to_print == 'Y'
              ?
              <div className="float-left pl-3">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" disabled={iterable_subitem.configuration.vc_print.source == 'portal' && iterable_subitem.configuration.vc_print.selected == 'Y' ? true : false} data-module_type="subitem" data-consult_type='vc' data-config_type='print' data-parent={iterable_subitem.parent_id} data-id={iterable_subitem.id} checked={iterable_subitem.configuration.vc_print.selected == 'Y' ? true : false} onClick={handleSSScreenCheck} className="custom-control-input" id={'subitem_vc_print_'+iterable_subitem.name} />
                  <label className="custom-control-label" htmlFor={'subitem_vc_print_'+iterable_subitem.name}>Add to Print</label>
                </div>
              </div>
              :
              null
          }
        </td>
        <td width="10%" style={{borderBottom: '0', borderTop: '0'}} className='text-center'>
          {
            iterable_subitem.sequence_change_allowed == 'Y'
              ?
              <DragHandleChild/>
              :
              null
          }
        </td>
      </tr>
    </table>
  )
  // return (
  //   <table className="table table_border table_key_findings_sequence" style={{marginBottom: '0px'}}>
  //     <tr>
  //       <td style={{width: '61px', fontWeight: '400', borderBottom: '0', borderTop: '0', borderLeft: '0'}}> {value.display_order != 0 ? value.display_order : ''}</td>
  //       <td style={{fontWeight: '400', borderBottom: '0', borderTop: '0'}}> {value.display_name} {value.display_value ? '(' + value.display_value + ')' : ''}</td>
  //       <td style={{width: '88px', borderBottom: '0', borderTop: '0'}}>
  //         <DragHandleChild />
  //       </td>
  //       <td style={{width: '125px', borderBottom: '0', borderTop: '0', borderRight: '0'}}>
  //         <div className="custom-control custom-checkbox custom-control-inline">
  //           <input type="checkbox" className="custom-control-input" id={`select_${parent_index}_${index1}`}
  //             name={`select_${parent_index}_${index1}`}
  //             /* disabled={this.renderDisabled(dt)} */
  //             /* value={dt.id} */
  //             checked={value.display_order != 0}
  //             onChange={e => checkBoxChange(e, value, parent_value)} />
  //           <label className="custom-control-label" htmlFor={`select_${parent_index}_${index1}`}></label>
  //         </div>
  //       </td>
  //     </tr>
  //   </table>
  // )
});

class SoapComponent extends Component {
  state = {
    profileData: [],
    soapListData: [],
    soapStoreData: [],
    singleConfInfo: [],
    soapDataLoaded: false,
    configuredData: {},
    addScreenSubj: false,
    addScreenObj: false,
    printWithoutScreen: false,
    isScreenMsgModalReq: false,
    screenType: 'single',
    screenTypeSource: 'doctor',
    doseRule: 3,
    isPaediatrics: 0,
    isObsGynaeDoctor: 0,
    isReferral: false,
    isCopyFromPrev: false,
    printGenericWithBrand: false,
    printGrowthChart: false,
    printVaccinationChart: false,
    isCopyFromPrev: false,
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  componentDidMount() {
    const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;
    Axios.post(PROFILE({ is_doctor }), qs.stringify())
      .then(response => {
        LS_SERVICE.set('isPaediatrician', response.data.data.paediatric || 0)
      }).catch(error => console.log(error))
    this.getReferralConfiguration();
    this.getCopyPrevConfiguration();
    this.loadData();
  }

  loadData = async () => {
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    const speciality_id = LS_SERVICE.get('speciality_id');
    const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;
    const specialityPromise = await Axios.post(PROFILE({ is_doctor }));
    this.setState({
      profileData: specialityPromise?.data?.data
    });
    const soapType = await Axios.post(SOAP_TYPE_FETCH,{
      staff_id: staff_id && staff_id != null ? parseInt(staff_id) : null,
      speciality_id: speciality_id && speciality_id != null ? parseInt(speciality_id) : null,
      hospital_id: hospital_id && hospital_id != null ? parseInt(hospital_id) : null,
      source: 'doctor',
      setting_for: 'doctor',
    });

    if(soapType.data && soapType.data.selected_soap && soapType.data.selected_soap.type){
      this.setState({
        screenType: soapType.data.selected_soap.type,
        screenTypeSource: soapType.data.selected_soap.source
      })
    }
    else{
      this.setState({
        screenType: 'single',
        screenTypeSource: 'doctor'
      })
    }

    const soapListPromise = Axios.post(SOAP_CONFIGURATION_LIST, qs.stringify({ staff_id, hospital_id, speciality_id }));
    const soapDoseRule = Axios.post(DOSE_RULE, qs.stringify({ staff_id, hospital_id, speciality_id }));
    const singleScreenConfPromise = Axios.post(SINGLE_SCREEN_CONFIGURATION_FETCH,{
      staff_id: staff_id && staff_id != null ? parseInt(staff_id) : null,
      speciality_id: speciality_id && speciality_id != null ? parseInt(speciality_id) : null,
      hospital_id: hospital_id && hospital_id != null ? parseInt(hospital_id) : null,
      source: 'doctor',
      setting_for: 'doctor',
    });

    Axios.all([soapListPromise, soapDoseRule, singleScreenConfPromise])
      .then(Axios.spread((...responses) => {
        const responseZero = responses[0];
        const responseOne = responses[1];
        const responseTwo = responses[2];

        // FOR SOAP_TYPE = SOAP : START
        const confInfo = Object.values(responseZero.data);
        const ruleInfo = Object.values(responseOne.data);
        if (confInfo[0] != undefined && confInfo[0][0] != undefined) {
          if (confInfo[0][0]['display'] === 'Y') {
            this.setState({
              addScreenSubj: true
            });
          }
        }
        if (confInfo[1] != undefined && confInfo[1][0] != undefined) {
          if (confInfo[1][0]['display'] === 'Y') {
            this.setState({
              addScreenObj: true
            });
          }
        }

        this.setState({
          doseRule: ruleInfo[0] ? ruleInfo[0].rule : 3
        });

        this.setState({
          soapListData: confInfo,
          soapDataLoaded: true,
        })
        const is_paediatrician = LS_SERVICE.get('isPaediatrician');
        if (is_paediatrician == 1) {
          this.setState({ isPaediatrics: 1 });
        }
        if (LS_SERVICE.get('isObsGynaeDoctor') == 1) {
          this.setState({ isObsGynaeDoctor: 1 });
        }
        // FOR SOAP_TYPE = SOAP : END

        // FOR SOAP_TYPE = SINGLE : START
        const singleConfInfo = responseTwo.data?.ss_configuration ? Object.values(responseTwo.data?.ss_configuration) : {};
        this.setState({
          singleConfInfo: singleConfInfo
        });
        // FOR SOAP_TYPE = SINGLE : END
      }))


  }

  onRadioScreenChange = (e, props) => {
    this.setState({ screenType: e.target.value, })
  };

  onSortEndChild = async ({oldIndex, newIndex, collection}) => {
    var singleConfInfo = this.state.singleConfInfo;
    if(singleConfInfo[collection]?.id == 3 && (newIndex == 0 || newIndex == 1)){
      //NOTHING
    }
    else{
      var item = singleConfInfo?.[collection]?.sub_items?.[oldIndex]
      singleConfInfo?.[collection]?.sub_items.splice(oldIndex, 1);
      singleConfInfo?.[collection]?.sub_items.splice(newIndex, 0, item);
      this.setState({singleConfInfo: singleConfInfo})
    }
  };

  onSortEndTop = async ({oldIndex, newIndex}) => {
    var singleConfInfo = this.state.singleConfInfo;
    if(newIndex > 0){
      var item = singleConfInfo[oldIndex];
      singleConfInfo.splice(oldIndex, 1);
      singleConfInfo.splice(newIndex, 0, item);
      this.setState({singleConfInfo: singleConfInfo})
    }
  };

  handleSSCustomFieldRadio = (e, props) => {
    var singleConfInfo = this.state.singleConfInfo;
    if(e.target.module_type == "parent"){
      if(!singleConfInfo.find(o => o.id == e.target.id).custom){
        singleConfInfo.find(o => o.id == e.target.id).custom.value = {
          value: e.target.value,
          source: 'doctor',
          setting_for: 'doctor',
        }
      }
      else{
        singleConfInfo.find(o => o.id == e.target.id).custom.value = e.target.value;
      }
    }
    else{
      var index = singleConfInfo.findIndex(o => o.id == e.target.parent);
      if(!singleConfInfo[index].sub_items.find(o => o.id == e.target.id).custom){
        singleConfInfo[index].sub_items.find(o => o.id == e.target.id).custom.value = {
          value: e.target.value,
          source: 'doctor',
          setting_for: 'doctor',
        }
      }
      else{
        singleConfInfo[index].sub_items.find(o => o.id == e.target.id).custom.value = e.target.value;
      }
    }
    this.setState({
      singleConfInfo: singleConfInfo
    })
  };

  handleSSCustomFieldTextbox = (e,data,module_type) => {
    var singleConfInfo = this.state.singleConfInfo;
    if(module_type == "parent"){
      if(!singleConfInfo.find(o => o.id == data.id).custom){
        singleConfInfo.find(o => o.id == data.id).custom = {
          value: e.target.value,
          source: 'doctor',
          setting_for: 'doctor',
        }
      }
      else{
        singleConfInfo.find(o => o.id == data.id).custom.value = e.target.value;
      }
    }
    else{
      var index = singleConfInfo.findIndex(o => o.id == data.parent_id);
      if(!singleConfInfo[index].sub_items.find(o => o.id == data.id).custom){
        singleConfInfo[index].sub_items.find(o => o.id == data.id).custom = {
          value: e.target.value,
          source: 'doctor',
          setting_for: 'doctor',
        }
      }
      else{
        singleConfInfo[index].sub_items.find(o => o.id == data.id).custom.value = e.target.value;
      }
    }
    this.setState({
      singleConfInfo: singleConfInfo
    })
  };

  handleSSScreenCheck = e => {
    var singleConfInfo = this.state.singleConfInfo;
    if(e.target.dataset.module_type === 'parent'){
      if(e.target.dataset.config_type == 'print' && e.target.checked == true){
        singleConfInfo.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_screen'].selected = 'Y';
        singleConfInfo.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_print'].selected = 'Y';
      }
      else if(e.target.dataset.config_type == 'screen' && e.target.checked != true){
        singleConfInfo.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_screen'].selected = 'N';
        singleConfInfo.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_print'].selected = 'N';
      }
      else{
        singleConfInfo.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_'+e.target.dataset.config_type].selected = e.target.checked == true ? 'Y' : 'N';
      }
    }
    else{
      var index = singleConfInfo.findIndex(o => o.id == e.target.dataset.parent);
      if(e.target.dataset.config_type == 'print' && e.target.checked == true){
        singleConfInfo[index].sub_items.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_screen'].selected = 'Y';
        singleConfInfo[index].sub_items.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_print'].selected = 'Y';
      }
      else if(e.target.dataset.config_type == 'screen' && e.target.checked != true){
        singleConfInfo[index].sub_items.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_screen'].selected = 'N';
        singleConfInfo[index].sub_items.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_print'].selected = 'N';
      }
      else{
        singleConfInfo[index].sub_items.find(o => o.id == e.target.dataset.id).configuration[e.target.dataset.consult_type+'_'+e.target.dataset.config_type].selected = e.target.checked == true ? 'Y' : 'N';
      }
    }
    this.setState({
      singleConfInfo: singleConfInfo
    })
  }

  handleScreenCheck = (e) => {
    const { soapListData } = this.state;

    const target = e.target.dataset.id;

    const data = soapListData

    data.forEach(iter => iter.forEach((item) => {
      if (item.id == target) {
        // item.print = item.print == 'Y' ? 'N' : "Y"
        item.display = item.display == 'Y' ? 'N' : "Y"
        if (item.display === 'N') {
          item.print = 'N';
        }
        if (item.parent_module == "paediatric" || item.parent_module == "obsgynae") {
          if (item.display == 'Y') {
            data[1].forEach(item => {
              //item.print = 'N';
              if (LS_SERVICE.get('isObsGynaeDoctor') == 1 && item.parent_module == "obsgynae") {
                /* item.display = isChecked; */
              }
              else if (LS_SERVICE.get('paediatric_doctor') == 1 && item.parent_module == "paediatric") {
                /* item.display = isChecked; */
              }
              else if (item.parent_module == "objective") {
                item.display = 'Y';
              }
              else {
                item.display = 'N'
              }
            })
            this.setState(prev => ({
              addScreenObj: true
            }))
          }
        }
      }
    }))

    this.setState({
      soapListData: data
    })


  }

  handlePrintCheck = (e) => {

    const { soapListData, addScreenObj, addScreenSubj } = this.state;

    const target = e.target.dataset.id;

    const data = soapListData

    if (e.target.dataset.parent == 'subjective' && !addScreenSubj || e.target.dataset.parent == 'objective' && !addScreenObj) {
      this.setState({
        isScreenMsgModalReq: true
      })
      return;
    }

    data.forEach(iter => iter.forEach((item) => {
      if (item.id == target) {
        item.print = item.print == 'Y' ? 'N' : "Y"
        if (item.display === 'N') {
          item.display = "Y";
        }
        if (item.parent_module == "paediatric" || item.parent_module == "obsgynae") {
          if (item.print == 'Y') {
            data[1].forEach(item => {
              //item.print = 'N';
              if (LS_SERVICE.get('isObsGynaeDoctor') == 1 && item.parent_module == "obsgynae") {
                /* item.display = isChecked; */
              }
              else if (LS_SERVICE.get('paediatric_doctor') == 1 && item.parent_module == "paediatric") {
                /* item.display = isChecked; */
              }
              else if (item.parent_module == "objective") {
                item.display = 'Y';
              }
              else {
                item.display = 'N'
              }
            })
            this.setState(prev => ({
              addScreenObj: true
            }))
          }
        }
      }
    }))
    this.setState({
      soapListData: data
    })

  }

  handleSoapSave = () => {
    this.handleSoapTypeSave();
    const data = [...this.state.soapListData[0], ...this.state.soapListData[1], ...this.state.soapListData[2], ...this.state.soapListData[3]];
    this.setState({
      isFormSaving: true
    })

    const configurationData = data.map((item) => (
      {
        print: item.print,
        display: item.display,
        emr_module_id: item.id,
        status: "active",
        print_ordering: item.print_ordering,
        display_ordering: item.display_ordering

      }
    ))

    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    const speciality_id = LS_SERVICE.get('speciality_id');

    const PARAMS = {
      configurationData,
      staff_id,
      hospital_id,
      speciality_id,
      configuration_level: "doctor",
      doseRule: this.state.doseRule,
    };

    Axios.post(SOAP_CONFIGURATION_STORE, qs.stringify(PARAMS))
      .then(res => {
        notification.success({
          message: res.data.message,
          placement: 'topRight'
        })
        this.setState({
          isFormSaving: false
        })
      })
      .catch(error => {
        // if (error && error.response && error.response.status === 401) {
        //     LS_SERVICE.clear();
        //     this.props.history.push("/");
        // }
      })
  }

  handleSingleSave = () => {
    this.handleSoapTypeSave();

    this.handleSoapSingleConfiguration();
  }

  async getReferralConfiguration(){
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    let response = await Axios.get(GET_REFERRAL_CONFIGURATION({staff_id, hospital_id}));
    if(response && response.status == 200){
      let data = response.data.data;
      this.setState({isReferral:data.referral_print == "Y" ? true : false, printGenericWithBrand:data.print_generic_with_brand == "Y" ? true : false, printGrowthChart:data.print_growth_chart == "Y" ? true : false, printVaccinationChart:data.print_vaccination_chart == "Y" ? true : false })
    }
  }

  saveReferralConfig(){
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    let BODY = {
      hospital_id: parseInt(hospital_id),
      staff_id: parseInt(staff_id),
      referral_print: this.state.isReferral ? "Y" : "N",
      print_generic_with_brand: this.state.printGenericWithBrand ? "Y" : "N",
      print_growth_chart: this.state.printGrowthChart ? "Y" : "N",
      print_vaccination_chart: this.state.printVaccinationChart ? "Y" : "N",
    }
    Axios.post(REFERRAL_CONFIGURATION_STORE, BODY)
  }

  async getCopyPrevConfiguration(){
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    let response = await Axios.get(GET_COPYPREV_CONFIGURATION({staff_id, hospital_id}));
    if(response && response.status == 200){
      let data = response.data.data;
      this.setState({isCopyFromPrev:data.auto_populate_presc == "Y" ? true : false })
    }
  }

  saveCopyPrevConfig(){
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    let BODY = {
      hospital_id: parseInt(hospital_id),
      staff_id: parseInt(staff_id),
      auto_populate_presc: this.state.isCopyFromPrev ? "Y" : "N"
    }
    Axios.post(COPY_PREV_CONFIGURATION_STORE, BODY)
  }

  handleSoapSingleConfiguration = () => {
    this.saveReferralConfig();
    this.saveCopyPrevConfig();
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');

    let soap_configuration_data = {
      configuration_data: this.state.singleConfInfo,
      hospital_id: parseInt(hospital_id),
      staff_id: parseInt(staff_id),
      source: 'doctor',
      setting_for: 'doctor',
    }

    if(LS_SERVICE.get('isPaediatrician') == 1)
    {
      soap_configuration_data.doseRule =  this.state.doseRule;
      soap_configuration_data.speciality_id = LS_SERVICE.get('speciality_id');
    }

    Axios.post(SINGLE_SCREEN_CONFIGURATION_STORE,soap_configuration_data).then(res => {
      notification.success({
        message: res.data.message,
        placement: 'topRight'
      })
    })
      .catch(error => {
        notification.error({
          message: 'Something went wrong! Unable to save the configuration',
          placement: 'topRight'
        })
      })
  }

  handleSoapTypeSave = () => {
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');

    Axios.post(SOAP_TYPE_STORE,{
      type: this.state.screenType,
      hospital_id: parseInt(hospital_id),
      staff_id: parseInt(staff_id),
      source: 'doctor',
      setting_for: 'doctor',
    });
  }

  handleScreenFullModule = (e) => {

    const { soapListData } = this.state
    const data = soapListData;
    const isChecked = e.target.checked ? 'Y' : 'N';

    if (e.target.name == 'subjective') {
      data[0].forEach(item => {
        item.print = 'N';
        item.display = isChecked;
      })
      this.setState(prev => ({
        addScreenSubj: !prev.addScreenSubj
      }))
    } else {
      data[1].forEach(item => {
        item.print = 'N';
        if (LS_SERVICE.get('isObsGynaeDoctor') == 1 && item.parent_module == "obsgynae") {
          item.display = isChecked;
        }
        else if (LS_SERVICE.get('paediatric_doctor') == 1 && item.parent_module == "paediatric") {
          item.display = isChecked;
        }
        else if (item.parent_module == "objective") {
          item.display = isChecked;
        }
        else {
          item.display = 'N'
        }
      })
      this.setState(prev => ({
        addScreenObj: !prev.addScreenObj
      }))
    }

    this.setState({
      soapListData: data
    });
  }

  isItemChecked(label) {
    if (label === 'subjective') {
      return this.state.addScreenSubj;
    }
    if (label === 'objective') {
      return this.state.addScreenObj;
    }
    return false;
  }

  handleDoseChange = (e) => {

    if (e.target.value != '' || e.target.value != null) {
      this.setState({ doseRule: e.target.value });
    }
  }

  handleReferral = (e) => {
    this.setState({isReferral: !this.state.isReferral})
  }

  handleGeneric = (e) => {
    this.setState({printGenericWithBrand: !this.state.printGenericWithBrand})
  }

  handleGrwothChartPrint = (e) => {
    this.setState({printGrowthChart: !this.state.printGrowthChart})
  }

  handleVaccination = (e) => {
    this.setState({printVaccinationChart: !this.state.printVaccinationChart})
  }
  
  handleCopyFromPrevious = (e) => {
    this.setState({isCopyFromPrev: !this.state.isCopyFromPrev})
  }

  handleCopyFromPrevious = (e) => {
    this.setState({isCopyFromPrev: !this.state.isCopyFromPrev})
  }

  render() {
    const { soapListData, soapDataLoaded, doseRule, isPaediatrics, isObsGynaeDoctor } = this.state;
    const soapDataFirstRow = [soapListData[0], soapListData[1]] // 0 - subjective, 1 - objective 
    const soapDataSecondRow = [soapListData[2], soapListData[3]] // 2 - assessment, 3 - plan
    var config = LS_SERVICE.get('emr_server_config');
    var app_env = config?.app_env;
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    return (
      <>
        {this.state.soapDataLoaded ?
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 mb-2 px-3">
                  <h1 className="page-title">Speciality Level Configuration</h1>
                </div>
                <form className="col-12 emrfrm px-3" id="dashboard" autoComplete="off">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group hasdata">
                        <select className="form-control" name="speciality" id="speciality" disabled="disabled">
                          {this.state.profileData.specialities.map((label, idx) => <option value={label} key={idx}>{label}</option>)}
                        </select>
                        <label htmlFor="speciality" className="required">Speciality</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group hasdata">
                        <select className="form-control" name="doctors" id="doctors" disabled="disabled">
                          {
                            ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                          ?
                            <option label value={LS_SERVICE.get('staff_id')} selected>{LS_SERVICE.get('staff_name')}</option>
                          :
                            <option label value={this.state.profileData.id} selected>{this.state.profileData.name}</option>
                          }
                        </select>
                        <label htmlFor="location" className="required">Doctor</label>
                      </div>
                    </div>
                  </div>

                  <Radio.Group disabled={this.state.screenTypeSource == 'portal' ? true : false} onChange={this.onRadioScreenChange} value={this.state.screenType}>
                    <Radio value={"single"} name="single" title={'Single'}>Single Screen</Radio>
                    <Radio value={"soap"} name="soap" title={'Soap'}>SOAP Screen</Radio>
                  </Radio.Group>

                  <br /><br />


                  <div 
                  className="container-fluid"
                  >
                    <table 
                    className="table emr_config_table" 
                    id="diagnosis-list"
                    >
                      <thead>
                        <tr>
                          <td width="100%">Other Configuration</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                          <Checkbox
                            onClick={(e) => this.handleReferral(e)}
                            value={this.state.isReferral}
                            checked={this.state.isReferral}
                            name="referral">Add referral source to print
                          </Checkbox>
                          <Checkbox
                            onClick={(e) => this.handleGeneric(e)}
                            value={this.state.printGenericWithBrand}
                            checked={this.state.printGenericWithBrand}
                            name="generic_drug">Print prescription with brand and generic
                          </Checkbox>
                          <Checkbox
                            onClick={(e) => this.handleGrwothChartPrint(e)}
                            value={this.state.printGrowthChart}
                            checked={this.state.printGrowthChart}
                            name="generic_drug">Print child developmental and growth
                          </Checkbox>
                          <Checkbox
                            onClick={(e) => this.handleVaccination(e)}
                            value={this.state.printVaccinationChart}
                            checked={this.state.printVaccinationChart}
                            name="generic_drug">Print vaccination chart
                          </Checkbox>
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>

                  </div>

                  <div 
                  className="container-fluid"
                  >
                    <table 
                    className="table emr_config_table" 
                    id="diagnosis-list"
                    >
                      <thead>
                        <tr>
                          <td width="100%">Copy From Previous Configuration</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                          <Checkbox
                            onClick={(e) => this.handleCopyFromPrevious(e)}
                            value={this.state.isCopyFromPrev}
                            checked={this.state.isCopyFromPrev}
                            name="referral">Auto populate previous prescription
                          </Checkbox>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                  {
                    LS_SERVICE.get('isPaediatrician') == 1 ?
                    <> 
                      <div 
                      className="container-fluid"
                      >
                        <table 
                        className="table emr_config_table" 
                        id="diagnosis-list"
                        >
                          <thead>
                            <tr>
                              <td width="100%">Pediatric Dose Calculator</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                              <div className="row">
                                <div className="col-6">
                                  <div className="custom-control custom-radio mt-2">
                                    <input type="radio" id="frieds_rule" name="dose" value="1" defaultChecked={doseRule == 1} className="custom-control-input" onChange={this.handleDoseChange} />
                                    <label className="custom-control-label font-weight-semibold" htmlFor="frieds_rule">Fried’s Rule</label>
                                  </div>
                                  <div className="pl-4"><small>Dose = [Age (month)/150] * Adult Dose</small></div>
                                </div>
                                <div className="col-6">
                                  <div className="custom-control custom-radio mt-2">
                                    <input type="radio" id="youngs_rule" name="dose" value="2" defaultChecked={doseRule == 2} className="custom-control-input" onChange={this.handleDoseChange} />
                                    <label className="custom-control-label font-weight-semibold" htmlFor="youngs_rule">Young’s Rule</label>
                                  </div>
                                  <div className="pl-4"><small>Dose =  (Age in years/ Age in years +12) * Adult Dose</small></div>
                                </div>
                                <div className="col-6">
                                  <div className="custom-control custom-radio mt-2">
                                    <input type="radio" id="clarkes_rule" name="dose" value="3" defaultChecked={doseRule == 3} className="custom-control-input" onChange={this.handleDoseChange} />
                                    <label className="custom-control-label font-weight-semibold" htmlFor="clarkes_rule">Clarke's Rule</label>
                                  </div>
                                  <div className="pl-4"><small>Dose = (Body Weight in Kgs/ 70) * Adult Dose</small></div>
                                </div>
                                <div className="col-6">
                                  <div className="custom-control custom-radio mt-2">
                                    <input type="radio" id="dose_bsa" name="dose" value="4" defaultChecked={doseRule == 4} className="custom-control-input" onChange={this.handleDoseChange} />
                                    <label className="custom-control-label font-weight-semibold" htmlFor="dose_bsa">Dose based on BSA</label>
                                  </div>
                                  <div className="pl-4"><small>(Child's BSA/ Average BSA for adult) * Adult Dose</small></div>
                                </div>
                              </div>
                              </td>
                              
                            </tr>
                          </tbody>
                        </table>

                      </div>
                    </>
                    :
                    null
                  }

                  {
                    this.state.screenType == 'soap'
                      ?
                      <>
                        {/* SOAP VIEW CONFIGURATIONS STARTS HERE */}
                        <div id="module_list">
                          <div className="row soap_sortable" id="level1">
                            {soapDataFirstRow?.map((iterable, i) => {
                              return (
                                <Fragment key={i}>
                                  <div className={`col-auto pr-0 ${i === 1 ? "pl-0" : ""}`}>
                                    <div className="soap_card_no p-3">{iterable[0].order}</div>
                                  </div>
                                  <div className={`col pl-0 ${i === 0 ? "pr-0" : ""}`}>
                                    <div className="p-4 widget-panel" id="widget-1">
                                      <div className="card rounded-0 widget-card" id="widget-card-1">
                                        <div className="pl-3 py-2 mr-3">
                                          <strong className="text-capitalize">{iterable[0].parent_module}</strong>
                                          <div className="float-right">
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                              <input type="checkbox"
                                                disabled={iterable[0].print_configuration_level == 'hospital'}
                                                className="custom-control-input"
                                                checked={this.isItemChecked(iterable[0].parent_module)}
                                                id={`${iterable[0].parent_module}_ats`}
                                                name={iterable[0].parent_module}
                                                onClick={this.handleScreenFullModule}
                                              />
                                              <label className="custom-control-label" htmlFor={`${iterable[0].parent_module}_ats`}>Add to screen</label></div>
                                          </div>
                                        </div>
                                        <div className="card-body p-0">
                                          <ul className="list-group list-group-flush  subjective" id="accordion">
                                            {iterable.sort((a, b) => a.id > b.id ? 1 : -1).map((item, i) => {
                                              if (item.parent_module != "paediatric" && item.parent_module != "obsgynae") {
                                                return (<>
                                                  <li className="list-group-item ui-state-default" key={item.id} data-printorder={1} data-displayorder={1} data-print="Y" data-display="Y" data-moduleid={1}>
                                                    <span className="ordno">{i + 1}.</span><strong>{item.display_name}</strong>
                                                    <div className="float-right">
                                                      <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                        <input type="checkbox"
                                                          disabled={item.print_configuration_level == 'hospital'}
                                                          onClick={this.handlePrintCheck} checked={item.print === 'Y'}
                                                          className="custom-control-input subjectivecheckBoxClass"
                                                          data-id={item.id} id={`print_${item.id}`}
                                                          data-childid={1} data-parent={`${iterable[0].parent_module}`}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`print_${item.id}`}>Add to Print</label></div>
                                                    </div>
                                                  </li>
                                                </>)
                                              }
                                            })}
                                            {i === 1 && isPaediatrics === 1 ?
                                              <>
                                                <li className="list-group-item ui-state-default">
                                                  <span className="ordno">5.</span><strong>
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                                      Paediatrics
                                                    </a></strong>

                                                  <div id="collapse_1" className="collapse" role="tabpanel" aria-labelledby="collapse_1">
                                                    <ul className="paediatrics_list">
                                                      {soapListData[1]?.map((val, j) => {
                                                        if (val.name == "child_development" || val.name == "vaccination_chart") {
                                                          return (<>
                                                            <li className="list-group-item ui-state-default" key={val.id} data-printorder={1} data-displayorder={1} data-print="Y" data-display="Y" data-moduleid={1}>
                                                              <strong>{val.display_name}</strong>
                                                              <div className="float-right">
                                                                <div className="custom-control custom-checkbox custom-control-inline">

                                                                  <input disabled={val.print_configuration_level == 'hospital'}
                                                                    type="checkbox"
                                                                    onChange={this.handleScreenCheck}
                                                                    checked={val.display == 'Y'}
                                                                    data-check={val.display + val.print}
                                                                    className="custom-control-input assessmentscreen"
                                                                    id={`screen_${val.id}`}
                                                                    data-id={val.id} name="assessment_add_to_screen"
                                                                    data-parent={`${val.parent_module}`}
                                                                  />

                                                                  <label className="custom-control-label" htmlFor={`screen_${val.id}`}>Add to Screen</label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox custom-control-inline mr-0">

                                                                  <input
                                                                    disabled={val.print_configuration_level == 'hospital'}
                                                                    type="checkbox"
                                                                    onChange={this.handlePrintCheck}
                                                                    checked={val.print == 'Y'}
                                                                    className="custom-control-input assessmentcheckBoxClass"
                                                                    id={`print_${val.id}`}
                                                                    data-id={val.id} name="assessment_add_to_print"
                                                                    data-parent={`${val.parent_module}`}
                                                                  />
                                                                  <label className="custom-control-label" htmlFor={`print_${val.id}`}>Add to Print</label>

                                                                </div>
                                                              </div>
                                                            </li>
                                                          </>)
                                                        }


                                                        // <li className="list-group-item ui-state-default" key='15' data-printorder={1} data-displayorder={1} data-print="Y" data-display="Y" data-moduleid={1}>
                                                        //     <strong>Vaccination Chart</strong>
                                                        //     <div className="float-right">
                                                        //         <div className="custom-control custom-checkbox custom-control-inline"><input type="checkbox" className="custom-control-input" id="screen_5_13" name="paediatrics" /><label className="custom-control-label" htmlFor="screen_5_13">Add to Screen</label></div>
                                                        //         <div className="custom-control custom-checkbox custom-control-inline mr-0"><input type="checkbox" className="custom-control-input" id="screen_5_14" name="assessment_add_to_print" /><label className="custom-control-label" htmlFor="screen_5_14">Add to Print</label></div>
                                                        //     </div>
                                                        // </li>
                                                      })}



                                                      <li className="list-group-item ui-state-default">
                                                        <strong>Pediatric Dose Calculator</strong>
                                                        <div className="pl-4">
                                                          <div className="row">
                                                            <div className="col-6">
                                                              <div className="custom-control custom-radio mt-2">
                                                                <input type="radio" id="frieds_rule" name="dose" value="1" defaultChecked={doseRule == 1} className="custom-control-input" onChange={this.handleDoseChange} />
                                                                <label className="custom-control-label font-weight-semibold" htmlFor="frieds_rule">Fried’s Rule</label>
                                                              </div>
                                                              <div className="pl-4"><small>Dose = [Age (month)/150] * Adult Dose</small></div>
                                                            </div>
                                                            <div className="col-6">
                                                              <div className="custom-control custom-radio mt-2">
                                                                <input type="radio" id="youngs_rule" name="dose" value="2" defaultChecked={doseRule == 2} className="custom-control-input" onChange={this.handleDoseChange} />
                                                                <label className="custom-control-label font-weight-semibold" htmlFor="youngs_rule">Young’s Rule</label>
                                                              </div>
                                                              <div className="pl-4"><small>Dose =  (Age in years/ Age in years +12) * Adult Dose</small></div>
                                                            </div>
                                                            <div className="col-6">
                                                              <div className="custom-control custom-radio mt-2">
                                                                <input type="radio" id="clarkes_rule" name="dose" value="3" defaultChecked={doseRule == 3} className="custom-control-input" onChange={this.handleDoseChange} />
                                                                <label className="custom-control-label font-weight-semibold" htmlFor="clarkes_rule">Clarke's Rule</label>
                                                              </div>
                                                              <div className="pl-4"><small>Dose = (Body Weight in Kgs/ 70) * Adult Dose</small></div>
                                                            </div>
                                                            <div className="col-6">
                                                              <div className="custom-control custom-radio mt-2">
                                                                <input type="radio" id="dose_bsa" name="dose" value="4" defaultChecked={doseRule == 4} className="custom-control-input" onChange={this.handleDoseChange} />
                                                                <label className="custom-control-label font-weight-semibold" htmlFor="dose_bsa">Dose based on BSA</label>
                                                              </div>
                                                              <div className="pl-4"><small>(Child's BSA/ Average BSA for adult) * Adult Dose</small></div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                      </li>

                                                    </ul>
                                                  </div>
                                                </li>
                                              </>
                                              :
                                              ''
                                            }
                                            {i === 1 && isObsGynaeDoctor === 1 ? <>
                                              <li className="list-group-item ui-state-default">
                                                <span className="ordno">5.</span><strong>
                                                  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                                    Obstetrics & Gynaecology</a></strong>

                                                <div id="collapse_2" className="collapse" role="tabpanel" aria-labelledby="collapse_2">
                                                  <ul className="paediatrics_list">
                                                    {
                                                      soapListData[1]?.map((val, j) => {
                                                        if (val.name == "mensural_hx" || val.name == "sexual_hx" || val.name == "obstetrics_hx" || val.name == "physical_examination") {
                                                          return (
                                                            <>
                                                              <li className="list-group-item ui-state-default" key={val.id} data-printorder={1} data-displayorder={1} data-print="Y" data-display="Y" data-moduleid={1}>
                                                                <strong>{val.display_name}</strong>
                                                                <div className="float-right">
                                                                  <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input disabled={val.print_configuration_level == 'hospital'}
                                                                      type="checkbox"
                                                                      onChange={this.handleScreenCheck}
                                                                      checked={val.display == 'Y'}
                                                                      data-check={val.display + val.print}
                                                                      className="custom-control-input assessmentscreen"
                                                                      id={`screen_${val.id}`}
                                                                      data-id={val.id} name="assessment_add_to_screen"
                                                                      data-parent={`${val.parent_module}`}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={`screen_${val.id}`}>Add to Screen</label>
                                                                  </div>
                                                                  <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                                    <input
                                                                      disabled={val.print_configuration_level == 'hospital'}
                                                                      type="checkbox"
                                                                      onChange={this.handlePrintCheck}
                                                                      checked={val.print == 'Y'}
                                                                      className="custom-control-input assessmentcheckBoxClass"
                                                                      id={`print_${val.id}`}
                                                                      data-id={val.id} name="assessment_add_to_print"
                                                                      data-parent={`${val.parent_module}`}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor={`print_${val.id}`}>Add to Print</label>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                            </>
                                                          )
                                                        }
                                                      })
                                                    }
                                                  </ul>
                                                </div>
                                              </li>
                                            </>
                                              : ''
                                            }
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              )
                            })}
                          </div>
                          <div className="row soap_sortable" id="level2">
                            {soapDataSecondRow?.map((iterable, i) => (
                              <Fragment key={i}>
                                <div className={`col-auto pr-0 ${i === 1 ? "pl-0" : ""} `}>
                                  <div className="soap_card_no p-3">{iterable[0].order}</div>
                                </div>
                                <div className={`col pl-0 ${i === 0 ? "pr-0" : ""} `}>
                                  <div className="p-4 widget-panel" id="widget-3">
                                    <div className="card rounded-0 widget-card" id="widget-card-3">
                                      <div className="pl-3 py-2 mr-3">
                                        <strong className="text-capitalize">{iterable[0].parent_module}</strong>
                                      </div>
                                      <div className="card-body p-0">
                                        <ul className="list-group list-group-flush sortitem assessment">
                                          {iterable.sort((a, b) => a.id > b.id ? 1 : -1).map((item, i) => (
                                            <li className="list-group-item ui-state-default" key={item.id} data-printorder={2} data-displayorder={2} data-print="Y" data-display="Y" data-moduleid={9}>
                                              <span className="ordno">{i + 1}.</span><strong>{item.display_name}</strong>
                                              <div className="float-right">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                  <input
                                                    disabled={item.print_configuration_level == 'hospital'}
                                                    type="checkbox"
                                                    onChange={this.handleScreenCheck}
                                                    checked={item.display == 'Y'}
                                                    data-check={item.display + item.print}
                                                    className="custom-control-input assessmentscreen"
                                                    id={`screen_${item.id}`}
                                                    data-id={item.id} name="assessment_add_to_screen"
                                                  />
                                                  <label className="custom-control-label" htmlFor={`screen_${item.id}`}>Add to Screen</label>
                                                </div>
                                                <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                  <input
                                                    disabled={item.print_configuration_level == 'hospital'}
                                                    type="checkbox"
                                                    onChange={this.handlePrintCheck}
                                                    checked={item.print == 'Y'}
                                                    className="custom-control-input assessmentcheckBoxClass"
                                                    id={`print_${item.id}`}
                                                    data-id={item.id} name="assessment_add_to_print"
                                                  />
                                                  <label className="custom-control-label" htmlFor={`print_${item.id}`}>Add to Print</label>

                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                          </div>
                        </div>
                        <div className="mt-5">&nbsp;</div>
                        <div className="fixed-cta full">
                          {/* <button type="button" className="btn btn-outline-secondary px-4 mr-3" id="cancel">CANCEL</button> */}
                          <button type="button" className="btn btn-primary px-4 mr-3 configuration-save" disabled={this.state.isFormSaving}
                            onClick={this.handleSoapSave}>
                            SAVE{this.state.isFormSaving ? LOADER : null}
                          </button>
                        </div>
                        {/* SOAP VIEW CONFIGURATIONS ENDS HERE */}
                      </>
                      :
                      <>
                        {/* SINGLE SCREEN CONFIGURATIONS STARTS HERE */}
                        <div className="container-fluid mb-5 pb-5">
                          <table className="table emr_config_table" id="diagnosis-list">
                            <thead>
                              <tr>
                                <td width="30%">FIELDS</td>
                                <td className='text-centers' width="30%">OPD</td>
                                <td className='text-centers' width="30%">VIRTUAL CONSULT</td>
                                <td width="10%" className='text-center'>SEQUENCE</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <SortableContainerTop
                                  helperClass='sortableHelperSSConfig'
                                  onSortEnd={this.onSortEndTop}
                                  lockAxis={'y'}
                                  lockToContainerEdges={false}
                                  useDragHandle
                                  disableAutoscroll={true}
                                >
                                  {this.state.singleConfInfo?.map((iterable, i) => {
                                    return ( <SortableItemTop
                                      key={i}
                                      iterable={iterable}
                                      index={i}
                                      parent_index={i}
                                      handleSSScreenCheck={this.handleSSScreenCheck}
                                      handleSSCustomFieldTextbox={this.handleSSCustomFieldTextbox}
                                      handleSSCustomFieldRadio={this.handleSSCustomFieldRadio}
                                      onSortEndChild={this.onSortEndChild}
                                    // checkBoxChangeParent={this.handleOnCheckBoxChangeParent}
                                    /* collection={i} */
                                    />
                                    )}
                                  )}
                                </SortableContainerTop>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <div className="fixed-cta mt-3 full">
                          <button type="button" className="btn btn-primary px-4 mr-3 configuration-save" disabled={this.state.isSoapTypeFormSaving} onClick={this.handleSingleSave}>
                            SAVE{this.state.isSoapTypeFormSaving ? LOADER : null}
                          </button>
                        </div>
                        {/* SINGLE SCREEN CONFIGURATIONS ENDS HERE */}
                      </>
                  }


                </form>
              </div>
            </div>

            <Modal
              visible={this.state.isScreenMsgModalReq}
              onCancel={() => this.setState({ isScreenMsgModalReq: false })}
              footer={[
                <div className="text-center">
                  <button className="btn btn-outline-secondary px-5 cancel-delete-history"
                    onClick={() => this.setState({ isScreenMsgModalReq: false })}>OK</button>

                </div>
              ]}
            >
              <div className="row">
                <div className="col-12 my-3 text-center confirm-message">
                  <h6>Module must be added on screen before adding to print.</h6>
                </div>
              </div>
            </Modal>
          </>
          :
          <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>
            {LOADER_RED}
          </div>
        }
      </>
    )
  }
}

export default SoapComponent;