import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { PROFILE, PROFILE_UPDATE, MYHC_APPTYPE, MHVCMARKETPLACE, WOODLANDS, MHEA, HANDLEVALIDANTMESSAGES, USERTYPES } from '../../utils/constant';
import { Form, DatePicker, Upload, Input, Button, Select } from 'antd';
import ImgCrop from 'antd-img-crop';
import { notification } from 'antd';
import { jQueryLabels, FixedCta, AntSelectLabels, onlynumbers } from '../../utils/jQueryLabels';
import moment from 'moment';
import ic_calendar from "../../assets/images/ic-calendar.png";

const { Option } = Select;

class ProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfileData: [],
            userDataLoaded: false,
            editViewform: false,
            age: '',
            fileList: [],
            isPaediatrician: 0,
        }

        this.profileFormRef = React.createRef();
        this.editView = this.editView.bind(this);
        this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
        this.onProfilePicChange = this.onProfilePicChange.bind(this);

    }

    static getDerivedStateFromProps() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        return null;
    }

    componentDidMount() {
        this.userProfile();
        jQueryLabels();
        FixedCta();
        onlynumbers();
    }
    componentDidUpdate() {
        setTimeout(() => {
            jQueryLabels();
            FixedCta();
            let Elem = document.querySelectorAll('.textarea-field');
            Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
        }, 100);
    }

    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }
    userProfile() {
        const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;
        let title = LS_SERVICE.get('title');
        Axios.post(PROFILE({ is_doctor }), qs.stringify())
            .then(response => {
                //console.log('response>>>', response);
                this.setState({
                    userProfileData: {
                        ...response.data.data,
                        title: title
                    },
                    userDataLoaded: true
                });

                                LS_SERVICE.set('isPaediatrician', response.data.data.paediatric || 0)
                
                // debugger;
            }).catch(error => {
                // if (error && error.response && error.response.status === 401) {
                //     LS_SERVICE.clear();
                //     this.props.history.push("/");
                // }
            })
    }


    age(date) {
        let dob = date;
        let dateParts = (dob && dob !== null) ? dob.split("/") : '';
        let dateObject = (dob && dob !== null) ? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]) : '';
        dob = (dob && dob !== null) ? new Date(dateObject.toString()) : '';
        let today = new Date();
        let age = (dob && dob !== null) ? Math.floor((today - (dob)) / (365.25 * 24 * 60 * 60 * 1000)) : '';
        return age;
    }

    getTime(obj) {
        let start_time = (obj.start_time && obj.start_time !== 'null') ? obj.start_time : '--';
        let end_time = (obj.end_time && obj.end_time !== 'null') ? obj.end_time : '--';
        let doctor_timing;
        if (start_time === '--' && end_time === '--') {
            doctor_timing = ``;
        }
        else {
            // doctor_timing = ` | (` + obj.start_time + ` to ` + obj.end_time + `)`;
            doctor_timing = obj.start_time + ` to ` + obj.end_time;
        }
        return doctor_timing;
    }

    experience(date) {
        let exp;
        if (date > 0) {
            exp = date;
            if (date > 1) {
                exp = exp + " " + 'Years';
            } else {
                exp = exp + " " + 'Year';
            }
        } else {
            exp = '-'
        }
        return exp;
    }

    editView = () => {
        let { editViewform } = this.state;
        this.setState({
            editViewform: !editViewform
        })
        AntSelectLabels();
    }


    onProfileFileList = () => {
        return ({
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        })
    }
    onProfilePicChange = (file, fileList) => {
        this.setState({ fileList })
        return false;
    };

    onProfilePicPreview = async file => {
        let src = file.url;
        if (!src) {
            //   src = await new Promise(resolve => {
            //     const reader = new FileReader();
            //     reader.readAsDataURL(file.originFileObj);
            //     reader.onload = () => resolve(reader.result);
            //   });
            // src = this.state.profile_picture
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    onDatepickerChangeHandle = (e) => {
        AntSelectLabels();
    }

    handleProfileSubmit = () => {

        // const PROFILE_UPDATE_URL = 'https://mhappapis.vc.myhealthcare.co/api/doctors/web/v1/doctors/profile-update';

        const PROFILE_TOKEN = 'eyJpdiI6ImxUeXIra0VIMDdrb1hSY1F3Z2UyXC9nPT0iLCJ2YWx1ZSI6Imh1TmZDWWdLRTh2cVBVTHdrR3A4dkE9PSIsIm1hYyI6IjdjNzAxMTY4OTRkNGExYmMzN2U5N2VmZDZlNTQwY2I5YmNjYjU2ZTAwYThhZjBkNzk5YWMwM2Y1YTJiZGM3MjgifQ==';
        let PARAMS = this.profileFormRef.current.getFieldsValue(true);
        const { userProfileData } = this.state;
        PARAMS.doctor_id = LS_SERVICE.get(('staff_id'));
        if (!PARAMS.first_name) PARAMS.first_name = userProfileData.first_name;
        if (!PARAMS.gender) PARAMS.gender = userProfileData.gender.id;
        if (!PARAMS.qualification) PARAMS.qualification = userProfileData.qualification;
        if (PARAMS.dob) PARAMS.dob = PARAMS.dob.format('YYYY-MM-DD')
        let headers = {
            // 'apitoken': PROFILE_TOKEN,
            'Content-Type': 'application/json',
            // 'aggregatorType': 'opd_emr'
        }
        let formData = new FormData();
        for (const [field, value] of Object.entries(PARAMS)) {
            formData.append(field, value);
        }

        if (this.state.fileList.length > 0) {
            formData.append("profile_picture", this.state.fileList[0]);
        }

        const profileUpdatePromise = Axios.post(PROFILE_UPDATE, formData, { headers: headers }).then(data => {
            if (data.status == 200) {
                notification.success({
                    message: data.data.message,
                    placement: 'topRight'
                });
                this.editView();
                this.userProfile();
            }
            else {
                notification.success({
                    message: data.data.message,
                    placement: 'topRight'
                });
            }
        })


    }

    render() {
        const { userDataLoaded, userProfileData, editViewform } = this.state;
                return (
            <>

                {userDataLoaded ? (
                    editViewform === false ?
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col pl-4">
                                    <div className="row">
                                        <div className="col-auto pr-0">
                                            <figure className="patient-pic mb-0"><img className="img-fluid img-circle" src={userProfileData.profile_picture} alt={userProfileData.name} /></figure>
                                        </div>
                                        <div className="col">
                                            <h5 className="font-weight-normal m-0 text-dark">{(userProfileData.title !== null ? userProfileData.title + '. ' : 'Dr. ') + userProfileData.first_name}</h5>
                                            <p>
                                                {/* {userProfileData.gender} |  */}
                                                {(MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA) ? userProfileData.gender.value : userProfileData.gender}
                                                {(userProfileData.dob !== null && userProfileData.dob !== '' && this.age(userProfileData.dob) > 0) ? ' | ' + this.age(userProfileData.dob) + ' Years' : null} <br />
                                                {userProfileData.designation}<br />
                                                {userProfileData.qualifications.replace(/&amp;/g, '&').replace(/<[^>]+>/g, '')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="col-auto">
                                    <button onClick={e => this.editView()} type="button" className="btn btn-outline-secondary profile-edit"><i className="icon-edit"></i><span>EDIT</span></button>
                                </div>*/}

                                {MYHC_APPTYPE === WOODLANDS ? <div className="col-12 col-md-7">
                                    <ul className="list-icons float-md-right  pr-md-5 pl-2 pl-md-0">
                                        {userProfileData.experience !== null && userProfileData.experience > 0 ?
                                            <li>
                                                <i className="myicon experience"></i>
                                                <p className="m-0">Experience <strong>{this.experience(userProfileData.experience)}</strong></p>
                                            </li>
                                            : ""}

                                        <li>
                                            <i className="myicon document"></i>
                                            <p className="m-0">Document <strong>{userProfileData.is_verified === 1 ? 'Verified' : 'Not Verified'}</strong></p>
                                        </li>
                                        {userProfileData.woodlands_gid !== null ?
                                            <li>
                                                <i className="myicon gid"></i>
                                                <p className="m-0">GID <strong>{userProfileData.woodlands_gid}</strong></p>
                                            </li>
                                            : ""}
                                    </ul>
                                </div> : null}
                            </div>
                            <div className="row">
                                <div className={((MYHC_APPTYPE === WOODLANDS) ? "border-right " : "") + "col-12 col-md-5 pt-4 pr-4 pl-4 pt-md-5 pl-md-5 pt-md-5"}>
                                    <ul className="list-icons">
                                        {(userProfileData.woodlands_email !== null && userProfileData.woodlands_email != "NULL")?
                                            <li>
                                                <i className="myicon email"></i>
                                                <p>{userProfileData.woodlands_email}</p>
                                            </li> : ""}

                                        <li>
                                            <i className="myicon hospital"></i>
                                            <h6 className="pt-2">Specialities</h6>
                                            <p>{userProfileData.specialities.map((item, index) => <span key={index}>{(index ? ', ' : '') + item}</span>)}</p>
                                        </li>
                                        <li>
                                            <i className="myicon hospital"></i>
                                            <h6 className="pt-2">Timing</h6>

                                            {userProfileData.schedule != null ?
                                                userProfileData.schedule.map((obj, ind) => (
                                                    <>
                                                        <p className="mb-0 font-weight-semibold">{obj.name ? obj.name : null}</p>
                                                        <p>{/*{obj.availability ? obj.availability.availability.map((item, index) => (index ? ', ' : '') + item) : null}*/}
                                                            {/* ({obj.start_time} to {obj.end_time}) */}
                                                            {this.getTime(obj)}
                                                        </p>
                                                    </>
                                                )) : null}
                                        </li>

                                        {MYHC_APPTYPE === WOODLANDS && userProfileData.membership ?
                                            <li>
                                                <i className="myicon member"></i>
                                                <h6 className="pt-2">Memberships</h6>
                                                <p>{userProfileData.membership.replace(/<[^>]+>/g, '')}</p>
                                            </li>
                                            : ""}
                                    </ul>

                                </div>

                                {MYHC_APPTYPE === WOODLANDS ? <div className="col-12 col-md-7 pt-4 pr-4 pl-4 pt-md-5 pl-md-5 pt-md-5">
                                    {userProfileData.experiance_details ?
                                        <>
                                            <h6>Experience</h6>
                                            <ul className="list-prime-circle">
                                                <li>{userProfileData.experiance_details.replace(/<[^>]+>/g, '')} </li>
                                            </ul>
                                        </>
                                        : ""}

                                    {userProfileData.awards ?
                                        <>
                                            <h6>Accomplishments &amp; Awards</h6>
                                            <ul className="list-prime-circle">
                                                <li>{userProfileData.awards.replace(/<[^>]+>/g, '')}</li>
                                            </ul>
                                        </>
                                        : ""}

                                </div> : null}
                            </div>
                        </div>

                        :
                        <>
                            <div className="container-fluid">
                                <div className="px-4">
                                    <div className="row mb-4">
                                        <h5 className="col font-weight-normal text-dark">Edit Profile</h5>
                                        <div className="col-auto">
                                            <button onClick={e => this.editView()} type="button" className="btn btn-outline-secondary profile-edit"><span>BACK</span></button>
                                        </div>
                                    </div>

                                    <Form
                                        id="profileForm"
                                        className="emrfrm"
                                        ref={this.profileFormRef}
                                        validateMessages={HANDLEVALIDANTMESSAGES}
                                        onFinish={this.handleProfileSubmit}
                                        layout="vertical"
                                    >

                                        <div className="row">
                                            <div className="col-4 antInputItem">
                                                <Form.Item name="first_name" label="First Name">
                                                    <Input maxLength={50} defaultValue={userProfileData.first_name} />
                                                </Form.Item>
                                            </div>
                                            <div className="col-4 antInputItem">
                                                <Form.Item name="middle_name" label="Middle Name">
                                                    <Input maxLength={50} defaultValue={userProfileData.middle_name} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-4 antInputItem">
                                                <Form.Item name="last_name" label="Last Name">
                                                    <Input maxLength={50} defaultValue={userProfileData.last_name} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-4">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="antSelectItem">
                                                            <Form.Item name="gender" label="Gender">
                                                                <Select defaultValue={(MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA) ? userProfileData.gender.value : userProfileData.gender}>
                                                                    <Option value="0">Male</Option>
                                                                    <Option value="1">Female</Option>
                                                                    <Option value="2">Other</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 antInputItem">
                                                        <Form.Item name="dob" label="Date of Birth">
                                                            {/* {this.state.dob} */}
                                                            <DatePicker name="dob"
                                                                defaultValue={userProfileData.dob ? moment(userProfileData.dob, 'DD/MM/YYYY') : null}
                                                                onChange={e => this.onDatepickerChangeHandle(e)}
                                                                placeholder=""
                                                                dateFormat="DD/MM/YYYY"
                                                                className="form-control ant-input"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="col-4 antInputItem">
                                                <Form.Item name="email" label="Email ID"
                                                    rules={[{
                                                        type: 'email',
                                                        message: 'This is not a valid email'
                                                    }]}
                                                >
                                                    <Input defaultValue={userProfileData.woodlands_email} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-4 antInputItem field_disabled">
                                                <Form.Item name="woodlands_mobile" label="Mobile No" className="onlynumbers">
                                                    <Input maxLength={10} defaultValue={userProfileData.woodlands_mobile} disabled />
                                                </Form.Item>
                                            </div>

                                            <div className="col-4 antInputItem">
                                                <Form.Item name="qualification" label="Qualification">
                                                    <Input defaultValue={(userProfileData.qualification).replace(/&amp;/g, '&').replace(/<[^>]+>/g, '')} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-4 antInputItem">
                                                <Form.Item name="designation" label="Designation">
                                                    <Input defaultValue={(userProfileData.designation).replace(/<[^>]+>/g, '')} disabled />
                                                </Form.Item>
                                            </div>

                                            <div className="col-4 antInputItem field_disabled">
                                                <Form.Item name="specialities" label="Specialities">
                                                    <Input defaultValue={userProfileData.specialities} disabled />
                                                </Form.Item>
                                            </div>


                                            <div className="col-4 antInputItem field_disabled">
                                                <Form.Item name="woodlands_gid" label="GID" >
                                                    <Input defaultValue={userProfileData.woodlands_gid} disabled />
                                                </Form.Item>
                                            </div>

                                            <div className="col-4 antInputItem field_disabled">
                                                <Form.Item name="doctor_registration_number" label="Registration number">
                                                    <Input defaultValue={userProfileData.doctor_registration_number} disabled />
                                                </Form.Item>
                                            </div>


                                            <div className="col-4 antInputItem field_disabled">
                                                <Form.Item name="experience" label="Years of Experience">
                                                    <Input defaultValue={userProfileData.experience} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-12 antInputItem">
                                                <Form.Item name="profile_text" label="Profile Text">
                                                    <textarea className="form-control ant-input textarea-field'" rows="3"
                                                        defaultValue={userProfileData.profile_text}
                                                        onInput={(e) => this.autoHeight(e)}
                                                    />
                                                </Form.Item>
                                            </div>

                                            {/*<div className="col-12 antInputItem">
                                                <Form.Item name="experience" label="Experience Details">
                                                    <textarea className="form-control ant-input textarea-field'" rows="3"
                                                        defaultValue={userProfileData.experiance_details}
                                                        onInput={(e) => this.autoHeight(e)}
                                                    />
                                                </Form.Item>
                                            </div>*/}


                                        </div>
                                        <div className="row pb-4">
                                            {/*<div className="col-6 ">
                                                <Form.Item name="profile_picture">
                                                    <ImgCrop rotate>
                                                        <Upload
                                                            listType="picture-card"
                                                            fileList={this.state.fileList}
                                                            // onChange={this.onProfilePicChange}
                                                            // onPreview={this.onProfilePicPreview}
                                                            beforeUpload={this.onProfilePicChange}
                                                        >
                                                            {this.state.fileList.length < 2 && '+ Upload/Update profile picture'}
                                                        </Upload>
                                                    </ImgCrop>
                                                </Form.Item>
                                            </div>*/}

                                            <div className="col-6 text-right pt-4">
                                                <Form.Item>
                                                    <button className="btn btn-primary px-3">UPDATE</button>
                                                </Form.Item>
                                            </div>




                                        </div>



                                    </Form>

                                </div>
                            </div>
                        </>


                ) : (
                    <div className="container-fluid text-center">
                        <div className="spinner red"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>
                    </div>

                )}
            </>
        )
    }
}

export default ProfileComponent;