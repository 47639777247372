import Axios from "axios";
import React, { Component } from "react";
import Bowser from "bowser";
import {
  CREATE_TEMPLATE,
  USERTYPES,
  SOAP_DETAILS,
  DRUG_URGENCY,
  DOSAGE_VALUE,
  DRUG_FREQUENCY,
  DRUG_ROUTE,
  DURATION_TYPE_OBJECT_REVERSE,
  DURATION_TYPE_OBJECT_REVERSE_SINGULAR,
  DRUG_INSTRUCTION,
  LOADER_RED,
  COMPLETE_APPOINTEMENT,
  UPDATE_QMS_STATUS,
  PLAN_TEST_ADVICED_LIST,
  LOADER,
  ASSESSMENT_STATUS_UPDATE,
  PHYSIO_UPADTE,
  MYHC_APPTYPE,
  WOODLANDS,
  ADMIS_UPADTE,
  ADMISSION_UPDATE,
  HANDLEVALIDANTMESSAGES,
  PDF_CONFIG_VITALS_GRAPH,
  PATIENT_VITALS_ALL,
  PATIENT_DATA,
  PRESCRIPTION_STORE,
  DELETE_MEDICINE,
  ASSESSMENT_STORE,
  ASSESSMENT_DELETE,
  DELETE_PRESCRIPTION_TEST,
  PLAN_TEST_ADVICED_STORE,
  SUBJECTIVE_STORE,
  SUBJECTIVE_STORE_CHIEF_COMPLAINTS,
  ISALLOWEDITSOAP,
  GET_ADDENDUM,
  STORE_ADDENDUM,
  TAB_NAMES,
  VaccinationList,
  VaccinationItemList,
  VaccinationByRefence,
  VaccinationDelete,
  VaccinationUpdate,
  MILESTONES_CHILD_LIST,
  DEFAULTVITALGROUPID,
  PAEDRATICVACCINEID,
  KEYFINDINGS_FILTER_SEQUENCING_LIST,
  MENSTRUAL_HISTORY_GRAPH,
  CONCEPTION_OPTIONS,
  PREGNANCY_OPTIONS,
  DELIVERY_OPTIONS,
  OUTCOME_OPTIONS,
  PLACE_OF_DELIVERY_OPTIONS,
  FETAL_MOVEMENT,
  PRESENTATION,
  RELATIONSHIP_TO_PP_BRIM,
  MENSTRUAL_HX_TREND_GRAPH,
  TOGGLE_PRACTICE,
  DELETE_VISIT_SUPPORTING_MATERIAL,
  ALL_CIMS_INTERACTION,
  ALL_CIMS_INTERACTION_EXISTS,
  CIMSALLOW
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import API_SERVICE from '../../utils/apiService';
import axios from "axios";
import qs from "qs";
import AsideLeft from "../../components/aside/asideleft";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Form, Modal, Input, Button, Select, Radio, Checkbox } from "antd";
import { jQueryLabels, FixedCta, AntSelectLabels } from "../../utils/jQueryLabels";
import { notification, DatePicker, Tooltip } from "antd";
import moment from "moment";
import Moment from "react-moment";
import TemplateAssesment from "../create-template/template-assesment";
// import TemplateMedicine from '../create-template/template-medicine';
import MedicineForm from "../../components/drugs/medicine-form";
import Templatetests from "../create-template/template-test";
import Chart from "../../components/chart/chart";
import KeyFindingsChart from "../../components/chart/key-findings-chart";
import FetalParametersTrendGraph from "../../components/chart/fp-trend-graph";
import AssessmentTable from "../../components/assessments/assessment-table";
import AssessmentForm from "../../components/assessments/assessment-form";
import TestForm from "../../components/tests/test-form";
import TestTable from "../../components/tests/test-table";
import MedicineTable from "../../components/drugs/medicine-table";
import { headerService$, practiceService } from "../../utils/rxjs-sharing";
import GynaeTrendGraph from "../../components/chart/trend-graph";

import { CDC, WHO, IAP, WHOIAP } from "../../utils/growthchartdata";
import IndividualPaediatricChart from "../../components/chart/paediatrics-chart-individual";
import SoapFlow from '../../utils/soap';

const { Option } = Select;
export class ViewPrescriptionSS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: props.location?.state?.patient,
      details: [],
      field_status: [],
      subjectiveData: [],
      subjectiveClinicalData: [],
      assessmentData: [],
      addedAssessmentList: [],
      drugsData: [],
      addedDrugsList: [],
      testAdvisedDuplicateOrders: [],
      testData: [],
      addedTestList: [],
      constants: [],
      admissionChecked: false,
      isAdmissionAdvisedPriority: false,
      physiotherapy_advised: 2,
      physioText: "",
      admissionText: "",
      isDataFetching: true,
      isModal: false,
      isEventBased: 'N',
      unfulifilledModal: false,
      isPrintLoader: false,
      savedisabled: true,
      istemplateErrorVisible: false,
      alertMessage: "",
      chartGraphSettings: "",
      clinicalNoteIds: [],
      growthChartSettings: {},
      otheroptions: [],
      growthChartData: {},
      growthData: [],
      showGrowthChart: false,
      chartDataLoaded: false,
      chartData: [],
      menstrualHistoryChartDataLoaded: false,
      menstrualHistoryChartData: [],
      bloodSugarChartData: null,
      vitalsRangeWithId: null,
      deleteMedicineId: null,
      delete_text: "",
      delete_data: null,
      deleteConfirmModal: false,
      deleteAssessmentModal: false,
      spin_loading: false,
      polyPharmacyWarningShown: false,
      editData: null,
      editIndex: -1,
      sourceOfRerral: "viewPrescription",
      blockname: "",
      isPracticing: false,
      isPracticingModal: false,
      allowEdit: true,
      allowAddendum: false,
      visitAddendum: "",
      zoomClass: false,
      vaccinationData: [],
      itemData: [],
      record: {},
      vaccDeleteId: 0,
      vaccBatch: "",
      vaccName: "",
      vaccRemark: "",
      vaccGivenon: "",
      vaccExpireon: "",
      vaccGivenoutside: 0,
      vaccItemId: 0,
      vaccEditId: 0,
      filterMilestoneData: [],
      isPaediatrics: 0,
      showingVitals: [],
      vitalsConstant: [],
      birthDate: moment().format("DD/MM/YYYY"),
      keyFindings: [],
      keyFindingsFiltersLoaded: false,
      newKeyFindingsOrder: [],
      keyFindingsChartGraphSettings: "",
      mensural_history_status: false,
      sexual_history_status: false,
      obs_history_status: false,
      phy_examination_status: false,
      conception_options: CONCEPTION_OPTIONS,
      pregnancy_options: PREGNANCY_OPTIONS,
      delivery_options: DELIVERY_OPTIONS,
      outcome_options: OUTCOME_OPTIONS,
      place_of_delivery_options: PLACE_OF_DELIVERY_OPTIONS,
      obsHistoryList: [],
      gpale_values: { gravidity: null, parity: null, abortion: null, live_birth: null, ectopic: null },
      phyExaminationList: [],
      phyExaminationFpList: [],
      current_pregnancy: null,
      latest_menst_hist: null,
      chartDataFp: {},
      chartDataLoadedFp: false,
      chartGraphSettingsFp: "",
      supportingFile: [],
      showPdfModal: false,
      pdfPath: "",
      showPintComplete: (LS_SERVICE.get('appointment_date') > moment().format('YYYY-MM-DD')) ? true : false,
      junior_doctor_complete_appointment: false,
      isModalAllInteractionVisible: false,
      drug_id_Interaction_Modalurl: [],
      cimsallow:CIMSALLOW,
      addedDrugsList:[],
      addedAssessmentList:[]
    };
    this.name = "";
    this.vaccineBatchRef = React.createRef();
    this.vaccineRemarkRef = React.createRef();
    this.vaccineDateRef = React.createRef();
    this.vaccineExpireRef = React.createRef();
    this.vaccineItemRef = React.createRef();
    this.vaccineOutsideRef = React.createRef();
    this.deleteButton = React.createRef();
    /*this.assessments_data = [];
        this.drugs_data = []
        this.tests_data = []
        this.instructions_data = [];*/
  }

  handleAllInteractionCancel = () => { this.setState({ isModalAllInteractionVisible: false }); };
  handleAllInteractionOk = () => { this.setState({ isModalAllInteractionVisible: false }) };
  showModalInteraction = () => {

    let {addedAssessmentList,addedDrugsList} = this.state

    if (typeof addedAssessmentList == 'undefined' || !addedAssessmentList || addedAssessmentList.length === 0 || addedAssessmentList === "" ) {
        addedAssessmentList = 0;
      } else {

        var result = Array.isArray(addedAssessmentList);
        if (result) {
            addedAssessmentList = addedAssessmentList.join('-');
        }
        else {
        if (typeof addedAssessmentList == 'undefined' || !addedAssessmentList || addedAssessmentList.length === 0 || addedAssessmentList === "" ) {
            addedAssessmentList = 0;
        } else {
            addedAssessmentList = addedAssessmentList.split(',');
            addedAssessmentList = addedAssessmentList.join('-');
            }
        } 
    }
    if (typeof addedDrugsList == 'undefined' || !addedDrugsList || addedDrugsList.length === 0 || addedDrugsList === "" ) {
        addedDrugsList = 0;
      } else {
        if(addedDrugsList && addedDrugsList.length > 0){
            addedDrugsList = addedDrugsList.join('-');
        }else{
            addedDrugsList = 0;
        } 
    }
    let medicineScreen = 'careprotocol';
    let referenceId = LS_SERVICE.get("call_patient_qms_token_id");
    let StoreId = LS_SERVICE.get("staff_id") ? LS_SERVICE.get("staff_id") : 0;
    let FacilityId = LS_SERVICE.get("FACILITY_ID") ? LS_SERVICE.get("FACILITY_ID") : 0;
    let enterpriseId = LS_SERVICE.get("ENTERPRISE_ID") ? LS_SERVICE.get("ENTERPRISE_ID") : 0;
    let patientId = LS_SERVICE.get("call_patient_id") ? LS_SERVICE.get("call_patient_id") : 0;
    let referenceType = 0;
    let orederSetId = 0;
    let careProtoColId = 0;
    let showAllInteractionModal = false;

    if (CIMSALLOW) {
      let drugIds = addedDrugsList;
      axios.get(ALL_CIMS_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList }))
        .then(success => {
          if (success.data.interaction) {
            showAllInteractionModal = true;
            this.setState({ drug_id_Interaction_Modalurl: drugIds, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId, addedAssessmentList: addedAssessmentList, medicineScreen: medicineScreen, isModalAllInteractionVisible: showAllInteractionModal });
          }else {
            notification.error({
              message: `No interactions Found`,
              placement: 'topRight'
            })
          }
        })
        .catch(err => console.log(err))
    } else {
      this.setState({ isModalAllInteractionVisible: false })
    }
  };



  showAlertMModal = () => {
    this.setState({ isAlertMedicineMVisible: true });
  };
  handleAlertMOk = () => {
    this.setState({ isAlertMedicineMVisible: false });
    this.MedicineOndelete(this.state.deleteMedicineId);
  };
  handleAlertMCancel = () => {
    this.setState({ isAlertMedicineMVisible: false });
  };
  showTemplateErrorModal = () => {
    this.setState({ istemplateErrorVisible: true });
  };
  handleTemplateErrorOk = () => {
    this.setState({ istemplateErrorVisible: false });
  };
  handleTemplateErrorCancel = () => {
    this.setState({ istemplateErrorVisible: false });
  };

  showVaccineAlert = (id) => {
    this.setState({ isAlertVaccineVisible: true, vaccDeleteId: id });
  };
  handleVaccineCancel = () => {
    this.setState({ isAlertVaccineVisible: false, vaccDeleteId: 0 });
  };
  handleVaccineOk = () => {
    this.setState({ isAlertVaccineVisible: false });
    this.VaccineOndelete(this.state.vaccDeleteId);
  };
  VaccineOndelete = (val) => {
    Axios.post(VaccinationDelete, qs.stringify({ id: val }))
      .then((success) => {
        this.setState({ vaccDeleteId: 0 });
        this.loadData();
      })
      .catch((err) => console.log(err));
  };

  showVaccineEAlert = (id, vaccName, batch, remark, givenon, expireon, givenoutside, itemid) => {
    this.setState({
      isAlertVaccineEVisible: true,
      vaccName: vaccName,
      vaccEditId: id,
      vaccBatch: batch,
      vaccRemark: remark,
      vaccGivenon: givenon,
      vaccExpireon: expireon,
      vaccGivenoutside: givenoutside,
      vaccItemId: itemid,
    });
  };
  handleVaccineECancel = () => {
    this.setState({ isAlertVaccineEVisible: false });
  };
  handleVaccineEOk = () => {
    this.setState({ isAlertVaccineEVisible: true });
    this.VaccineOnEdit(this.state.deleteMedicineId);
  };
  VaccineOnEdit = () => {
    let batch_data = "";
    let remark_data = "";
    let date_data = "";
    let expire_data = "";
    let item_data = "";

    const batch_val = this.vaccineBatchRef.current.props.value;
    const batch_def = this.vaccineBatchRef.current.props.defaultValue;
    if (batch_val == undefined) {
      batch_data = batch_def;
    } else {
      batch_data = batch_val;
    }

    const remark_val = this.vaccineRemarkRef.current.props.value;
    const remark_def = this.vaccineRemarkRef.current.props.defaultValue;
    if (remark_val == undefined) {
      remark_data = remark_def;
    } else {
      remark_data = remark_val;
    }

    const date_val = this.vaccineDateRef.current.props.value;
    const date_def = this.vaccineDateRef.current.props.defaultValue
      ? this.vaccineDateRef.current.props.defaultValue._i
      : this.vaccineDateRef.current.props.defaultValue;
    if (date_val == undefined) {
      date_data = date_def;
    } else {
      date_data = moment(date_val._d).format("YYYY-MM-DD");
    }

    const expire_val = this.vaccineExpireRef.current.props.value;
    const expire_def = this.vaccineExpireRef.current.props.defaultValue
      ? this.vaccineExpireRef.current.props.defaultValue._i
      : this.vaccineExpireRef.current.props.defaultValue;
    if (expire_val == undefined) {
      expire_data = expire_def;
    } else {
      expire_data = moment(expire_val._d).format("YYYY-MM-DD");
    }

    const item_val = this.vaccineItemRef.current.props.defaultValue;
    const item_def = this.vaccineItemRef.current.props.value;
    if (item_def == undefined) {
      item_data = item_val;
    } else {
      item_data = item_def;
    }

    const outside_def = this.vaccineOutsideRef.current.state.checked ? 1 : 0;

    if (outside_def == 0 && item_data == 0) this.vaccineItemRef.current.focus();
    else if (outside_def == 1 && date_data == undefined) this.vaccineDateRef.current.focus();
    else if ((batch_data == "" && item_data != 0) || date_data == undefined) {
      if (batch_data != "") {
        this.vaccineDateRef.current.focus();
      } else {
        this.vaccineBatchRef.current.focus();
      }
    } else {
      Axios.post(
        VaccinationUpdate,
        qs.stringify({
          id: this.state.vaccEditId,
          batch: batch_data,
          remarks: remark_data,
          given_on: date_data,
          expiry_date: expire_data,
          given_outside: outside_def,
          item_id: item_data,
        })
      )
        .then((success) => {
          this.setState({ isAlertVaccineEVisible: false });
          this.loadData();
        })
        .catch((err) => console.log(err));
    }
  };

  getVisitTestList = async _ => {
    const { patient_id, qms_token_id } = this.state.patient;
    // this.setState({testDataLoaded:false})

    const planTestAdvPromise = await axios.get(PLAN_TEST_ADVICED_LIST({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id, patient_id }))
    if (planTestAdvPromise.data.status === 'success') {
      const { constants, details, duplicateOrderTests } = planTestAdvPromise.data.data;
      this.setState({
        testAdvisedDuplicateOrders: duplicateOrderTests,
      })
    }
  }

  async componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    this.setState({
      isDataFetching: true,
    });

    const {
      match: { params },
    } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const isPracticingHospitalId = LS_SERVICE.get("practicing")?.hospital_id;
    const speciality_id = LS_SERVICE.get("speciality_id");
    const is_paediatrician = LS_SERVICE.get("isPaediatrician");
    if (is_paediatrician == 1) {
      this.setState({ isPaediatrics: 1 });
    }
    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true,
      });
    }
    if (this.state.patient) {
      await this.setState({
        patientData: this.state.patient,
      });
    } else {
      await this.getPatientData(reference_id);
    }
    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id,
    };
    let hospitalConfigData = LS_SERVICE.get("hospitalConfigData") ?? [];
    hospitalConfigData = hospitalConfigData.find((item) => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData
      ? ISALLOWEDITSOAP(
        hospitalConfigData.pres_modification,
        hospitalConfigData.allow_hours,
        LS_SERVICE.get("appointmentCompletedTime")[reference_id],
        "button"
      )
      : true;
    let allowAddendum = hospitalConfigData && hospitalConfigData.pres_modification == "addendum" ? true : false;
    this.setState({ hospital_id, patient_id, reference_id, patient, allowEdit, allowAddendum }, async () => {
      if (LS_SERVICE.has("hospitalConfigData")) {
        const hospitalConfigData = LS_SERVICE.get("hospitalConfigData");
        let hospitalConfigObj = hospitalConfigData.find((obj) => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        this.setSoapFlow();
        await this.handleKeyFindingFilterData();
        this.handleInitialData();
        if (allowAddendum) this.getAddendumData();
      }
      await this.getMilestonesPatientSaved();
      this.loadData();
      this.getVisitTestList();
    });

    this.menstrualGraph();
    this.checkJuniorDoctorCompleteAppoitment();
  }

  checkJuniorDoctorCompleteAppoitment() {
    let junior_doctor_complete_appointment = LS_SERVICE.get('junior_doctor_complete_appointment');
    let is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
    let call_patient_detail = LS_SERVICE.get('call_patient_detail');
    let current_login_doctor = LS_SERVICE.get('staff_id');
    if (is_junior_doctor == true && current_login_doctor != call_patient_detail.doctor_id) {
      this.setState({
        junior_doctor_complete_appointment: !junior_doctor_complete_appointment
      })
    }
  }

  async setSoapFlow() {
    const { hospital_id } = this.state;
    SoapFlow.hospital(hospital_id)

    const mensural_history_status = SoapFlow.printElement('obsgynae', 'mensural_hx');
    const sexual_history_status = SoapFlow.printElement('obsgynae', 'sexual_hx');
    const obs_history_status = SoapFlow.printElement('obsgynae', 'obstetrics_hx');
    const phy_examination_status = SoapFlow.printElement('obsgynae', 'physical_examination');
    this.setState({
      mensural_history_status,
      sexual_history_status,
      obs_history_status,
      phy_examination_status
    });


  }

  handleKeyFindingFilterData = async () => {
    let details = LS_SERVICE.get('call_patient_detail');

    const keyFindingFilterPromise = await Axios.get(KEYFINDINGS_FILTER_SEQUENCING_LIST({ doctor: details.doctor_id }));

    if (keyFindingFilterPromise.data.status) {
      this.setState({
        keyFindings: keyFindingFilterPromise.data.data,
        keyFindingsFiltersLoaded: true,
      });
    }
  };

  updateKeyFindingsOrder = async () => {
    let new_key_findings_order = [];
    this.state.keyFindings.map((fo, k2) => {
      let obj = { display_order: fo.display_order, finding_group_id: fo.finding_group_id, vital_sign: [] };
      let at_least_one_av = false;
      let findex = this.state.KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
      if (findex !== -1) {
        obj.name = this.state.KeyFindingsConstants[findex].name;
        fo.vital_sign.map((v, k) => {
          let obj_vital_sign = { ...v };
          let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
          if (findex2 !== -1) {
            obj_vital_sign.display_name = this.state.KeyFindingsConstants[findex].vital_sign[findex2].display_name;
            obj_vital_sign.id = this.state.KeyFindingsConstants[findex].vital_sign[findex2].id;
            obj_vital_sign.decimal_value = this.state.KeyFindingsConstants[findex].vital_sign[findex2].decimal_value;
            obj_vital_sign.display_value = this.state.KeyFindingsConstants[findex].vital_sign[findex2].display_value;

            let vital = this.state.KeyFindingsConstants[findex].vital_sign[findex2];
            let keyfindingsavailable = false;

            if (
              this.state.details?.visitVitalsKeyFindings?.[0]?.[vital.id] &&
              this.state.details?.visitVitalsKeyFindings?.[0]?.[vital.id] != "" &&
              this.state.details?.visitVitalsKeyFindings?.[0]?.[vital.id] != "NaN"
            ) {
              keyfindingsavailable = true;
              at_least_one_av = true;
            }
            /* this.state.visitKeyFindingsFormatted[vital.id]?.map((val, i) => {
                            if (val && val != "") {
                                keyfindingsavailable = true;
                                at_least_one_av = true;
                            }
                        }) */
            obj_vital_sign.value_available = keyfindingsavailable;
            obj.vital_sign.push(obj_vital_sign);
          }
        });
      }
      obj.one_value_available = at_least_one_av;
      new_key_findings_order.push(obj);
    });
    await this.setState({
      newKeyFindingsOrder: new_key_findings_order,
    });
  };

  getMilestonesPatientSaved = async () => {
    const { patient } = this.state;
    const { patient_id } = patient;
    const PARAMS = {
      patient_id,
    };

    await Axios.post(MILESTONES_CHILD_LIST, PARAMS)
      .then((success) => {
        if (success?.data?.status == true) {
          const { qms_token_id } = patient;
          let filtr_data = [];
          success.data.data.forEach((ele, i) => {
            if (ele.milestone_categories.length > 0) {
              let age = "";
              let cat_data = [];
              ele.milestone_categories.forEach((el, j) => {
                if (qms_token_id == el.referenceId) {
                  age = el.child_milestones.age_label;
                  let combine = {
                    cat_id: el.child_milestones.parent_category.id,
                    cat: el.child_milestones.parent_category.category,
                    sub_cat: el.child_milestones.category,
                  };
                  cat_data.push(combine);
                }
              });
              filtr_data[i] = { age: age, data: cat_data };
            }
          });

          let update_data = [];
          filtr_data.forEach((ele, i) => {
            if (ele.age != "") {
              update_data.push(ele);
            }
          });

          let final_data = [];
          update_data.forEach((el, index) => {
            let new_obj = [];
            let temp_data = [];
            new_obj["age"] = el.age;
            el.data.forEach((ele, i) => {
              if (temp_data[ele.cat_id]) {
                temp_data[ele.cat_id].sub_cat.push(ele.sub_cat);
              } else {
                temp_data[ele.cat_id] = { ...ele, sub_cat: [ele.sub_cat] };
              }
            });
            new_obj["data"] = temp_data;
            final_data.push(new_obj);
          });
          this.setState({ filterMilestoneData: final_data });
        }
      })
      .catch((err) => console.log(err));
  };

  loadData = () => {
    const staff_id = LS_SERVICE.get("staff_id");
    const hospital_id = LS_SERVICE.get("slot_hospital_id");
    const speciality_id = LS_SERVICE.get("speciality_id");
    const {
      match: { params },
    } = this.props;
    const { reference_type, reference_id } = params;
    const patient_data = this.state.patient;
    //console.log("patient>>", patient_data)
    if (patient_data != undefined && patient_data.dob != undefined && patient_data.dob != null) {
      this.setState({ birthDate: patient_data.dob });
    }
    const list = Axios.post(VaccinationList, qs.stringify({ hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null }));
    const item = Axios.post(VaccinationItemList, qs.stringify({ drug_class: PAEDRATICVACCINEID, hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null }));
    //const chart_list = Axios.post(VaccinationRecordList, qs.stringify({ patient_id: patient_data.patient_id }));
    const chart_list = Axios.post(VaccinationByRefence, qs.stringify({ reference_type: reference_type, reference_id: reference_id }));
    Axios.all([list, item, chart_list]).then(
      Axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseThree = responses[2];

        const confInfo = Object.values(responseOne.data);
        const item = Object.values(responseTwo.data);
        const chart_list = Object.values(responseThree.data);

        this.setState({
          itemData: item[1],
        });
        let record = this.state.record;
        if (confInfo[0] != undefined) {
          confInfo[1]?.map((val, i) => {
            let patient_dob = patient_data.dob;
            let dose_start_age = parseInt(val.startAge);
            let dose_start_duration = "days";
            if (val.startAgeDuration == "day") {
              dose_start_duration = "days";
            } else if (val.startAgeDuration == "week") {
              dose_start_duration = "weeks";
            } else if (val.startAgeDuration == "month") {
              dose_start_duration = "months";
            } else if (val.startAgeDuration == "year") {
              dose_start_duration = "years";
            }
            let due_on = moment(patient_dob).add(dose_start_age, dose_start_duration).format("DD/MM/YYYY");
            confInfo[1][i] = { ...val, duedate: due_on, givenon: "" };
            if (chart_list != undefined && chart_list.length > 0) {
              chart_list?.map((value, j) => {
                if (val.vaccinationId == value.vacc_id && val.scheduleDoseNo == value.dose_number) {
                  confInfo[1][i] = {
                    ...val,
                    batch: value.batch,
                    remark: value.remarks,
                    duedate: value.due_date,
                    givenon: value.given_on ? value.given_on : "",
                    outside: value.given_outside,
                    item: value.item_id,
                  };
                  //record[val.vaccinationId+'_'+val.scheduleDoseNo] = { id: val.vaccinationId, name: val.name, duedate: value.due_date,  givenon: value.given_on?value.given_on:'', start_age: val.startAge, duration: val.startAgeDuration, type: val.type, outside:value.given_outside, item:value.item_id, batch: value.batch, remark: value.remarks, dob: patient_dob, dose_number: val.scheduleDoseNo }
                }
              });
            }

            record[val.vaccinationId + "_" + val.scheduleDoseNo] = {
              id: val.vaccinationId,
              name: val.name,
              duedate: due_on,
              start_age: val.startAge,
              duration: val.startAgeDuration,
              type: val.type,
              outside: 0,
              batch: "",
              remark: "",
              dob: patient_dob,
              dose_number: val.scheduleDoseNo,
              item: "",
              givenon: "",
            };
          });
          //console.log("confInfo---", confInfo[1]);
          var vaccinationFinalArray = responseThree.data;
          vaccinationFinalArray.sort(function (a, b) {
            return a.dose_id - b.dose_id
          });
          this.setState({
            record,
            vaccinationData: vaccinationFinalArray,
          });
        }
      })
    );
  };

  componentDidUpdate() {
    const blockname = LS_SERVICE.get("blockname");
    if (blockname !== "undefined" && blockname !== "") {
      const blockId = document.getElementById(blockname);
      if (blockId !== null) {
        this.scrollTop$ = setTimeout(() => {
          window.scrollTo({ top: blockId.offsetTop, behavior: "smooth" });
          LS_SERVICE.set("blockname", "");
        }, 100);
      }
    }

    setTimeout(() => {
      jQueryLabels();
      FixedCta();
      let Elem = document.querySelectorAll(".subjective-field, .autoHeightText");
      Elem.forEach(function (el) {
        el.style.height = el.scrollHeight + "px";
      });
    }, 100);
  }

  componentWillUnmount() {
    const blockname = LS_SERVICE.get("blockname");
    if (this.scrollTop$ !== "" && blockname !== "") {
      clearTimeout(this.scrollTop$);
    }
  }

  goBack = (event) => {
    event.preventDefault();
    // this.props.history.goBack();
    const { patient } = this.state;
    const { qms_token_id, patient_id, hospital_id } = patient;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
    if (LS_SERVICE.get('soap_type') == 'single'){
      this.props.history.push({
        pathname: `/patient/soapss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
        state: { patient },
      });
    }
    else {
      this.props.history.push({
        pathname: `/patient/todays-visi/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
        state: { patient },
      });
    }
  };

  milestoneRedirect = (event) => {
    event.preventDefault();
    const { patient, sourceOfRerral } = this.state;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
    const { qms_token_id, patient_id, hospital_id } = patient;
    this.props.history.push({
      pathname: `/patient/todays-visit/objective/paediatrics/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
      state: { patient, sourceOfRerral },
    });
  };

  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  };

  zoomChart = (e) => {
    e.preventDefault();
    const { zoomClass } = this.state;
    this.setState({
      zoomClass: !this.state.zoomClass,
    });
  };

  handleSubjectiveOnChange = (e, id) => {
    const { name, value } = e.target;
    let { subjectiveData } = this.state;
    subjectiveData.map((subject, k) => {
      if (subject.subjective_title === name) {
        subjectiveData[k].description = value;
      }
    });

    this.setState({
      subjectiveData,
      [id + "Value"]: value,
    });
  };

  handleSubjectiveOnEdit = async (e, id) => {
    var subjectivesData = [
      { id: 0, description: this.state.chief_complaintValue },
      { id: 2, description: this.state.review_of_systemsValue },
    ];

    const { patient } = this.state;
    const { qms_token_id } = patient;

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      doctor_id: parseInt(LS_SERVICE.get('staff_id')),
      hospital_id: parseInt(LS_SERVICE.get('slot_hospital_id')),
      speciality_id: LS_SERVICE.get('speciality_id') ? LS_SERVICE.get('speciality_id') : '',
      USER_NAME: LS_SERVICE.get('staff_name'),
      patient_id: LS_SERVICE.get('call_patient_id'),
      from_button: null,
      subjectives: subjectivesData,
    };

    const submitPromise = await Axios.post(SUBJECTIVE_STORE_CHIEF_COMPLAINTS, PARAMS);

    if (submitPromise.data.status == "success") {
      this.setState(
        {
          [id + "Disabled"]: !this.state[id + "Disabled"],
        },
        () => {
          this.printButtonDisabled();
        }
      );
    }
  };

  onHandleSubjectiveStatus = (e, id) => {
    e.preventDefault();
    this.setState({ [id + "Disabled"]: !this.state[id + "Disabled"] });
  };

  handleInitialData = () => {
    const { patient, hospital_id } = this.state;
    // const hospital_id = LS_SERVICE.get("slot_hospital_id");
    const PARAMS = [
      {
        reference_type: LS_SERVICE.get('reference_type'),
        patient_id: patient.patient_id,
        reference_id: patient.qms_token_id,
        hospital_id,
        type: 'SS'
      },
      {
        reference_type: LS_SERVICE.get('reference_type'),
        reference_id: patient.qms_token_id,
      },
    ];

    PARAMS[0].store_id = LS_SERVICE.get("STORE_ID");
    PARAMS[0].facility_id = LS_SERVICE.get("FACILITY_ID");
    PARAMS[0].enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");

    const SOAP_DETAIL_REQ = Axios.post(SOAP_DETAILS, qs.stringify(PARAMS[0]));
    const PDF_CONFIG_VITAL_GRAPH_REQ = Axios.get(PDF_CONFIG_VITALS_GRAPH(PARAMS[1]));

    Axios.all([SOAP_DETAIL_REQ, PDF_CONFIG_VITAL_GRAPH_REQ]).then(
      Axios.spread(async (...res) => {
        const { details, field_status } = res[0].data;
        this.setState({ supportingFile: details.supportingMaterials });
        const { graph_settings } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { key_findings_graph_settings } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { growthchart_age, growthchart_type } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { fetal_params_graph_settings } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { clinical_note_ids } = res[1].data.data.details !== null ? res[1].data.data.details : "";

        if (details.subjectives === null) {
          details.subjectives = [
            {
              subjective_title: "Chief Complaints & HPI",
              description: "",
              subjective_id: 0,
            },
            {
              subjective_title: "Physical Examination",
              description: "",
              subjective_id: 2,
            },
          ];
        }

        field_status.subjective?.map((item, i) => {
          this.setState({
            [item.type + "Disabled"]: true,
            [item.type + "Value"]: details.subjectives[i] ? details.subjectives[i].description : "",
          });
        });

        details.constants.vitals_details = details.constants.vitalsDetails;
        details.constants.vitals_range = details.constants.vitalsRange;

        let assessmentTempArray = [];
        let addedAssessmentList = [];
        if (details.assessments && details.assessments.length > 0) {
          details.assessments.map((data, i) => {
            var tempAssessmentObj = {
              description: data?.diagnosisInfo?.diagnose_description,
              diagnosis_id: data?.diagnosisId,
              icd_code: data?.diagnosisInfo?.icd_code,
              id: data.id,
              image_comment: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : null,
              image_uploaded: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
              stage: data.stage,
              type: data.type,
              status: data.status,
              diagnosis_notes: data.diagnosisNotes,
            };
            assessmentTempArray.push(tempAssessmentObj);
            addedAssessmentList.push(tempAssessmentObj.diagnosis_id);
          });
        }

        let addedDrugsList = [];
        let drugsData = details.medicines ? details.medicines : [];

        if (drugsData && drugsData.length > 0) {
          drugsData.map((v, k) => {
            drugsData[k].prescription_drug_id = v.prescriptionDrugId;
            drugsData[k].prescription_drug_active = v.prescriptionDrugActive;
            delete drugsData[k].prescriptionDrugId;
            delete drugsData[k].prescriptionDrugActive;
            addedDrugsList.push(v.drug_id);
          });
        }

        let addedTestList = [];
        if (details.tests && details.tests.length > 0) {
          details.tests.map((v, k) => {
            addedTestList.push(v.test_id);
          });
        }

        this.setState({
          isEventBased: details?.prescriptionDrug?.isEventBased ? details?.prescriptionDrug?.isEventBased : 'N'
        })
        details.constants.vitals_range = details.constants.vitalsRange;
        let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
        //Finding emr_vital_group_id of emr_vital_group table using speciality id of doctor
        let selectedVitalGroup = Object.values(details.constants.vitalGroups).find((item) => {
          let item_arr = [];
          item_arr = item.speciality_id != null ? item.speciality_id.toString().split(",") : item_arr;
          // if (item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
          if (
            LS_SERVICE.get("speciality_id") &&
            item_arr.indexOf(LS_SERVICE.get("speciality_id")) &&
            item_arr.indexOf(LS_SERVICE.get("speciality_id").toString()) != -1
          )
            return true;
        });
        if (selectedVitalGroup != undefined) {
          seletctedSpecialityIdID = selectedVitalGroup.id;
        }
        let seletctedShowingVitalGroup = Object.values(details.constants.vitalGroups).find((item) => item.id == seletctedSpecialityIdID);

        const remainingVitals = details.constants.vitals_details.filter(
          (elem) => !seletctedShowingVitalGroup.vital_sign.find(({ vital_code }) => elem.vital_code === vital_code)
        );

        let obs_history_list = [];
        if (details.obsHistory != null && details.obsHistory.length > 0) {
          details.obsHistory.forEach(ele => {
            let obj = { id: ele.id, pregnancy_year: ele.year, mode_of_conception: ele.mode, mode_text: this.state.conception_options.filter(x => x.val == ele.mode)[0].label, pregnancy_type: ele.pregnancyType, previous_births: ele.previous_births, indication: ele.indication, remarks: ele.remarks };

            if (ele.pregnancyType == '2') {
              obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label + '(' + ele.previous_births.length + ')';
              let prev_del_types = [];
              let prev_outcomes = [];
              ele.previous_births.forEach(el => {
                if (el.deliveryType) {
                  let ob_del = { delivery_type: el.deliveryType, count: 1 };
                  let index_del = prev_del_types.findIndex(m => m.delivery_type === el.deliveryType);
                  if (index_del === -1) {
                    prev_del_types.push(ob_del)
                  } else {
                    prev_del_types[index_del].count = prev_del_types[index_del].count + 1;
                  }
                }

                let ob_outcome = { outcome: el.outcome, count: 1 };
                let index_outcome = prev_outcomes.findIndex(m => m.outcome === el.outcome);
                if (index_outcome === -1) {
                  prev_outcomes.push(ob_outcome)
                } else {
                  prev_outcomes[index_outcome].count = prev_outcomes[index_outcome].count + 1;
                }
              });
              let delivery_type_text_arr = [];
              prev_del_types.forEach(e => {
                delivery_type_text_arr.push(this.state.delivery_options.filter(x => x.val == e.delivery_type)[0].label + '(' + e.count + ')');
              });
              let outcome_text_arr = [];
              prev_outcomes.forEach(e => {
                outcome_text_arr.push(this.state.outcome_options.filter(x => x.val == e.outcome)[0].label + '(' + e.count + ')');
              });

              obj.delivery_type_text = delivery_type_text_arr.join(',');
              if (obj.delivery_type_text == '') {
                obj.delivery_type_text = '-';
              }
              obj.outcome_text = outcome_text_arr.join(',');
            } else {
              obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label;
              if (ele.previous_births[0].deliveryType) {
                obj.delivery_type_text = this.state.delivery_options.filter(x => x.val == ele.previous_births[0].deliveryType)[0].label;
              } else {
                obj.delivery_type_text = '-';
              }
              obj.outcome_text = this.state.outcome_options.filter(x => x.val == ele.previous_births[0].outcome)[0].label;
            }
            obs_history_list.push(obj);
          });
        }

        let gpale_data = details.obsGpale;
        if (gpale_data) {
          let obj = { gravidity: gpale_data.gravidity, parity: gpale_data.parity, abortion: gpale_data.abortion, live_birth: gpale_data.liveBirth, ectopic: gpale_data.ectopic };
          await this.setState({
            gpale_values: obj
          })
        }

        await this.setState({
          details,
          field_status,
          constants: details.constants,
          showingVitals: seletctedShowingVitalGroup.vital_sign,
          vitalsConstant: remainingVitals,
          subjectiveData: details.subjectives,
          subjectiveClinicalData: details.subjectives_clinical,
          assessmentData: assessmentTempArray,
          addedAssessmentList: addedAssessmentList,
          drugsData: drugsData,
          addedDrugsList: addedDrugsList,
          testData: details.tests,
          addedTestList: addedTestList,
          isAdmissionAdvisedPriority: details.admissionAdvisedPriority === null || details.admissionAdvisedPriority === 0 ? false : true,
          admissionChecked: details?.admissionAdvised !== null && details.admissionAdvised == 1 ? true : false,
          admissionComment: details?.admissionComment ?? "",
          physiotherapy_advised: details.physiotherapyAdvised ? 1 : 2,
          physioText: details.physiotherapyComment,
          admissionText: details.admissionComment,
          isDataFetching: false,
          chartGraphSettings: graph_settings != undefined && graph_settings !== null && graph_settings !== "" ? graph_settings : "",
          keyFindingsChartGraphSettings:
            key_findings_graph_settings != undefined && key_findings_graph_settings !== null && key_findings_graph_settings !== ""
              ? key_findings_graph_settings
              : "",
          chartGraphSettingsFp: fetal_params_graph_settings != undefined && fetal_params_graph_settings !== null && fetal_params_graph_settings !== "" ? fetal_params_graph_settings : "",
          growthChartSettings:
            growthchart_age != undefined && growthchart_type != undefined && growthchart_age !== null && growthchart_type !== null
              ? { growthchart_type, growthchart_age }
              : {},
          clinicalNoteIds: clinical_note_ids != undefined && clinical_note_ids !== null && clinical_note_ids !== "" ? clinical_note_ids.split(',') : [],
          // chartData: details.chartdata,
          chartData: details.chartdatanew,
          chartDataLoaded: true,
          chartDataFp: details.chartdata_fp,
          chartDataLoadedFp: true,
          bloodSugarChartData: details.bloodSugarData,
          vitalsRangeWithId: details.constants.vitalsRange,
          KeyFindingsConstants: details.constants.findingGroupData ? Object.values(details.constants.findingGroupData) : [],
          obsHistoryList: obs_history_list,
          phyExaminationList: details.phyExamination,
          phyExaminationFpList: details.phyExaminationFp,
          current_pregnancy: details.obsCurrentPreg,
          latest_menst_hist: details.menstHistoryLatest
        });
        if (this.state.keyFindingsFiltersLoaded) {
          this.updateKeyFindingsOrder();
        }
        if (this.state.growthChartSettings.growthchart_type && this.state.growthChartSettings.growthchart_age) {
          this.setState({
            gender: this.state.patientData.gender == "Female" ? "girls" : "boys",
          });
          if (this.state.patientData.cmonthsage != undefined && this.state.patientData.cmonthsage < 24 && this.state.patientData.cmonthsage >= 0) {
            await this.setState((prevState) => {
              let prevItems = { ...prevState.patientData };
              prevItems.monthsage = prevItems.cmonthsage;
              return { patientData: prevItems };
            });
          }
          await this.handleVitalGraphData();
          await this.setGrowthChartData();
        }
      })
    );
  };

  getListValue(selectedValue, OptionObj = null) {
    if (selectedValue == 0 || selectedValue === null) {
      return "-";
    } else if (OptionObj) {
      return OptionObj[selectedValue];
    } else {
      return selectedValue;
    }
  }

  //MENSTRUAL_HISTORY_GRAPH
  menstrualGraph = async () => {
    const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;

    const PARAMS = {
      patient_id: patient_id,
      doctor_id: doctor_id,
      hospital_id: hospital_id,
      reference_id: reference_id,
      reference_type: 0,
    }

    const result = await Axios.get(MENSTRUAL_HISTORY_GRAPH(PARAMS));
    if (result?.data?.data && result?.data?.data.chartdata) {
      this.setState({
        menstrualHistoryChartData: result?.data?.data.chartdata,
        menstrualHistoryChartDataLoaded: true
      });
    }
  }

  async setGrowthChartData() {
    let paedVitalsConstant = this.state.constants
      ? this.state.constants.vitalGroups["VG002"]
        ? this.state.constants.vitalGroups["VG002"].vital_sign
        : this.state.constants.vitals_details
      : [];

    let type = this.state.growthChartSettings.growthchart_type;
    let age = this.state.growthChartSettings.growthchart_age;
    let data = [];
    let otheroptions = [];
    if (type == "CDC") {
      type = CDC;
    } else if (type == "WHO") {
      type = WHO;
    } else if (type == "IAP") {
      type = IAP;
    } else if (type == "WHOIAP") {
      type = WHOIAP;
    } else {
      type = null;
    }
    let gendertype = this.state.gender;
    if (type && type[gendertype]) {
      const findex = type[gendertype].findIndex((x) => x.age == age);
      if (findex !== -1) {
        let options = {};
        options.XLeft = type[gendertype][findex].XLeft;
        options.YBottom = type[gendertype][findex].YBottom;
        options.ageLabel = type[gendertype][findex].ageLabel;

        let ageToConsider;
        if (this.state.patientData.cdob && this.state.patientData.monthsage == this.state.patientData.cmonthsage) {
          if (options.ageLabel == "months") {
            if (this.state.patientData.cmonthsage >= 12) {
              ageToConsider = this.state.patientData.cmonthsage + 1;
            } else {
              ageToConsider = 12 + 1;
            }
          } else {
            if (this.state.patientData.cmonthsage >= 12) {
              ageToConsider = (this.state.patientData.cmonthsage / 12).toFixed(2);
            } else {
              ageToConsider = 1;
            }
          }
        } else {
          if (options.ageLabel == "months") {
            if (this.state.patientData.monthsage >= 12) {
              ageToConsider = this.state.patientData.monthsage + 1;
            } else {
              ageToConsider = 12 + 1;
            }
          } else {
            if (this.state.patientData.monthsage >= 12) {
              ageToConsider = (this.state.patientData.monthsage / 12).toFixed(2);
            } else {
              ageToConsider = 1;
            }
          }
        }
        if (type[gendertype][findex].datasets.height) {
          let datasets = [];
          type[gendertype][findex].datasets.height.forEach((ele, i) => {
            let data2 = [];
            try {
              ele.data.forEach((ele2, i) => {
                if (ele2.x <= ageToConsider) {
                  data2.push(ele2);
                } else {
                  if (ele.data[i]) {
                    data2.push(ele.data[i]);
                  }
                  throw new Error(); // break
                }
              });
            } catch (e) { }
            let obj = {
              type: "line",
              label: ele.label,
              label2: ele.label2,
              data: data2,
              labeltype: ele.labeltype,
              group: "Height for age",
              xAxisID: "bottom",
              yAxisID: "L",
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0,
            };
            if (ele.labeltype == "97") {
              otheroptions.push({
                ...options,
                label: "Height for age",
                YTop: Math.ceil(obj.data[obj.data.length - 1].y + 10),
                XRight: obj.data[obj.data.length - 1].x,
              });
            }
            datasets.push(obj);
          });
          data.push({ datasets: datasets });
        }

        if (type[gendertype][findex].datasets.weight) {
          let datasets = [];
          type[gendertype][findex].datasets.weight.forEach((ele, i) => {
            let data2 = [];
            try {
              ele.data.forEach((ele2, i) => {
                if (ele2.x <= ageToConsider) {
                  data2.push(ele2);
                } else {
                  if (ele.data[i]) {
                    data2.push(ele.data[i]);
                  }
                  throw new Error(); // break
                }
              });
            } catch (e) { }
            let obj = {
              type: "line",
              label: ele.label,
              label2: ele.label2,
              data: data2,
              labeltype: ele.labeltype,
              group: "Weight for age",
              xAxisID: "bottom",
              yAxisID: "L",
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0,
            };
            if (ele.labeltype == "97") {
              otheroptions.push({
                ...options,
                label: "Weight for age",
                YTop: Math.ceil(obj.data[obj.data.length - 1].y + 10),
                XRight: obj.data[obj.data.length - 1].x,
              });
            }
            datasets.push(obj);
          });
          data.push({ datasets: datasets });
        }

        if (type[gendertype][findex].datasets.headcc) {
          let datasets = [];
          type[gendertype][findex].datasets.headcc.forEach((ele, i) => {
            let data2 = [];
            try {
              ele.data.forEach((ele2, i) => {
                if (ele2.x <= ageToConsider) {
                  data2.push(ele2);
                } else {
                  if (ele.data[i]) {
                    data2.push(ele.data[i]);
                  }
                  throw new Error(); // break
                }
              });
            } catch (e) { }
            let obj = {
              type: "line",
              label: ele.label,
              label2: ele.label2,
              data: data2,
              labeltype: ele.labeltype,
              group: "Head CC for age",
              xAxisID: "bottom",
              yAxisID: "L",
              borderWidth: 1,
              borderColor: ele.borderColor,
              fill: false,
              pointRadius: 0,
            };
            if (ele.labeltype == "97") {
              otheroptions.push({
                ...options,
                label: "Head CC for age",
                YTop: Math.ceil(obj.data[obj.data.length - 1].y + 10),
                XRight: obj.data[obj.data.length - 1].x,
              });
            }
            datasets.push(obj);
          });
          data.push({ datasets: datasets });
        }

        let vitals = [];
        paedVitalsConstant.forEach((data, i) => {
          let obj = {};
          obj.id = data.id;
          obj.display_name = data.display_name;
          obj.values = [];
          this.state.growthChartData.forEach((d, j) => {
            if (d[obj.id] && d[obj.id] != undefined && d[obj.id] != "") {
              let findind;
              let findvalue;
              if (type[gendertype][findex].ageLabel == "months") {
                if (this.state.patientData.cdob && this.state.patientData.monthsage == this.state.patientData.cmonthsage) {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.cdob), "months", true).toFixed(1);
                } else {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.dob), "months", true).toFixed(1);
                }
              }
              if (type[gendertype][findex].ageLabel == "years") {
                if (this.state.patientData.cdob && this.state.patientData.monthsage == this.state.patientData.cmonthsage) {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.cdob), "years", true).toFixed(1);
                } else {
                  findvalue = moment(new Date(d.date)).diff(new Date(this.state.patientData.dob), "years", true).toFixed(1);
                }
              }
              findind = obj.values.findIndex((x) => x.age == findvalue);
              if (findind === -1) {
                let objvalues = {};
                objvalues.val = d[obj.id];
                objvalues.date = d.date;
                objvalues.age = findvalue;
                objvalues.reference_id = d.reference_id;
                obj.values.push(objvalues);
              }
            }
          });
          vitals.push(obj);
        });
        vitals.forEach((dat, i) => {
          let vitals_max_value = 0;
          let scatterdata = [];
          dat.values.forEach((d, j) => {
            if (parseInt(d.val) > vitals_max_value) {
              vitals_max_value = parseInt(d.val);
            }
            scatterdata.push({ x: d.age, y: d.val, z: d.reference_id });
          });
          let scatterobj = {
            type: "scatter",
            label: dat.display_name,
            data: scatterdata,
            xAxisID: "bottom",
            yAxisID: "L",
            backgroundColor: "rgba(255, 0, 0, 1)",
            borderWidth: 1,
            radius: 4,
          };
          if (dat.id == "V001" && type[gendertype][findex].datasets.height && data[0]) {
            let ytop = Math.max(otheroptions[0].YTop, vitals_max_value);
            let divider = 5;
            if (ytop >= 100) {
              divider = 10;
            }
            let remainder = ytop % divider;
            ytop = ytop + (divider - remainder);
            otheroptions[0].YTop = ytop;
            data[0].datasets.push(scatterobj);
          }

          if (dat.id == "V002" && type[gendertype][findex].datasets.weight && data[1]) {
            let ytop = Math.max(otheroptions[1].YTop, vitals_max_value);
            let divider = 5;
            if (ytop >= 100) {
              divider = 10;
            }
            let remainder = ytop % divider;
            ytop = ytop + (divider - remainder);
            otheroptions[1].YTop = ytop;
            data[1].datasets.push(scatterobj);
          }
          if (dat.id == "V016" && type[gendertype][findex].datasets.headcc && data[2]) {
            let ytop = Math.max(otheroptions[2].YTop, vitals_max_value);
            let divider = 5;
            if (ytop >= 100) {
              divider = 10;
            }
            let remainder = ytop % divider;
            ytop = ytop + (divider - remainder);
            otheroptions[2].YTop = ytop;
            data[2].datasets.push(scatterobj);
          }
        });
      }

      await this.setState({
        growthData: data,
        otheroptions,
        showGrowthChart: true,
      });
    }
  }

  handleVitalGraphData = async (_) => {
    const { patient_id } = this.state.patient;

    const vitalGraphPromise = await Axios.get(PATIENT_VITALS_ALL({ patient_id }));

    if (vitalGraphPromise.data.status) {
      const { data } = vitalGraphPromise;
      this.setState({
        growthChartData: Object.values(data.data.details.visitVitals),
      });
    }
  };

  getPatientData = (_) => {
    const {
      match: { params },
    } = this.props;
    const { patient_id } = params;
    Axios.get(PATIENT_DATA({ patient_id }))
      .then((success) => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken ? success.data.data.results.qmsToken.qms_token_id : null,
        };

        this.setState(
          {
            patient,
          },
          () => {
            this.loadData();
          }
        );
      })
      .catch((err) => console.log(err));
  };

  getAddendumData = () => {
    const { patient, hospital_id } = this.state;
    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: LS_SERVICE.get('reference_type'),
      hospital_id: patient.hospital_id,
      patient_id: patient.patient_id,
    };

    Axios.post(GET_ADDENDUM, qs.stringify(PARAMS))
      .then((success) => {
        let { visit } = success.data;
        this.setState({
          visitAddendum: visit.rows.length > 0 ? visit.rows[0].addendum : "",
        });
      })
      .catch((err) => console.log(err.response));
  };

  updateAssessmentData = async (data) => {
    let assessmentData = this.state.assessmentData;
    let addedAssessmentList = this.state.addedAssessmentList;
    /* data.selected=true;
         let addedAssessmentList=[];*/
    var index = data.i;
    if (index == -1) index = assessmentData == null ? 0 : assessmentData.length;
    else data.assessment_id = data.id;
    assessmentData[index] = data;
    addedAssessmentList = assessmentData.map((v, k) => {
      return v.diagnosis_id;
    });

    /*if (data.i != -1) {
            data.assessment_id = data.id
            assessmentData[data.i] = data;
        }
        else {
            assessmentData[assessmentData.length] = data
            addedAssessmentList.push(data.diagnosis_id);
        }*/
    data.reference_type = LS_SERVICE.get('reference_type');
    data.reference_id = this.state.patient.qms_token_id;
    data.encounter_id = parseInt(LS_SERVICE.get('PATIENT_ENCOUNTER_ID'));

    const assFormPromise = await Axios.post(ASSESSMENT_STORE, qs.stringify(data));
    this.setState({ editData: null, editIndex: -1, showAssessmentModal: false, assessmentData, addedAssessmentList });
    if (assFormPromise.data) {
      this.handleInitialData();
      notification.success({
        message: assFormPromise.data.message,
        placement: "topRight",
      });
      /*this.setState({
                formModeEdit: false,
                isProcessing: false
            })*/
    }
  };

  clearEdit = () => {
    this.setState({
      editData: null,
      editIndex: -1,
    });
  };

  polyPharmacyWarningShown = () => {
    this.setState({
      polyPharmacyWarningShown: true,
    });
  };

  updateMedicineData = (data, showTaper) => {
    data.selected = true;
    let drugsData = this.state.drugsData;
    let addedDrugsList = [];
    var index = data.i;
    if (index == -1) index = drugsData == null ? 0 : drugsData.length;
    drugsData[index] = data;

    addedDrugsList = drugsData.map((v, k) => {
      return v.drug_id;
    });
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.patient.qms_token_id,
      patient_id: this.state.patient.patient_id,
      medicine_data: [data],
    };
    axios
      .post(PRESCRIPTION_STORE, qs.stringify(PARAMS))
      .then((success) => {
        this.handleInitialData();
        this.setState(
          {
            drugsData,
            addedDrugsList,
            editData: null,
            editIndex: -1,
          },
          () => {
            if (!showTaper) this.handleModalPopup(null, "showMedicineModal");
          }
        );
      })
      .catch((err) => console.log(err));
  };

  updateTestData = async (data) => {
    data.urgency = data.urgency && data.urgency != null ? data.urgency : 49;
    let testData = this.state.testData;
    let addedTestList = this.state.addedTestList;
    /* data.selected=true;
         let addedAssessmentList=[];*/
    var index = data.i;
    if (index == -1) index = testData == null ? 0 : testData.length;
    testData[index] = data;
    addedTestList = testData.map((v, k) => {
      return v.test_id;
    });
    /*if (data.i != -1) {
            testData[data.i] = data;
        }
        else {
            testData[testData.length] = data
            addedTestList.push(data.test_id);
        }*/

    const PARAMS = {
      tests_data: [data],
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.patient.qms_token_id,
      patient_id: this.state.patient.patient_id,
    };

    const testFormPromise = await Axios.post(PLAN_TEST_ADVICED_STORE, qs.stringify(PARAMS));
    this.setState({ editData: null, editIndex: -1, showTestModal: false, testData, addedTestList });
    if (testFormPromise.data) {
      this.handleInitialData();
      notification.success({
        message: testFormPromise.data.message,
        placement: "topRight",
      });
      /*this.setState({
                formModeEdit: false,
                isProcessing: false
            })*/
    }
  };

  /* handleEdit = (data, i) =>{
         this.setState({
             actionText: "Edit",
             editData:this.state.assessmentData[i],
             editIndex:i
         })
         AntSelectLabels();
     }*/

  /*MedicineOndelete = (val) => {
        axios.get(DELETE_MEDICINE({ id: val }))
            .then(success => {
                this.getVisitDetails();
            })
            .catch(err => console.log(err))

    }

    handleMedicineData = data => {
        let { allViewVisitData, isMedicineEdit } = this.state;

        const PARAMS = {
            reference_type: LS_SERVICE.get('reference_type'),
            reference_id: this.state.patient.qms_token_id,
            patient_id: this.state.patient.patient_id,
            medicine_data: [data],
        };
        axios.post(PRESCRIPTION_STORE, qs.stringify(PARAMS))
            .then(success => {
                this.getVisitDetails();
            })
            .catch(err => console.log(err))


        this.setState({
            allViewVisitData,
            resetMedicalForm: true,
            isMedicineEdit: false,
            updateMedicineModal: false,
            // medicineEditData: null
        }, () => {
            // this.handleOnSelectEachItem(null, 'drug_' + i + '_' + drug_id, 'drug', data.drug_generic[0].generic_id);
            this.handleModalPopup(null, 'addMedicinesModal');
        });
    }*/

  /*assessmentDataInitialFnc() {
        let { assessmentData } = this.state;
        let templateAssessments=[];
        assessmentData.forEach((element, i) => {
            templateAssessments.push({
                diagnosis_id: element.diagnosis_id,
                text: element.description,
                code: element.icd_code,
                type: element.type,
                stage: element.stage,
                status: element.status,
                image_path: element.image_uploaded,
                image_comment: element.image_comment,
                diagnosis_notes: element.diagnosis_notes
            })
        });
        this.setState({templateAssessments});
    }
    TestDataInitialFnc() {
        let { testData } = this.state;
        let templateTests=[];
        testData.forEach((element, i) => {
            delete element.is_diabetic
            delete element.is_metformin
            delete element.risk
            templateTests.push(element);
        })
        this.tests_data = testData;

        this.setState({templateAssessments});
    }
    drugDataInitialFnc() {
        let { drugsData } = this.state;

        let templateDrugs=[];
        drugsData.forEach((element, i) => {
            delete element.prescriptionId;
            delete element.prescriptionDrugId;
            delete element.drug_generic;
            templateDrugs.push(element);
        })
        this.drugs_data = drugsData;
    }
    instructionsInitialFnc() {
        const { details } = this.state;
        if (details.instructions !== null) {
            details.instructions.forEach((element, i) => {
                this.instructions_data.push({
                    id: element.instruction_id,
                    instruction: element.instruction
                })
            })
        }
    }*/

  templateSubmit = () => {
    this.setState({ isModal: false });
    let { assessmentData, drugsData, testData, details } = this.state;
    let templateAssessments = [];
    assessmentData.forEach((element, i) => {
      templateAssessments.push({
        diagnosis_id: element.diagnosis_id,
        text: element.description,
        code: element.icd_code,
        type: element.type,
        stage: element.stage,
        status: element.status,
        image_path: element.image_uploaded,
        image_comment: element.image_comment,
        diagnosis_notes: element.diagnosis_notes,
      });
    });

    let templateDrugs = [];
    var formulary_medicines = drugsData.filter(function (el) {
      return el.medicineType == 'F'
    });
    formulary_medicines.forEach((element, i) => {
      delete element.prescriptionId;
      delete element.prescriptionDrugId;
      delete element.prescription_drug_id;
      delete element.drug_generic;
      delete element.stopped_by;
      delete element.doctor_name;
      delete element.updated_at;
      delete element.medicineType;
      if (element.active == 'Y' && element.prescription_drug_active == 'Y') {
        templateDrugs.push(element);
      }
      delete element.prescription_drug_active;
      delete element.active;
    });

    let templateTests = [];
    var formulary_tests = testData.filter(function (el) {
      return el.test_type == 'F'
    });
    formulary_tests.forEach((element, i) => {
      delete element.is_diabetic;
      delete element.is_metformin;
      delete element.risk;
      delete element.test_type;
      if (element.test_id.toString().length < 10) {
        templateTests.push(element);
      }
    });

    let templateInstructions = [];
    if (details.instructions !== null) {
      details.instructions.forEach((element, i) => {
        templateInstructions.push({
          id: element.instruction_id,
          instruction: element.instruction,
        });
      });
    }

    let templateMaterials = [];
    if (details.supportingMaterials !== null) {
      details.supportingMaterials.forEach((element, i) => {
        templateMaterials.push({
          id: element.material_id,
          fileName: element.file_name,
          fileUrl: element.file_url
        });
      });
    }

    var params = {
      name: this.name,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      owner_id: LS_SERVICE.get("staff_id"),
      logined_id: LS_SERVICE.get("staff_id"),
      diagnosis_data: templateAssessments,
      drugs_data: formulary_medicines,
      tests_data: templateTests,
      instruction: templateInstructions.length == 0 ? [] : templateInstructions,
      supporting_material_data: templateMaterials,
      login_role: (LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin) ? '' : 'doctor',
      hospital_master: 'N',
    };

    axios
      .post(CREATE_TEMPLATE, qs.stringify(params))
      .then((success) => {
        if (success.data.status) {
          notification.success({
            message: success.data.message,
            placement: "topRight",
          });
        } else {
          notification.error({
            message: success.data.message,
            placement: "topRight",
          });
        }
      })
      .catch((err) =>


        console.log(err));
    /*axios.post(CREATE_TEMPLATE, qs.stringify({
            name: this.name,
            diagnosis_data: templateAssessments,
            drugs_data: templateDrugs,
            tests_data: templateTests,
            instruction: templateInstructions.length == 0 ? null : templateInstructions
        })).then(success => {
            notification.success({
                message: success.data.message,
                placement: 'topRight'
            });
        }).catch(err => console.log(err))*/
  };
  handleSaveTemplate = () => {
    const { details } = this.state;
    var formulary_medicines = details?.medicines?.filter(function (el) {
      return el.medicineType == 'F'
    });
    var formulary_tests = details?.tests?.filter(function (el) {
      return el.test_type == 'F'
    });
    if (details.assessments?.length > 0 && (formulary_medicines?.length > 0 || formulary_tests?.length > 0)) {
      this.setState((prev) => ({
        isModal: !prev.isModal,
      }));
    } else {
      this.setState((prev) => ({
        unfulifilledModal: !prev.unfulifilledModal,
      }));
    }
  };

  handleReferralRoute = (e) => {
    e.preventDefault();
    const { patient, sourceOfRerral } = this.state;
    const { hospital_id, patient_id, qms_token_id } = patient;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
    this.props.history.push({
      pathname: `/patient/todays-visit/referral/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
      state: { patient, sourceOfRerral },
    });
  };

  // handleToggleAdmission = (e) => {
  //     const { patient } = this.state

  //     const PARAMS = {
  //         reference_id: patient.qms_token_id,
  //         reference_type: LS_SERVICE.get('reference_type'),
  //         admission_advised: 1,
  //         priority: +e
  //     }

  //     // debugger

  //     Axios.post(ADMISSION_UPDATE, qs.stringify(PARAMS))
  //         .then(res => {
  //             this.setState({
  //                 isAdmissionAdvisedPriority: e
  //             })
  //         })
  // }

  handleCompleteAppointment = () => {
    const { patient, hospital_id, isPracticing } = this.state;

    // Store Update chief complaint
    this.handleSubjectiveOnEdit('', "chief_complaint");

    if (!this.state.allowEdit) {
      this.props.history.push({
        pathname: `/patient-queue/${hospital_id}`,
      });
      return;
    }

    if (!isPracticing) {
      this.handleModalPopup(null, "isPracticingModal");
      return;
    }

    this.setState({
      isPrintLoader: true,
    });
    var ipd_slots = LS_SERVICE.get("ipd_slots")
    var commonfacilityName = ipd_slots.find(item => item?.hospitalId == LS_SERVICE.get("FACILITY_ID"));
    const params = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: patient.qms_token_id,
      patient_id: patient.patient_id,
      hospital_id,
      print_pdf: 1,
      type: 'SS',
      store_id: LS_SERVICE.get("STORE_ID"),
      facility_id: commonfacilityName?.facilityId || LS_SERVICE.get("FACILITY_ID"),
      facility_name: commonfacilityName?.facilityName || LS_SERVICE.get("FACILITY_NAME"),
      enterprise_id: LS_SERVICE.get("ENTERPRISE_ID"),
      enterprise_name: LS_SERVICE.get("ENTERPRISE_NAME"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      fromfacility: commonfacilityName?.hospitalName || LS_SERVICE.get("FACILITY_NAME"),
      fromfacilityid: commonfacilityName?.hospitalId || LS_SERVICE.get("FACILITY_ID"),
      USER_NAME: LS_SERVICE.get('staff_name'),
    };

    Axios.post(COMPLETE_APPOINTEMENT, qs.stringify(params)).then((res) => {
      if (this.state?.details?.qms_details?.source == "qms") {
        let params = { status: 'complete', token_id: this.state?.details?.qms_details?.token_id, hospital_id: hospital_id }
        Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(response => {
          console.log(response)
        })
      }

      this.setState({
        isPrintLoader: false,
      });

      const browser = Bowser.parse(window.navigator.userAgent).browser.name;
      if (browser == "Safari") {
        this.props.history.push({
          pathname: `/patient-queue/${hospital_id}`,
          state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
        });
      } else {
        this.props.history.push({
          pathname: `/patient-queue/${hospital_id}?reference=${patient.qms_token_id}`,
          state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
        });
      }
    });
  };

  handleAdmissionAdvicedCheckbox = (e) => {
    let { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      this.admissionSave
    );
  };

  handleRadioChange = (e, callBackFunName = "") => {
    e.preventDefault();
    const { isPracticing } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, "isPracticingModal");
      return;
    }

    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (callBackFunName !== "") {
          this[callBackFunName]();
        }
      }
    );

    if (e.target.value === 2) {
      const { patient } = this.state;
      const PARAMS = {
        reference_id: patient.qms_token_id,
        reference_type: LS_SERVICE.get('reference_type'),
        physiotherapy_advised: 0,
        physiotherapy_comment: "",
      };
      Axios.post(PHYSIO_UPADTE, qs.stringify(PARAMS)).then((res) => console.log(res.data));

      this.setState({ physioText: "" });
    }
  };

  physiotherapySave = (e) => {
    const { patient, physioText, physiotherapy_advised } = this.state;
    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: LS_SERVICE.get('reference_type'),
      physiotherapy_advised,
      physiotherapy_comment: physioText,
    };
    Axios.post(PHYSIO_UPADTE, qs.stringify(PARAMS)).then((res) => console.log(res.data));
  };

  onHandleAdmissionAdvised = (e) => {
    const { isPracticing, admissionChecked, isAdmissionAdvisedPriority } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, "isPracticingModal");
      return;
    }

    this.setState(
      {
        admissionChecked: !admissionChecked,
        isAdmissionAdvisedPriority: admissionChecked ? isAdmissionAdvisedPriority : false,
        // isAdmissionAdvisedPriority: true
      },
      () => {
        this.admissionSave();
      }
    );

    /* const PARAMS = {
             reference_id: patient.qms_token_id,
             reference_type: LS_SERVICE.get('reference_type'),
             admission_advised: admissionChecked ? 0 : 1,
             // admission_comment: ''
         }
         Axios.post(MYHC_APPTYPE === WOODLANDS ? ADMIS_UPADTE : ADMISSION_UPDATE, qs.stringify(PARAMS))
             .then(res => console.log(res.data))*/
  };

  handleToggleAdmission = (e) => {
    this.setState(
      {
        isAdmissionAdvisedPriority: e,
      },
      () => {
        this.admissionSave();
      }
    );

    /* const PARAMS = {
             reference_id: patient.qms_token_id,
             reference_type: LS_SERVICE.get('reference_type'),
             admission_advised: 1,
             priority: isAdmissionAdvisedPriority ? 1 : 0
         }
         Axios.post(MYHC_APPTYPE === WOODLANDS ? ADMIS_UPADTE : ADMISSION_UPDATE, qs.stringify(PARAMS))
             .then(res => console.log(res.data))*/
  };
  handleAdmissionAdvicedComment = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.admissionSave();
      }
    );
  };

  admissionSave = () => {
    const { patient, admissionChecked, isAdmissionAdvisedPriority, admissionComment } = this.state;
    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: LS_SERVICE.get('reference_type'),
      admission_advised: admissionChecked ? 1 : 0,
      admission_comment: admissionComment,
      priority: isAdmissionAdvisedPriority ? 1 : 0,
    };
    if (!admissionChecked) {
      this.setState({
        admissionComment: "",
      });
      PARAMS.admission_comment = "";
    }
    Axios.post(MYHC_APPTYPE === WOODLANDS ? ADMIS_UPADTE : ADMISSION_UPDATE, qs.stringify(PARAMS)).then((res) => console.log(res.data));
  };

  handleVisitAddendum = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { patient, visitAddendum } = this.state;
        const PARAMS = {
          reference_id: patient.qms_token_id,
          reference_type: LS_SERVICE.get('reference_type'),
          addendum_comment: visitAddendum,
          hospital_id: patient.hospital_id,
          patient_id: patient.patient_id,
        };
        Axios.post(STORE_ADDENDUM, qs.stringify(PARAMS)).then((res) => console.log(res.data));
      }
    );
  };

  onChangeTemplateName = (e) => {
    this.name = e.target.value;
    if (e.target.value.length > 0) {
      this.setState({ savedisabled: false });
    }
  };

  printButtonDisabled = () => {
    const { details, chief_complaintValue } = this.state;
    let btnDisable = false;
    // const subjectiveArr = [];

    const field_arr = [];
    Object.values(details.constants.subjectives).forEach((sf) => {
      if (sf.required) field_arr.push(sf.input_name);
    });

    const field_validation = new Array(field_arr.length).fill(false);

    field_arr.forEach((fa, idx) => {
      field_validation[idx] = (idx === 0) ? this.state[fa + "Value"] == null || this.state[fa + "Value"] == "" : this.state[fa + "Value"] == null || this.state[fa + "Value"].trim() == "";
    });

    if (field_validation.some((fv) => fv == true)) btnDisable = true;

    if (this.state.isPrintLoader) btnDisable = true;

    if (details.qms_details.token_date > moment().format('YYYY-MM-DD')) btnDisable = true;

    if (chief_complaintValue != null && chief_complaintValue.length > 0) { btnDisable = false } else { btnDisable = true };

    return btnDisable;

    // return subjectiveArr.some(sub => sub);
  };

  handleStatusSwitch = async (e, i, data) => {
    const { id, status } = data;
    let { assessmentData } = this.state;

    const switchPromise = await Axios.get(ASSESSMENT_STATUS_UPDATE({ id, status: e ? 0 : 1 }));

    if (switchPromise) {
      // this.handleAssessmentData();
      assessmentData[i].status = e ? 0 : 1;
      this.setState({ assessmentData });

      const { data } = switchPromise;

      notification.success({
        message: data.message,
        placement: "topRight",
      });
    }
  };

  /*MedcineListLoad() {
        let medicineRows = {};
        for (const medInfo of this.state.details.medicines) {
            let medKey = medInfo['drug_id'];
            medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + 1) : 1;
        }

        let iteratedDrugs = [];
        let mederowCount = 1;
        let medicine_remarks = '';

        return this.state.details.medicines.map((medicine, i) => {
            let medexists;
            if (!iteratedDrugs.includes(medicine.drug_id)) {
                medexists = false;
                mederowCount = 1;
                medicine_remarks = medicine.medicine_remarks
                iteratedDrugs.push(medicine.drug_id);
            } else {
                medexists = true;
                mederowCount = mederowCount + 1;
                if (medicine_remarks === '') {
                    medicine_remarks = medicine.medicine_remarks;
                }
            }
            const drugRowSpan = medicineRows[medicine.drug_id] !== undefined && medicineRows[medicine.drug_id] > 1 ? medicineRows[medicine.drug_id] : 1;


            let htmlData = (
                <>
                    <tr key={i}>
                        {drugRowSpan > 1 && !medexists ? <td rowSpan={drugRowSpan}>{medicine.drug_name}</td> : null}
                        {drugRowSpan === 1 && !medexists ? <td>{medicine.drug_name}</td> : null}
                        <td>{medicine.strength}</td>
                        <td>{DRUG_URGENCY[medicine.urgency]}</td>
                        <td>{DOSAGE_VALUE[medicine.dosage]} {medicine.dosage_form_text}</td>
                        <td>{DRUG_FREQUENCY[medicine.drug_frequency]}</td>
                        <td>{medicine.schedule_name}</td>
                        <td>{DRUG_ROUTE[medicine.route]}</td>
                        <td>{DRUG_INSTRUCTION[medicine.instruction]}</td>
                        <td>{medicine.number_of_days}</td>
                        <td>
                            <a className="nostyle-link mr-2" onClick={e => this.handleModalPopup(e, 'addMedicinesModal', medicine, 'editMedicines', medicine.drug_id)}><i className="icon-edit" /></a>
                            <a className="nostyle-link remove-list" onClick={() => { this.setState({ deleteMedicineId: medicine.prescriptionDrugId }); this.showAlertMModal() }}><i className="icon_delete mr-2" /></a>
                        </td>
                    </tr>
                    {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                        <tr>
                            <td colspan="10" className="text-center py-2"><span className="text-muted">Remarks</span>&nbsp;{medicine_remarks}</td>
                        </tr>
                        : null}
                </>
            )
            return htmlData;
        });
    }*/

  // For Modal popup
  handleModalPopup = (e, popupName, data = null, actionType = "", objId = "", patientDocs, idx) => {
    if (e !== null) e.preventDefault();
    this.setState(
      {
        [popupName]: !this.state[popupName],
      },
      () => {
        const { viewDocumentModal } = this.state;

        this.setState(
          {
            actionText: "Add",
            editData: null,
            editIndex: -1,
          },
          () => {
            // const { addAssessmentModal, addMedicinesModal, addInstructionModal, deleteConfirmModal } = this.state;
            // if (addAssessmentModal) {

            // }
            const { deleteConfirmModal, deleteAssessmentModal, showAssessmentModal, showMedicineModal, showInstructionModal, showTestModal } =
              this.state;

            if (showAssessmentModal) {
              if (actionType === "editAssessment") {
                this.setState({
                  actionText: "Edit",
                  editData: this.state.assessmentData[objId],
                  editIndex: objId,
                });
                AntSelectLabels();
              }
            }

            if (deleteAssessmentModal) {
              if (data !== null) {
                this.setState({
                  delete_text: data.data.description,
                  delete_data: data.data,
                });
              }
            }
            if (showMedicineModal) {
              if (actionType === "editMedicine") {
                this.setState({
                  actionText: "Edit",
                  editData: this.state.drugsData[objId],
                  editIndex: objId,
                });
              }
            }

            if (deleteConfirmModal) {
              // this.setState({ actionType, objId });
              // For Assessment
              if (actionType === "deleteAssessment") {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: "Are you sure you want to delete assessment?" });
              }

              // For Medicines
              if (actionType === "deleteMedicine") {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: "Are you sure you want to delete medicine?" });
              }

              // For Tests
              if (actionType === "deleteTest") {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: "Are you sure you want to delete test?" });
              }

              // For Supporting Materials
              if (actionType === "deleteSupportingMaterials") {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: "Are you sure you want to delete file?", deleteItemIndex: idx });
                this.deleteButton.current.focus();
              }
            }
          }
        );
      }
    );
  };

  handleOnItemDelete = async (e, actionType, objId) => {
    e.preventDefault();
    var stateVariable = null;
    var stateAddedVariable = null;
    var propKey = null;
    var deleteUrl = null;
    // For Assessment
    if (actionType === "deleteAssessment") {
      stateVariable = "assessmentData";
      stateAddedVariable = "addedAssessmentList";
      propKey = "diagnosis_id";
      deleteUrl = ASSESSMENT_DELETE;
    }
    // For Medicine
    if (actionType === "deleteMedicine") {
      stateVariable = "drugsData";
      stateAddedVariable = "addedDrugsList";
      propKey = "drug_id";
      deleteUrl = DELETE_MEDICINE;
    }
    // For Test
    if (actionType === "deleteTest") {
      stateVariable = "testData";
      stateAddedVariable = "addedTestList";
      propKey = "test_id";
      deleteUrl = DELETE_PRESCRIPTION_TEST;
    }

    // For Supporting Material
    if (actionType === "deleteSupportingMaterials") {
      stateVariable = "supportingFile";
      stateAddedVariable = "supportingFile";
      propKey = "id";
      deleteUrl = DELETE_VISIT_SUPPORTING_MATERIAL;
    }

    let dataArray = this.state[stateVariable];
    var deleteObj = dataArray[objId];
    var deleteId = deleteObj.id;

    if (actionType === "deleteMedicine") deleteId = deleteObj.prescription_drug_id;

    if (actionType === "deleteSupportingMaterials") {
      this.setState({
        deleteConfirmModal: false,
      });
      Axios.post(deleteUrl({ id: deleteId }))
        .then((success) => {
          notification.success({
            message: "Record(s) deleted successfully!",
            placement: 'topRight'
          })
          let removedSupportingFile = this.state.supportingFile.filter(function (ele, i) {
            return i !== objId;
          });
          this.setState({
            supportingFile: removedSupportingFile, spin_loading: false
          })
        })
        .catch((err) => console.log(err));
    }
    else if (actionType === "deleteTest") {
      this.setState({
        spin_loading: true,
      });
      dataArray.splice(objId, 1);
      let addedArray = [];
      dataArray.map((v, k) => {
        addedArray.push(v[propKey]);
      });
      this.setState({
        deleteConfirmModal: false,
        [stateVariable]: dataArray,
        [stateAddedVariable]: addedArray,
      });
      const { patient_id, qms_token_id } = this.state.patient;
      let PARAMS = {
        facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
        ServiceCategoryCode: "I",
        patient_test_id: deleteId,
        reason_id: 163,
        reason_desc: "Test Reason Master",
        cancelBy: parseInt(LS_SERVICE.get('staff_id')),
        cancelByName: LS_SERVICE.get('staff_name'),
        patientId: patient_id,
      }
      Axios.post(DELETE_PRESCRIPTION_TEST, qs.stringify(PARAMS))
        .then(success => {
          this.getVisitTestList();
          this.setState({
            spin_loading: false
          })
        })
        .catch(err => console.log(err))
    }
    else {
      this.setState({
        spin_loading: true,
      });
      dataArray.splice(objId, 1);
      let addedArray = [];
      dataArray.map((v, k) => {
        addedArray.push(v[propKey]);
      });
      this.setState({
        deleteConfirmModal: false,
        [stateVariable]: dataArray,
        [stateAddedVariable]: addedArray,
      });
      Axios.get(deleteUrl({ id: deleteId }))
        .then((success) => {
          this.handleInitialData();
          this.setState({
            spin_loading: false
          });

        })
        .catch((err) => console.log(err));
    }
  };

  /*resetMedicineModal = () => {
        this.setState({ updateMedicineModal: false, medicineEditData: null })
    }
    resetUpdateMedicineModal = (val) => {
        this.setState({ updateMedicineModal: val })
    }*/

  handleDeletePopSubmit = (e) => {
    const { delete_data } = this.state;

    this.setState({
      spin_loading: true,
    });

    Axios.get(ASSESSMENT_DELETE({ id: delete_data.id }))
      .then((success) => {
        this.handleInitialData();
        this.setState({
          spin_loading: false,
        });
        this.handleModalPopup(null, "deleteConfirmModal");
      })
      .catch((err) => console.log(err));
  };

  enablePractice = async (e) => {
    e.preventDefault();
    practiceService.init({ initiater: "SOAP Pages", status: "start" });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
    } else {
      is_practicing = false;
    }
    this.setState(
      {
        isPracticing: is_practicing,
      },
      () => {
        this.handleModalPopup(null, "isPracticingModal");
      }
    );
  };

  handlePracticeModal = (data) => {
    this.handleModalPopup(null, data);
  };

  disabledDate(current) {
    //return current && (current < moment(duedate).startOf('day') || current < moment(this.state.birthDate).startOf('day'));
    return current && current > moment();
  }

  disabledPastDate(current) {
    return current && current < moment();
  }

  showFetalInfo() {
    let show = false;
    const { phyExaminationFpList } = this.state;
    if (phyExaminationFpList.length > 0) {
      loop1:
      for (let element of phyExaminationFpList) {
        if (element.fetal_params.length > 0) {
          loop2:
          for (let fetal_param of element.fetal_params) {
            show = true;
            break loop1;
          }
        }
      }
    }
    return show;
  }

  subjective = (subjective_id) => {
    var { patient } = this.state;

    var data = null;
    if (subjective_id == 0) {
      data = LS_SERVICE.get("chief_complaint_" + patient?.patient_id + "_" + patient?.qms_token_id);
    }

    return data;
  }
  handleEditDelete = (e, modal, data, action, idx) => {
    this.handleModalPopup(e, modal, data, action, idx);
    // document.getElementById('deleteButton').focus();
  }


  openSupportingFile(e, file_url) {
    this.setState({ pdfPath: file_url, showPdfModal: true });
  }

  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }


  render() {
    const {
      vaccinationData,
      itemData,
      patient,
      field_status,
      subjectiveData,
      details,
      isAdmissionAdvisedPriority,
      admissionChecked,
      admissionComment,
      physioText,
      physiotherapy_advised,
      isPracticing,
      isDataFetching,
      visitAddendum,
      isPaediatrics,
      addedDrugsList
    } = this.state;

    const dateFormat = "DD/MM/YYYY";

    let one_group_avail = false;
    this.state.newKeyFindingsOrder.forEach((data) => {
      if (!one_group_avail) {
        one_group_avail = data.one_value_available;
      }
    });

    return (
      <>
        {patient != undefined && patient.patient_id != undefined ? (
          <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} />
        ) : null}

        {!isDataFetching ? (
          <div className="content-wrapper">
            <div className="container-fluid ">
              <div className="row" id="view-prescription-content">
                <div className="col-12 mb-3">
                  <div className="float-left">
                    <h1 className="page-title">View Prescription</h1>
                  </div>
                  <div className="float-right">
                    <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>
                      BACK
                    </button>
                  </div>
                </div>
                <div className="col-12 emrfrm subjective-form">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="page-title">Subjective</h2>
                      <div className="row">
                        <div className="col-12">
                          {/* {field_status.subjective?.map((item, i) => {
                                                        const descriptionValue = subjectiveData !== null ? subjectiveData[i]?.description : "";
                                                        return (
                                                            item.type != "review_of_systems" || (item.type === "review_of_systems" &&
                                                                details.subjectives[i] !== undefined && descriptionValue !== null && descriptionValue !== '') ?
                                                                <div className="form-group normal micwrap" key={item.type}>
                                                                    <label className="control-label" htmlFor={item.type}>{item.name} {item.name !== 'On Examination' ? (<span className="text-danger">*</span>) : null}</label>
                                                                    <div className="input-group">
                                                                        <textarea type="text" className="form-control subjective-field" rows={1} name={item.type} id={item.type} onChange={(e) => this.handleSubjectiveOnChange(e, item.type)} onBlur={(e) => this.handleSubjectiveOnEdit(e, item.type)}
                                                                            value={this.state[item.type + 'Value']} disabled={this.state[item.type + 'Disabled']} />

                                                                        {this.state.allowEdit ? <a href="#" className="edit" onClick={(e) => this.onHandleSubjectiveStatus(e, item.type)}><i className="icon-edit"></i></a> : null}
                                                                    </div>
                                                                </div>
                                                                : ''                                                        )
                                                    })} */}

                          {field_status.subjective !== null
                            ? field_status.subjective.map((sf, idx) => {
                              if (details.constants.subjectives[idx].required || sf.status) {
                                return (
                                  <div className="form-group micwrap normal" key={idx}>
                                    <label htmlFor={sf.type} className="control-label">
                                      {sf.name} {details.constants.subjectives[idx].required && <span className="text-danger">*</span>}
                                    </label>

                                    <textarea
                                      className="form-control subjective-field"
                                      id={sf.type}
                                      name={sf.type}
                                      disabled={this.state[sf.type + "Disabled"]}
                                      onChange={(e) => this.handleSubjectiveOnChange(e, sf.type)}
                                      onBlur={(e) => this.handleSubjectiveOnEdit(e, sf.type)}
                                      value={this.state[sf.type + "Value"]}
                                      rows="1"
                                      onInput={(e) => this.autoHeight(e)}
                                    ></textarea>

                                    {this.state.allowEdit ? (
                                      <a href="#" className="edit" onClick={(e) => this.onHandleSubjectiveStatus(e, sf.type)}>
                                        <i className="icon-edit"></i>
                                      </a>
                                    ) : null}
                                  </div>
                                );
                              }
                            })
                            : null}
                          {this.state.clinicalNoteIds.length && this.state.subjectiveClinicalData.length ?
                            <div className="form-group micwrap normal">
                              <label className="control-label">
                                Clinical Notes
                              </label>
                              {this.state.subjectiveClinicalData.map((element, index) => {
                                if (this.state.clinicalNoteIds.includes(String(element.id))) {
                                  return (
                                    <div className='row mt-2' key={index}>
                                      <div className='col-auto d-flex align-items-center'>
                                        <label className=""><span className=''><Moment date={element.created_at} format="D MMM YYYY" /></span>
                                        </label>
                                      </div>
                                      <div className='col'>
                                        <span className='text-dark'>{element?.description}</span>
                                      </div>
                                    </div>
                                  )
                                }
                              })
                              }
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>
                    {/* Vital */}

                    <div className="col-12  mt-4">
                      {details.visitVitals !== undefined && Object.keys(details.visitVitals).length > 0 ? (
                        <div className="row">
                          <h2 className="page-title col-12 mb-3">
                            Vitals <small className="text-muted">({moment(details.visitVitals[0].date).add(330, "minutes").format("DD MMM, YYYY | hh:mm A")})</small>
                          </h2>
                          {this.state.showingVitals.map((vital) => {
                            if (vital.id != "V005" && vital.id != "V006")
                              return (
                                <>
                                  {details?.visitVitals?.[0]?.[vital.id] && details?.visitVitals?.[0]?.[vital.id] !== "" ? (
                                    <div className="col text-dark border-right">
                                      {vital.display_name}
                                      <br />({vital.display_value})
                                      <span className="text-muted d-block mt-2">
                                        {details.visitVitals[0] && details.visitVitals[0][vital.id] !== "" ? details.visitVitals[0][vital.id] : "-"}
                                      </span>
                                    </div>
                                  ) : null}
                                </>
                              );
                            if (vital.id == "V005")
                              return (
                                <>
                                  {(details?.visitVitals?.[0]?.V006 && details?.visitVitals?.[0]?.V006 !== "") ||
                                    (details?.visitVitals?.[0]?.V005 && details?.visitVitals?.[0]?.V005 !== "") ? (
                                    <div className="col text-dark border-right">
                                      Blood Pressure
                                      <br />
                                      (mmHg)
                                      <span className="text-muted d-block mt-2">
                                        {details.visitVitals[0]?.V006 !== "" ? details.visitVitals[0]?.V006 : "-"}/
                                        {details.visitVitals[0]?.V005 !== "" ? details.visitVitals[0]?.V005 : "-"}
                                      </span>
                                    </div>
                                  ) : null}
                                </>
                              );
                          })}
                          {this.state.vitalsConstant.map((vital) => {
                            if (vital.id != "V005" && vital.id != "V006")
                              return (
                                <>
                                  {details?.visitVitals?.[0]?.[vital.id] && details?.visitVitals?.[0]?.[vital.id] !== "" ? (
                                    <div className="col text-dark border-right">
                                      {vital.display_name}
                                      <br />({vital.display_value})
                                      <span className="text-muted d-block mt-2">
                                        {details.visitVitals[0] && details.visitVitals[0][vital.id] !== "" ? details.visitVitals[0][vital.id] : "-"}
                                      </span>
                                    </div>
                                  ) : null}
                                </>
                              );
                            if (vital.id == "V005")
                              return (
                                <>
                                  {(details?.visitVitals?.[0]?.V006 && details?.visitVitals?.[0]?.V006 !== "") ||
                                    (details?.visitVitals?.[0]?.V005 && details?.visitVitals?.[0]?.V005 !== "") ? (
                                    <div className="col text-dark border-right">
                                      Blood Pressure
                                      <br />
                                      (mmHg)
                                      <span className="text-muted d-block mt-2">
                                        {details.visitVitals[0]?.V006 !== "" ? details.visitVitals[0]?.V006 : "-"}/
                                        {details.visitVitals[0]?.V005 !== "" ? details.visitVitals[0]?.V005 : "-"}
                                      </span>
                                    </div>
                                  ) : null}
                                </>
                              );
                          })}

                        </div>
                      ) :
                        null}
                    </div>
                  </div>

                  {/* Vital Chart */}
                  {this.state.chartDataLoaded ? (
                    <Chart
                      chartData={this.state.chartData}
                      dataLoaded={this.state.chartDataLoaded}
                      bloodSugarChartData={this.state.bloodSugarChartData}
                      vitalsRangeWithId={this.state.vitalsRangeWithId}
                      chartsType={"all"}
                      reference_id={this.state.patient.qms_token_id}
                      chartGraphSetting={this.state.chartGraphSettings}
                      chartsTab={2}
                      constants={this.state.constants}
                      showOnlyTicked={true}
                    />
                  ) : null}

                  {/* Key Findings */}
                  <div className="row">
                    <div className="col-12 mt-4">
                      {one_group_avail ? (
                        <div className="row">
                          <h2 className="page-title col-12 mb-3">
                            Key Findings <small className="text-muted">({moment(details.visitVitalsKeyFindings[0].date).format("DD MMM, YYYY | hh:mm A")})</small>
                          </h2>
                          {this.state.newKeyFindingsOrder.map((fo, index) => {
                            if (fo.one_value_available) {
                              return fo.vital_sign.map((vital, i) => {
                                if (vital.value_available) {
                                  return (
                                    <div key={vital.id} className="col text-dark border-right">
                                      {vital.display_name}
                                      <br />({vital.display_value})
                                      <span className="text-muted d-block mt-2">
                                        {details.visitVitalsKeyFindings[0] && details.visitVitalsKeyFindings[0][vital.id] !== "" ? details.visitVitalsKeyFindings[0][vital.id] : "-"}
                                      </span>
                                    </div>
                                  );
                                }
                              });
                            }
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Key Findings Chart */}
                  {this.state.chartDataLoaded ? (
                    <KeyFindingsChart
                      chartData={this.state.chartData}
                      dataLoaded={this.state.chartDataLoaded}
                      keyFindingsOrder={this.state.keyFindings}
                      chartsType={"all"}
                      chartsId={null}
                      reference_id={this.state.patient.qms_token_id}
                      chartGraphSetting={this.state.keyFindingsChartGraphSettings}
                      chartsTab={2}
                      constants={this.state.constants}
                      showOnlyTicked={true}
                    />
                  ) : null}

                  {this.state.mensural_history_status && details.visitMenstrualHistory != undefined && details.visitMenstrualHistory.menstrualHistoryDetails && details.isObsGynaeConfidential.menstrualHistory ?
                    <div className="col-12 mt-4">
                      <div className="row">
                        <h2 className="page-title col-12 mb-12" style={{ paddingLeft: "0" }}>
                          Menstrual Hx <small className="text-muted">({moment(details.visitMenstrualHistory.menstrualHistoryDetails.createdAt).format("DD MMM, YYYY | hh:mm A")})</small>
                        </h2>
                        <>
                          {(details.visitMenstrualHistory.menstrualHistoryDetails.cycleLength && details.visitMenstrualHistory.menstrualHistoryDetails.cycleLength !== "") ?
                            <div style={{ paddingLeft: "0" }} className="col text-dark border-right">
                              {'Cycle Length (Days)'}
                              <span className="text-muted d-block mt-2">
                                {details.visitMenstrualHistory.menstrualHistoryDetails.cycleLength && details.visitMenstrualHistory.menstrualHistoryDetails.cycleLength !== "" ? details.visitMenstrualHistory.menstrualHistoryDetails.cycleLength : "-"}
                              </span>
                            </div>
                            : ''}
                          {(details.visitMenstrualHistory.menstrualHistoryDetails.durationOfPeriod && details.visitMenstrualHistory.menstrualHistoryDetails.durationOfPeriod !== "") ?
                            <div className="col text-dark border-right">
                              {'Duration of Period (Days)'}
                              <span className="text-muted d-block mt-2">
                                {details.visitMenstrualHistory.menstrualHistoryDetails.durationOfPeriod && details.visitMenstrualHistory.menstrualHistoryDetails.durationOfPeriod !== "" ? details.visitMenstrualHistory.menstrualHistoryDetails.durationOfPeriod : "-"}
                              </span>
                            </div>
                            : ''}
                          {(details.visitMenstrualHistory.menstrualHistoryDetails.flow && details.visitMenstrualHistory.menstrualHistoryDetails.flow != "") ? (
                            <div className="col text-dark border-right">
                              {'Flow'}
                              <span className="text-muted d-block mt-2">
                                {(details.visitMenstrualHistory.menstrualHistoryDetails.flow) ? details.visitMenstrualHistory.menstrualHistoryDetails.flow.charAt(0).toUpperCase() + details.visitMenstrualHistory.menstrualHistoryDetails.flow.slice(1) : '-'}
                              </span>
                            </div>
                          )
                            : ''}
                          {(details.visitMenstrualHistory.menstrualHistoryDetails.regularity && details.visitMenstrualHistory.menstrualHistoryDetails.regularity != "") ?
                            <div className="col text-dark border-right">
                              {'Regularity'}
                              <span className="text-muted d-block mt-2">
                                {(details.visitMenstrualHistory.menstrualHistoryDetails.regularity) ? details.visitMenstrualHistory.menstrualHistoryDetails.regularity.charAt(0).toUpperCase() + details.visitMenstrualHistory.menstrualHistoryDetails.regularity.slice(1) : '-'}
                              </span>
                            </div>
                            : ''}
                          {(details.visitMenstrualHistory.menstrualHistoryDetails.painScore && details.visitMenstrualHistory.menstrualHistoryDetails.painScore !== "") ?
                            <div className="col text-dark border-right">
                              {'Pain Score'}
                              <span className="text-muted d-block mt-2">
                                {details.visitMenstrualHistory.menstrualHistoryDetails.painScore && details.visitMenstrualHistory.menstrualHistoryDetails.painScore !== "" ? details.visitMenstrualHistory.menstrualHistoryDetails.painScore : "-"}
                              </span>
                            </div>
                            : null}
                          {(details.visitMenstrualHistory.menstrualHistoryDetails.changesInCycle && details.visitMenstrualHistory.menstrualHistoryDetails.changesInCycle != "") ?
                            <div className="col text-dark border-right">
                              {'Change in Cycle'}
                              <span className="text-muted d-block mt-2">
                                {details.visitMenstrualHistory.menstrualHistoryDetails.changesInCycle && details.visitMenstrualHistory.menstrualHistoryDetails.changesInCycle !== "" ? (details.visitMenstrualHistory.menstrualHistoryDetails.changesInCycle === '1') ? `Yes, ${details.visitMenstrualHistory.menstrualHistoryDetails.since}` : `No` : "-"}
                              </span>
                            </div>
                            : ''}
                          {(details.visitMenstrualHistory.menstrualHistoryDetails.remarks && details.visitMenstrualHistory.menstrualHistoryDetails.remarks !== "") ?
                            <div className="col text-dark border-right">
                              {'Remarks'}
                              <span className="text-muted d-block mt-2" title={(details.visitMenstrualHistory.menstrualHistoryDetails.remarks) ? details.visitMenstrualHistory.menstrualHistoryDetails.remarks.charAt(0).toUpperCase() + details.visitMenstrualHistory.menstrualHistoryDetails.remarks.slice(1) : '-'}>
                                {(details.visitMenstrualHistory.menstrualHistoryDetails.remarks) ? details.visitMenstrualHistory.menstrualHistoryDetails.remarks.charAt(0).toUpperCase() + details.visitMenstrualHistory.menstrualHistoryDetails.remarks.slice(1).substring(0, 40) : '-'} {(details.visitMenstrualHistory.menstrualHistoryDetails.remarks.length > 40 ? '...' : '')}
                              </span>
                            </div>
                            : ''}
                        </>
                      </div>
                    </div>
                    : null}

                  {this.state.mensural_history_status ?
                    <div className="col-12 fix-height-right col-md-12" style={{ marginTop: "20px" }}>
                      {MENSTRUAL_HX_TREND_GRAPH !== undefined && MENSTRUAL_HX_TREND_GRAPH.length > 0 ? MENSTRUAL_HX_TREND_GRAPH.map((d, i) => (
                        (details.visitMenstrualHistory?.menstrualHistoryPdfDetails.checkedMenstrualCycleLength == true && d.val == 'cycle_length' || details.visitMenstrualHistory?.menstrualHistoryPdfDetails.checkedMenstrualDurationOfPeriod == true && d.val == 'duration_of_period' || details.visitMenstrualHistory?.menstrualHistoryPdfDetails.checkedMenstrualPainScore == true && d.val == 'pain_score') ?
                          <GynaeTrendGraph
                            chartData={this.state.menstrualHistoryChartData}
                            dataLoaded={this.state.menstrualHistoryChartDataLoaded}
                            chartType={d.val}
                            chartTypes={'all'}
                            chartTitle={'Trend Graph'}
                            title={d.des}
                            chartsTab={2}
                            checkBoxVisible={false}
                          />
                          : null
                      )) : null}
                    </div>
                    : null}

                  {this.state.sexual_history_status && details.visitSexualHistory.sexualHistoryDetails != undefined && details.visitSexualHistory.sexualHistoryDetails && details.visitSexualHistory.sexualHistoryDetails.detailed_description && details.isObsGynaeConfidential.sexualHistory ?
                    <div className="col-12 mt-4">
                      <div className="row">
                        <div>
                          <h2 className="page-title">
                            Sexual Hx <small className="text-muted">({moment(details.visitSexualHistory.sexualHistoryDetails.createdAt).format("DD MMM, YYYY | hh:mm A")})</small>
                          </h2>
                        </div>
                      </div>
                      <div className="row">
                        <div>
                          <p className="page-title">{details.visitSexualHistory.sexualHistoryDetails.detailed_description}</p>
                          {(details.visitSexualHistory.sexualHistoryDetails.remarks) ?
                            <p className="page-title">Remarks: {details.visitSexualHistory.sexualHistoryDetails.remarks}</p>
                            : null}
                        </div>
                      </div>
                    </div>
                    : null}

                  {/* <div className="row" id="vitals-graph">
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V001">
                                                <h4>Height</h4>
                                                <canvas id="chart-V001" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V002">
                                                <h4>Weight</h4>
                                                <canvas id="chart-V002" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V003">
                                                <h4>BMI</h4>
                                                <canvas id="chart-V003" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V004">
                                                <h4>Temperature</h4>
                                                <canvas id="chart-V004" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V005">
                                                <h4>Blood Pressure</h4>
                                                <canvas id="chart-V005" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V007">
                                                <h4>SPO2</h4>
                                                <canvas id="chart-V007" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V008">
                                                <h4>Heart Rate</h4>
                                                <canvas id="chart-V008" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V009">
                                                <h4>Pulse Rate</h4>
                                                <canvas id="chart-V009" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V010">
                                                <h4>Blood Sugar Fasting</h4>
                                                <canvas id="chart-V010" />
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3">
                                            <div className="chartCanvas" data-vital="V011">
                                                <h4>Blood Sugar Post Prandial</h4>
                                                <canvas id="chart-V011" />
                                            </div>
                                        </div>
                                    </div> */}
                  {Object.keys(this.state.growthChartSettings).length && this.state.showGrowthChart ? (
                    <div className="row mt-3">
                      <div className="col-12 mb-2">
                        <div className="float-left">
                          <h3 className="page-title">
                            <a href="#" className="nostyle-link">
                              Growth Charts
                            </a>
                          </h3>
                        </div>
                      </div>
                      {this.state.growthData.map((data, i) => {
                        if (Object.keys(data).length) {
                          return (
                            <div
                              key={i}
                              className={`mb-3 bg-grey ${this.state.growthData.length == 2 ? "col-6" : "col-4"}`}
                              style={{ height: "350px" }}
                            >
                              <IndividualPaediatricChart data={data} otheroptions={this.state.otheroptions[i]} />
                            </div>
                          );
                        }
                      })}
                      {/* <div className="col-4 text-center">Weight for Age</div>
                                            <div className="col-4 text-center">Head circumference for Age</div>  */}
                      {/* <div className="col-3 text-center">Weight for Height</div> */}

                      <ul className="legend_unit_label float-right">
                        {this.state.growthChartSettings.growthchart_type == "CDC" ? (
                          <>
                            <li>
                              <span className="fst"></span>3rd Percentile
                            </li>
                            <li>
                              <span className="thrd"></span>5th Percentile
                            </li>
                            <li>
                              <span className="fif"></span>50th Percentile
                            </li>
                            <li>
                              <span className="nint"></span>97th Percentile
                            </li>
                          </>
                        ) : this.state.growthChartSettings.growthchart_type == "WHO" ? (
                          <>
                            <li>
                              <span className="fst"></span>1st Percentile
                            </li>
                            <li>
                              <span className="thrd"></span>3rd Percentile
                            </li>
                            <li>
                              <span className="fif"></span>50th Percentile
                            </li>
                            <li>
                              <span className="nint"></span>97th Percentile
                            </li>
                          </>
                        ) : this.state.growthChartSettings.growthchart_type == "IAP" ||
                          this.state.growthChartSettings.growthchart_type == "WHOIAP" ? (
                          <>
                            <li>
                              <span className="fst"></span>3rd Percentile
                            </li>
                            {/* <li><span className="thrd"></span>5th Percentile</li> */}
                            <li>
                              <span className="fif"></span>50th Percentile
                            </li>
                            <li>
                              <span className="nint"></span>97th Percentile
                            </li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                  ) : null}

                  <div className="row mt-3 dev-miles">
                    {this.state.filterMilestoneData && this.state.filterMilestoneData.length > 0 ? (
                      <>
                        {this.state.filterMilestoneData?.map((el, i) => (
                          <>
                            <div className="col-12 mb-2">
                              <h3 className="page-title float-left" style={{ color: "#5c5c5c" }}>
                                {i == 0 ? "Developmental Milestones : " : ""}
                                <span className="text-danger">{el.age}</span>
                              </h3>
                              {this.state.allowEdit ? (
                                <div className="float-right">
                                  {i == 0 ? (
                                    <a onClick={this.milestoneRedirect}>
                                      <i className="icon-edit mr-2"></i>
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : null}
                            </div>
                            {el.data.map((el1, j) => (
                              <div className="col-6">
                                <h6 className="mb-3" style={{ color: "#5c5c5c" }}>
                                  {el1.cat}:{" "}
                                </h6>
                                <ul>
                                  {el1.sub_cat?.map((el2, k) => (
                                    <li key={k}>{el2}</li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {details.isObsGynaeConfidential.obstetricHistory && this.state.obs_history_status && (this.state.obsHistoryList.length > 0 || this.state.gpale_values.gravidity != null || this.state.gpale_values.parity != null || this.state.gpale_values.abortion != null || this.state.gpale_values.live_birth != null || this.state.gpale_values.ectopic != null) ?
                    <div className="row mt-3">
                      <div className="col-3">
                        <div className="float-left pt-1">
                          <h3 className="page-title">
                            Obstetric Hx
                          </h3>
                        </div>
                      </div>
                      <div className="col-3">
                        {(this.state.gpale_values.gravidity != null) || (this.state.gpale_values.parity != null) || (this.state.gpale_values.abortion != null) || (this.state.gpale_values.live_birth != null) || (this.state.gpale_values.ectopic != null) ? <div className="form-group">
                          <span className="bg-grey  py-1 px-3 rounded font-weight-bold">
                            {(this.state.gpale_values.gravidity != null) ? 'G' + this.state.gpale_values.gravidity + ' ' : ''}{(this.state.gpale_values.parity != null) ? 'P' + this.state.gpale_values.parity + ' ' : ''}{(this.state.gpale_values.abortion != null) ? 'A' + this.state.gpale_values.abortion + ' ' : ''}{(this.state.gpale_values.live_birth != null) ? 'L' + this.state.gpale_values.live_birth + ' ' : ''}{(this.state.gpale_values.ectopic != null) ? 'E' + this.state.gpale_values.ectopic + ' ' : ''}
                          </span>
                        </div> : null}
                      </div>
                      <div className="col-3">
                        {this.state.latest_menst_hist && this.state.latest_menst_hist.lmpDate ?
                          <React.Fragment>
                            <span>LMP:&nbsp;</span><span className="font-weight-bold">{moment(this.state.latest_menst_hist.lmpDate).format('DD MMM, YYYY')}</span>
                          </React.Fragment>
                          : null}
                      </div>
                      <div className="col-3">
                        {this.state.current_pregnancy && this.state.current_pregnancy.eddDate ?
                          <div className="form-group bg-grey py-1 px-3 rounded ">
                            <span>{(this.state.current_pregnancy.correctedEddDate) ? "Corrected EDD:" : "EDD:"}</span>{" "}
                            <span className="font-weight-bold">
                              {(this.state.current_pregnancy.correctedEddDate) ? moment(this.state.current_pregnancy.correctedEddDate).format('DD MMM, YYYY') : moment(this.state.current_pregnancy.eddDate).format('DD MMM, YYYY')}
                            </span>
                          </div>
                          : null}
                      </div>
                      <div className="col-12 mb-2">
                        {this.state.current_pregnancy && this.state.current_pregnancy.remarksForCurrentPregnancy ?
                          <div className="row">
                            <div>
                              <p>Remarks For Current Pregnancy : <span className="page-title">{this.state.current_pregnancy.remarksForCurrentPregnancy}</span></p>
                            </div>
                          </div>
                          : null}
                        {this.state.current_pregnancy && this.state.current_pregnancy.riskFactorsDedtails ?
                          <div className="row">
                            <div>
                              <p>Risk Factor : <span className="page-title">{this.state.current_pregnancy.riskFactorsDedtails}</span></p>
                            </div>
                          </div>
                          : null}
                        {this.state.current_pregnancy && this.state.current_pregnancy.riskFactorsRemarks ?
                          <div className="row">
                            <div>
                              <p>Remarks For Risk Factor  : <span className="page-title">{this.state.current_pregnancy.riskFactorsRemarks}</span></p>
                            </div>
                          </div>
                          : null}
                      </div>

                      {this.state.obsHistoryList && this.state.obsHistoryList.length > 0 ?
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-bordered" id="obstetric-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "100px" }}>YEAR OF OUTCOME</th>
                                  <th style={{ width: "150px" }}>MODE OF CONCEPTION</th>
                                  <th>PREGNANCY TYPE</th>
                                  <th>DELIVERY TYPE</th>
                                  <th>OUTCOME</th>
                                  <th style={{ width: "250px" }}>REMARKS</th>
                                  {/* <th className="text-center">EDIT</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.obsHistoryList.map((data, i) => (
                                  <tr key={i}>
                                    <td>{data.pregnancy_year}</td>
                                    <td>{data.mode_text}</td>
                                    <td>{data.pregnancy_type_text}</td>
                                    <td>{data.delivery_type_text}</td>
                                    <td>{data.outcome_text}</td>
                                    <td>{data.remarks || '-'}</td>
                                    {/* <td className="action text-center">
                                    <Button className='btn nostyle-link p-0' disabled={this.state.no_obs_hx} onClick={() => { this.enableEditPregnancy(data); }}>
                                      <i className="icon-edit" />
                                    </Button>
                                  </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div> : null}
                    </div> : null}

                  {details.isObsGynaeConfidential.PhysicalExamination && this.state.phy_examination_status && ((this.state.phyExaminationList != null && this.state.phyExaminationList.length > 0) || (this.state.phyExaminationFpList != null && this.state.phyExaminationFpList.length > 0)) ?
                    <div className="row mt-3">
                      <div className="col-4 mb-2 pt-1">
                        <div className="float-left">
                          <h3 className="page-title">
                            Physical Examination <small className="text-muted">({this.state.phyExaminationList != null && this.state.phyExaminationList.length > 0 ? moment(this.state.phyExaminationList[0].createdAt).format("DD MMM, YYYY | hh:mm A") : moment(this.state.phyExaminationFpList[0].createdAt).format("DD MMM, YYYY | hh:mm A")})</small>
                          </h3>
                        </div>
                      </div>
                      {this.state.current_pregnancy && (this.state.current_pregnancy.gestationalAgeLmpWeeks || this.state.current_pregnancy.gestationalAgeLmpDays) ?
                        <div className="col-3">
                          <div className="form-group pt-1">
                            <span>POG(D)</span>
                            <span>:&nbsp;</span>
                            <span className="font-weight-bold">
                              {this.state.current_pregnancy.gestationalAgeLmpWeeks ? <>{this.state.current_pregnancy.gestationalAgeLmpWeeks} Weeks</> : null}&nbsp;{this.state.current_pregnancy.gestationalAgeLmpDays ? <>{this.state.current_pregnancy.gestationalAgeLmpDays} Days</> : null}
                            </span>
                          </div>
                        </div> : null}
                      {this.state.current_pregnancy && (this.state.current_pregnancy.gestationalAgeUsgWeeks || this.state.current_pregnancy.gestationalAgeUsgDays) ?
                        <div className="col-3">
                          <div className="form-group pt-1">
                            <span>POG(USG)</span>
                            <span>:&nbsp;</span>
                            <span className="font-weight-bold">
                              {this.state.current_pregnancy.gestationalAgeUsgWeeks ? <>{this.state.current_pregnancy.gestationalAgeUsgWeeks} Weeks</> : null}&nbsp;{this.state.current_pregnancy.gestationalAgeUsgDays ? <>{this.state.current_pregnancy.gestationalAgeUsgDays} Days</> : null}
                            </span>
                          </div>
                        </div> : null}
                      {this.state.current_pregnancy && (this.state.current_pregnancy.gestationalAgeLmpWeeks || this.state.current_pregnancy.gestationalAgeLmpDays) ?
                        <div className="col-3">
                          <div className="form-group pt-1">
                            <span>POG(D)</span>
                            <span>:&nbsp;</span>
                            <span className="font-weight-bold">
                              {this.state.current_pregnancy.gestationalAgeLmpWeeks ? <>{this.state.current_pregnancy.gestationalAgeLmpWeeks} Weeks</> : null}&nbsp;{this.state.current_pregnancy.gestationalAgeLmpDays ? <>{this.state.current_pregnancy.gestationalAgeLmpDays} Days</> : null}
                            </span>
                          </div>
                        </div> : null}
                      {this.state.current_pregnancy && (this.state.current_pregnancy.gestationalAgeUsgWeeks || this.state.current_pregnancy.gestationalAgeUsgDays) ?
                        <div className="col-3">
                          <div className="form-group pt-1">
                            <span>POG(USG)</span>
                            <span>:&nbsp;</span>
                            <span className="font-weight-bold">
                              {this.state.current_pregnancy.gestationalAgeUsgWeeks ? <>{this.state.current_pregnancy.gestationalAgeUsgWeeks} Weeks</> : null}&nbsp;{this.state.current_pregnancy.gestationalAgeUsgDays ? <>{this.state.current_pregnancy.gestationalAgeUsgDays} Days</> : null}
                            </span>
                          </div>
                        </div> : null}
                      {this.state.phyExaminationList != null && this.state.phyExaminationList.length > 0 ?
                        <>
                          <div className="col-12 mb-2">
                            <span>Mother's Parameters</span>
                          </div>
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table table-bordered" id="phy-examination-table">
                                <thead>
                                  <tr>
                                    <th>PARAMETERS</th>
                                    <th>EDEMA</th>
                                    <th>PALLOR</th>
                                    <th>FUNDAL HEIGHT</th>
                                    <th>REMARKS</th>
                                    {/* <th style={{ width: "150px" }}>FETAL MOVEMENT</th>
                                <th>FETAL HEART RATE</th>
                                <th>PRESENTATION</th>
                                <th style={{ width: "150px" }}>RELATION OF PP TO BRIM</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.phyExaminationList.map((data, i) => (
                                    <tr key={i}>
                                      <td>
                                        {moment(data.createdAt).format("D MMM, YYYY")}
                                        {/* <Moment
                                      parse="YYYY-MM-DD"
                                      date={data.createdAt}
                                      format="D MMM, YYYY"
                                    /> */}
                                      </td>
                                      <td>{data.edema || '-'}</td>
                                      <td>{data.pallor || '-'}</td>
                                      <td>{data.fundalHeight || '-'}</td>
                                      <td>{data.remarks || '-'}</td>
                                      {/* <td>{this.getListValue(data.fetalMovement, FETAL_MOVEMENT)}</td>
                                  <td>{data.fetalHeartRate || '-'}</td>
                                  <td>{this.getListValue(data.presentation, PRESENTATION)}</td>
                                  <td>{this.getListValue(data.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                        : null}
                      {this.state.phyExaminationFpList != null && this.state.phyExaminationFpList.length > 0 && this.showFetalInfo() ?
                        <>
                          <div className="col-12 mb-2">
                            <span>Fetal Parameters</span>
                          </div>
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table table-bordered" id="phy-examination-table">
                                <thead>
                                  <tr>
                                    <th style={{ width: "120px" }}>PARAMETERS</th>
                                    <th style={{ width: "100px" }}>FETUS NO.</th>
                                    <th>FETAL MOVEMENT</th>
                                    <th style={{ width: "115px" }}>FETAL HEART RATE</th>
                                    <th>PRESENTATION</th>
                                    <th>RELATION OF PP TO BRIM</th>
                                    <th>HEAD CC</th>
                                    <th>ABDOMINAL CC</th>
                                    <th>FEMUR LENGTH</th>
                                    <th>REMARKS</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.phyExaminationFpList.map((data, i) => (
                                    data.fetal_params.map((d, j) => (
                                      <tr key={d.id}>
                                        {j == 0 ?
                                          <td rowSpan={data.fetal_params.length}> {moment(data.createdAt).format("D MMM, YYYY")}
                                          </td>
                                          : null}
                                        <td>{j + 1}</td>
                                        <td>{this.getListValue(d.fetalMovement, FETAL_MOVEMENT)}</td>
                                        <td>{d.fetalHeartRate || '-'}</td>
                                        <td>{this.getListValue(d.presentation, PRESENTATION)}</td>
                                        <td>{this.getListValue(d.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td>
                                        <td>{d.headCc || '-'}</td>
                                        <td>{d.abdominalCc || '-'}</td>
                                        <td>{d.femurLength || '-'}</td>
                                        <td style={{ wordBreak: 'break-all' }}>{data.remarks || '-'}</td>
                                      </tr>
                                    ))
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                        : null}
                    </div> : null}

                  {/* Fetal Parameters Chart */}
                  {details.isObsGynaeConfidential.PhysicalExamination && this.state.phy_examination_status && this.state.chartDataLoadedFp ? (
                    <FetalParametersTrendGraph
                      chartData={this.state.chartDataFp}
                      dataLoaded={this.state.chartDataLoadedFp}
                      reference_id={this.state.patient.qms_token_id}
                      chartGraphSetting={this.state.chartGraphSettingsFp}
                      chartsTab={2}
                      showOnlyTicked={true}
                    />
                  ) : null}

                  <div className="row mt-3">
                    <div className="col-12 mb-2">
                      <div className="float-left">
                        <h3 className="page-title">
                          <a
                            href="#"
                            className="nostyle-link"
                            onClick={(e) => {
                              this.state.allowEdit && this.handleModalPopup(e, !isPracticing ? "isPracticingModal" : "showAssessmentModal");
                            }}
                          >
                            Assessment
                          </a>
                        </h3>
                      </div>
                    </div>

                    <div className="col-12 assessment-list-container">
                      <div className="row">
                        <div className="col-12">
                          {!isDataFetching ? (
                            <AssessmentTable
                              assessmentData={this.state.assessmentData}
                              viewType={"soap-view"}
                              statusSwitch={this.handleStatusSwitch}
                              showAction={this.state.allowEdit ? true : false}
                              showSelectCheck={false}
                              constants={this.state.constants}
                              updateSelection={this.handleSelectCheck}
                              handlePopUp={this.handleModalPopup}
                              handleEdit={this.handleEdit}
                              isPracticing={isPracticing}
                              practiceModal={this.handlePracticeModal}
                              allowEdit={this.state.allowEdit}
                            />
                          ) : null}
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>

                  {isPaediatrics === 1 ? (
                    vaccinationData && vaccinationData.length > 0 ? (
                      <div className="row">
                        <div className="container-fluid">
                          <div className="row emrfrm">
                            <div className="col-lg-12">
                              <h1 className="page-title float-left">Vaccines Administered</h1>
                            </div>
                            <Form className="emrfrm">
                              <div className="col-lg-12">
                                <div className="tab-content">
                                  <div className="tab-pane fade show active" role="tabpanel">
                                    <div className="table-responsive">
                                      <table className="table table-default">
                                        <thead>
                                          <tr>
                                            <th style={{ width: "190px" }}>VACCINE</th>
                                            <th style={{ width: "200px" }}>VACCINE Name</th>
                                            <th style={{ width: "140px" }}>DUE DATE</th>
                                            <th style={{ width: "140px" }}>Given Outside</th>
                                            <th style={{ width: "140px" }}>Given On</th>
                                            <th style={{ width: "125px" }}>Batch</th>
                                            <th style={{ width: "140px" }}>EXPIRY DATE</th>
                                            <th style={{ width: "140px" }}>Remarks</th>
                                            <th style={{ width: "140px" }}>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {vaccinationData?.map((iterable, i) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>
                                                    {iterable.name} {iterable.dose_number}{" "}
                                                    <input
                                                      type="hidden"
                                                      className="custom-control-input"
                                                      value={`${iterable.name} ${iterable.scheduleDoseNo}`}
                                                    />
                                                  </td>
                                                  <td>
                                                    {itemData?.map((item, i) => {
                                                      let selected = false;
                                                      if (iterable.item_id == item.id) {
                                                        return <> {item.name}</>;
                                                      }
                                                    })}
                                                  </td>
                                                  <td>{iterable.duedate && iterable.type == "O" ? "" : iterable.due_date} </td>
                                                  <td>{iterable.given_outside == 1 ? "Yes" : "No"}</td>
                                                  <td className="datepicker">
                                                    {iterable.given_on ? moment(iterable.given_on).format("DD/MM/YYYY") : iterable.given_on}
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="table-form-control remark"
                                                      disabled
                                                      readOnly
                                                      placeholder="Type Batch"
                                                      value={iterable.batch}
                                                    />
                                                  </td>
                                                  <td>
                                                    {iterable.expiry_date ? moment(iterable.expiry_date).format("MM/YYYY") : iterable.expiry_date}{" "}
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="table-form-control remark"
                                                      disabled
                                                      readOnly
                                                      value={iterable.remarks}
                                                      placeholder="Type Remark"
                                                    />
                                                  </td>
                                                  <td>
                                                    <a
                                                      className="nostyle-link mr-2"
                                                      onClick={() => {
                                                        this.showVaccineEAlert(
                                                          iterable.id,
                                                          iterable.name,
                                                          iterable.batch,
                                                          iterable.remarks,
                                                          iterable.given_on,
                                                          iterable.expiry_date,
                                                          iterable.given_outside,
                                                          iterable.item_id
                                                        );
                                                      }}
                                                    >
                                                      <i className="icon-edit"></i>
                                                    </a>
                                                    <a
                                                      className="nostyle-link mr-2"
                                                      onClick={() => {
                                                        this.showVaccineAlert(iterable.id);
                                                      }}
                                                    >
                                                      <i className="icon_delete"></i>
                                                    </a>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <div className="row mt-3">
                    <div className="col-12 mb-2">
                      <div className="float-left">
                        <h3 className="page-title">
                          <a
                            href="#"
                            className="nostyle-link"
                            onClick={(e) => {
                              this.state.allowEdit && this.handleModalPopup(e, !isPracticing ? "isPracticingModal" : "showMedicineModal");
                            }}
                          >
                            Medicine
                          </a>
                        </h3>
                      </div>
                    </div>

                    <div className="col-md-12">
                      {!isDataFetching ? (
                        <MedicineTable
                          drugsData={this.state.drugsData}
                          viewType={"soap-view"}
                          showAction={this.state.allowEdit ? true : false}
                          showSelectCheck={false}
                          constants={this.state.constants}
                          handlePopUp={this.handleModalPopup}
                          isPracticing={isPracticing}
                          practiceModal={this.handlePracticeModal}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 mb-2">
                      <div className="float-left">
                        <h3 className="page-title">
                          <a
                            href="#"
                            className="nostyle-link"
                            onClick={(e) => {
                              this.state.allowEdit && this.handleModalPopup(e, !isPracticing ? "isPracticingModal" : "showTestModal");
                            }}
                          >
                            Test
                          </a>
                        </h3>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      {!isDataFetching ? (
                        <TestTable
                          testData={this.state.testData}
                          viewType={"soap-view"}
                          showAction={this.state.allowEdit ? true : false}
                          eventBased={this.state.isEventBased && this.state.isEventBased == 'Y' ? true : false}
                          showSelectCheck={false}
                          constants={this.state.constants}
                          updateSelection={this.handleSelectCheck}
                          handlePopUp={this.handleModalPopup}
                          isPracticing={isPracticing}
                          practiceModal={this.handlePracticeModal}
                        />
                      ) : null}
                    </div>
                  </div>

                  {details?.instructions?.length > 0 ? (
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="float-left">
                          <h3 className="page-title">Instructions</h3>
                        </div>
                        <div className="float-right">&nbsp;</div>
                      </div>
                      <div className="col-12">
                        <ul className="custom-list list-success">
                          {details.instructions?.map((item) => (
                            <li key={item.instruction_id}>{item.instruction}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : null}

                  {this.state.supportingFile?.length > 0 ? (
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="float-left">
                          <h3 className="page-title">Supporting Material</h3>
                        </div>
                        <div className="float-right">&nbsp;</div>
                      </div>
                      <div className="col-6">
                        {this.state.supportingFile?.map((item, index) => (
                          <span key={item.id}>
                            <ul className="custom-list list-success" style={{ marginBottom: 0 }}>
                              <li>
                                <a onClick={e => this.openSupportingFile(e, item.file_url, index)} className="nostyle-link remove-list remove-instruction">
                                  {item.file_name}
                                </a>
                                <a>
                                  <i style={{ float: "right" }} className="icon_delete text-right pt-1" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', item, 'deleteSupportingMaterials', index)}></i>
                                </a>
                              </li>
                            </ul>
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : null}

                  {details.referrals?.length > 0 ? (
                    <div className="row mt-3" id="referral">
                      {details.referrals?.map((item, i) => (
                        <div className="col-12 mb-3" key={item.id}>
                          <div className="card rounded-0">
                            <div className="card-body py-3">
                              <a
                                href="!#"
                                className="nostyle-link text-dark"
                                onClick={(e) => {
                                  this.state.allowEdit && this.handleReferralRoute(e);
                                }}
                              >
                                <div className="row">
                                  <div className="col-10 col-md-11">
                                    <span className="font-weight-semibold">REFERRAL {i + 1}</span>
                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">
                                      Speciality : <strong>{item.speciality.name != "" ? item.speciality.name : "-"}</strong>
                                    </span>
                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">
                                      Doctor's Name : <strong>{item.doctor.doctor_name != "" ? item.doctor.doctor_name : "-"}</strong>{" "}
                                    </span>
                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">
                                      Priority : <span className="text-danger">{item.priorityname}</span>
                                    </span>
                                    <span className="d-block d-lg-inline pl-0 pl-lg-5">
                                      Doctor's Note : <strong>{item.instructions}</strong>
                                    </span>
                                  </div>
                                  <div className="col-2 col-md-1 align-self-center">
                                    <span className="float-right chevron chevron-right" />
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row" key="1" id="referral">
                      <div className="col-12 mb-3">
                        <div className="card rounded-0">
                          <div className="card-body py-3">
                            <a
                              href="!#"
                              className="nostyle-link text-dark"
                              onClick={(e) => {
                                this.state.allowEdit && this.handleReferralRoute(e);
                              }}
                            >
                              <div className="row">
                                <div className="col-10 col-md-11">
                                  <span className="font-weight-semibold">REFERRAL</span>
                                </div>
                                <div className="col-2 col-md-1 align-self-center">
                                  <span className="float-right chevron chevron-right" />
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row emrfrm mb-3">
                    <div className="col-6">
                      <div className="card rounded-0">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-12 col-md-6 font-weight-semibold text-dark float-left">PHYSIOTHERAPY ADVISED</div>
                            <div className="col-12 col-md-6 text-md-right">
                              <Radio.Group
                                onChange={this.handleRadioChange}
                                name="physiotherapy_advised"
                                value={physiotherapy_advised}
                                disabled={!this.state.allowEdit}
                              >
                                <Radio value={1} name="physiotherepy_radio" title={"3"}>
                                  Yes
                                </Radio>
                                <Radio value={2} name="physiotherepy_radio" title={"5"}>
                                  No
                                </Radio>
                              </Radio.Group>
                            </div>

                            <div className={`col-12 mt-3 ${physiotherapy_advised == 2 ? "d-none" : ""}`} id="therapy_text">
                              <div className={`form-group ${physioText != "" ? "hasdata" : ""}`}>
                                <textarea
                                  onInput={(e) => this.autoHeight(e)}
                                  rows={1}
                                  id="comment"
                                  className="form-control autoHeightText"
                                  placeholder="Therapy Required"
                                  value={physioText}
                                  onBlur={this.physiotherapySave}
                                  disabled={!this.state.allowEdit}
                                  onChange={(e) => this.setState({ physioText: e.target.value })}
                                />
                                <label htmlFor="comment">Therapy Required</label>
                              </div>
                              {/* <button type="button" className="float-right btn btn-outline-secondary" onClick={this.physiotherapySave}>SAVE</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card rounded-0">
                        <div className="card-body py-3">
                          <div className="row">
                            <div className="col-8 font-weight-semibold mb-1">
                              <label className="d-inline font-weight-semibold text-dark mr-3">ADMISSION ADVISED {admissionChecked}</label>
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="admission_advised"
                                  name="admissionChecked"
                                  value={admissionChecked}
                                  checked={admissionChecked}
                                  // onChange={() => this.setState(prev => ({ admissionChecked: !prev.admissionChecked }))}
                                  onChange={this.onHandleAdmissionAdvised}
                                  disabled={!this.state.allowEdit}
                                />
                                <label className="custom-control-label text-basic" htmlFor="admission_advised">
                                  &nbsp;
                                </label>
                              </div>
                            </div>
                            {admissionChecked ? (
                              <>
                                <div className="col-4 font-weight-semibold mb-1">
                                  <div className="col-12 ant_switch_button_sm" id="emer_elect">
                                    <BootstrapSwitchButton
                                      width={150}
                                      height={24}
                                      size="xs"
                                      checked={isAdmissionAdvisedPriority}
                                      offlabel="Elective"
                                      onlabel="Emergency"
                                      offstyle="success"
                                      onstyle="alert"
                                      onChange={this.handleToggleAdmission}
                                      disabled={!this.state.allowEdit}
                                    />
                                  </div>
                                </div>
                                <div className={`col-12 mt-3 ${!admissionChecked ? "d-none" : ""}`} id="therapy_text">
                                  <div className={`form-group ${admissionComment != "" ? "hasdata" : ""}`}>
                                    <textarea
                                      onInput={(e) => this.autoHeight(e)}
                                      rows={1}
                                      id="comment"
                                      className="form-control autoHeightText"
                                      placeholder="Therapy Required"
                                      value={admissionComment}
                                      onBlur={this.handleAdmissionAdvicedComment}
                                      disabled={!this.state.allowEdit}
                                      onChange={(e) => this.setState({ admissionComment: e.target.value })}
                                    />
                                    <label htmlFor="comment">Admission Advised Remark</label>
                                  </div>
                                  {/* <button type="button" className="float-right btn btn-outline-secondary" onClick={this.physiotherapySave}>SAVE</button> */}
                                </div>
                                {/* <div className={`col-12 mt-3 ${admissionChecked? '':'none'}`}>
                                                            <textarea onInput={(e) => this.autoHeight(e)} cols={50} rows={1} className="form-control pull-left" id="admission_comment"
                                                                value={admissionText} onChange={(e) => this.setState({ admissionText: e.target.value })} maxLength={255}  />
                                                            <button type="button" className="float-right btn btn-outline-secondary mt-3" onClick={this.admissionSave}>SAVE</button>
                                                        </div> */}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-5">
                    {details.followUp !== null ? (
                      <div className="col-12 mb-4 mt-2" id="visit-follow-up">
                        <div className="card rounded-0">
                          <div className="card-body py-3 follow-up-change">
                            <div className="row">
                              <div className="col-10 col-md-4">
                                <span className=" font-weight-semibold text-dark">FOLLOW UP</span>
                                <span className="ml-5 visit-follow-up-days">
                                  {details.followUp?.followUpDate !== null
                                    ? moment(details.followUp?.followUpDate).endOf("day").fromNow()
                                    : "In " +
                                    details.followUp?.followUpDays +
                                    " " +
                                    (details.followUp && details.followUp.followUpType == 0
                                      ? "Days"
                                      : details.followUp.followUpDays == 1
                                        ? DURATION_TYPE_OBJECT_REVERSE_SINGULAR[details.followUp?.followUpType]
                                        : DURATION_TYPE_OBJECT_REVERSE[details.followUp?.followUpType])}
                                </span>
                              </div>
                              <div className="col-10 col-md-6">
                                Appointment :
                                <span className="font-weight-semibold text-dark visit-follow-up-date">
                                  {details.followUp?.followUpDate !== null
                                    ? " Booked " +
                                    moment(details.followUp?.followUpDate).format("D MMM, YYYY") +
                                    " | " +
                                    moment(details.followUp?.followUpTime, "hh:mm:ss").format("h:mm A")
                                    : " Not Booked"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {this.state.allowAddendum ? (
                    <div className="row mb-5">
                      <div className="col-12 mb-4 mt-2" id="addendum">
                        <div className="card rounded-0">
                          <div className="card-body py-3 follow-up-change">
                            <a className="nostyle-link text-dark">
                              <div className="row">
                                <div className="col-10 col-md-4">
                                  <span className=" font-weight-semibold text-dark">Addendum</span>
                                </div>
                                <textarea
                                  onInput={(e) => this.autoHeight(e)}
                                  rows={1}
                                  id="comment"
                                  className="form-control autoHeightText"
                                  placeholder="Addendum"
                                  value={visitAddendum}
                                  onBlur={this.handleVisitAddendum}
                                  onChange={(e) => this.setState({ visitAddendum: e.target.value })}
                                  maxLength={255}
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}


                </div>

                <div className="fixed-cta">
                  {this.state.allowEdit ? (
                    <div className="col-12 mt-2">
                      <p className="text-danger">You will not be able to edit this prescription after you complete.</p>
                    </div>
                  ) : null}
                  <div className="col-12 mt-2">
                    <div className="btn btn-outline-primary px-5 mr-3 text-uppercase save-template-button" onClick={this.handleSaveTemplate}>
                      SAVE AS CARE PROTOCOL
                    </div>
                    {this.state.cimsallow && addedDrugsList.length > 0 ?
                      <div className="btn btn-outline-primary px-5 mr-3 text-uppercase save-template-button" onClick={this.showModalInteraction}>
                        <i className="icon-medinfo drug_info mr-2" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}></i>VIEW ALL INTERACTIONS
                      </div> : null}
                    {this.state.allowEdit || this.state.allowAddendum ? (
                      <button
                        className="btn btn-primary px-5 complete-consult"
                        disabled={this.printButtonDisabled() || this.state.showPintComplete || this.state.junior_doctor_complete_appointment
                        }
                        onClick={this.handleCompleteAppointment}
                      >
                        PRINT &amp; COMPLETE CONSULT{this.state.isPrintLoader ? LOADER : null}
                      </button>
                    ) : (
                      <button className="btn btn-primary px-5 complete-consult" onClick={this.handleCompleteAppointment}>
                        GO TO PATIENT QUEUE
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col d-flex justify-content-center align-self-center mt-2">{LOADER_RED}</div>
        )}

        <Modal
          id="alert_confirm_message_vaccine"
          className="alert_confirm_message"
          title="Are you sure you want to delete vaccine?"
          visible={this.state.isAlertVaccineVisible}
          onOk={() => this.handleVaccineOk()}
          bodyStyle={{ padding: 0 + "px" }}
          onCancel={() => this.handleVaccineCancel()}
          closable={false}
          footer={[
            <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.handleVaccineCancel()}>
              CANCEL
            </Button>,
            <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleVaccineOk()}>
              DELETE
            </Button>,
          ]}
          width="520px"
        ></Modal>

        {this.state.isAlertVaccineEVisible == true ? (
          <Modal
            id="alert_confirm_message_edit_vaccine"
            className="alert_confirm_message"
            title="Edit Vaccine"
            visible={this.state.isAlertVaccineEVisible}
            onOk={() => this.handleVaccineEOk()}
            bodyStyle={{ padding: 0 + "px" }}
            onCancel={() => this.handleVaccineECancel()}
            closable={false}
            footer={[
              <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.handleVaccineECancel()}>
                CANCEL
              </Button>,
              <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleVaccineEOk()}>
                UPDATE
              </Button>,
            ]}
            width="520px"
          >
            <Form id="vaccineForm" className="edit-vaccine emrfrm emr-form" onFinish={this.handleVaccineEditSubmit} layout="vertical">
              <div className="row" style={{ marginLeft: "10px", marginRight: "10px" }}>
                <div className="col-4 hasdata antInputItem">
                  <Form.Item name="vacc-name" label="Vaccine Name">
                    <Input name="vacc-name" autoComplete="off" defaultValue={this.state.vaccName} disabled />
                  </Form.Item>
                </div>
                <div className="col-4 antInputItem hasdata">
                  <Form.Item name="item_name" label="Item Name">
                    <Select ref={this.vaccineItemRef} defaultValue={this.state.vaccItemId ? this.state.vaccItemId : "0"} disabled>
                      <option value="0">Select</option>
                      {itemData?.map((item, i) => {
                        let selected = false;
                        if (this.state.vaccItemId == item.id) {
                          selected = true;
                        }
                        return (
                          <>
                            {" "}
                            <option value={item.id} selected={selected}>
                              {item.name}
                            </option>{" "}
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-4 antInputItem hasdata">
                  <Form.Item name="give_on" label="Given On">
                    {
                      <DatePicker
                        ref={this.vaccineDateRef}
                        disabledDate={(date) => this.disabledDate(date)}
                        format={dateFormat}
                        defaultValue={this.state.vaccGivenon != null ? moment(this.state.vaccGivenon) : ""}
                      />
                    }
                  </Form.Item>
                </div>
                <div className={this.state.vaccBatch != "" ? "col-4 antInputItem hasdata" : "col-4 antInputItem"}>
                  <Form.Item name="batch" label="Batch">
                    <Input name="batch" autoComplete="off" defaultValue={this.state.vaccBatch} ref={this.vaccineBatchRef} />
                  </Form.Item>
                </div>
                <div className="col-4 antInputItem hasdata">
                  <Form.Item name="expire_on" label="Expiry Date">
                    {
                      <DatePicker
                        picker="month"
                        ref={this.vaccineExpireRef}
                        disabledDate={this.disabledPastDate}
                        format={dateFormat}
                        // format="MMM, YYYY"
                        defaultValue={this.state.vaccExpireon != null ? moment(this.state.vaccExpireon) : ""}
                      />
                    }
                  </Form.Item>
                </div>
                <div className="col-4 antInputItem">
                  <Form.Item name="give_out">
                    <Checkbox
                      ref={this.vaccineOutsideRef}
                      checked={this.state.vaccGivenoutside == 1 ? true : false}
                      style={{
                        lineHeight: "32px",
                      }}
                      value={this.state.vaccGivenoutside}
                      onChange={(e) =>
                        this.setState({
                          vaccGivenoutside: e.target.checked ? 1 : 0,
                        })
                      }
                    >
                      Given Outside
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className={this.state.vaccRemark != "" ? "col-4 antInputItem hasdata" : "col-4 antInputItem"}>
                  <Form.Item name="remark" label="Remark">
                    <Input
                      name="remark"
                      autoComplete="off"
                      ref={this.vaccineRemarkRef}
                      defaultValue={this.state.vaccRemark ? this.state.vaccRemark : ""}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        ) : null}

        <Modal
          id="alert_confirm_message_medicine"
          className="alert_confirm_message"
          title="Are you sure you want to delete medicine?"
          visible={this.state.isAlertMedicineMVisible}
          onOk={() => this.handleAlertMOk()}
          bodyStyle={{ padding: 0 + "px" }}
          onCancel={() => this.handleAlertMCancel()}
          closable={false}
          footer={[
            <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.handleAlertMCancel()}>
              CANCEL
            </Button>,
            <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleAlertMOk()}>
              DELETE
            </Button>,
          ]}
        ></Modal>

        <Modal
          id="alert_confirm_message"
          className="alert_confirm_message"
          // title='Are you sure you want to delete?'
          visible={this.state.isModal}
          // onOk={() => this.handleTestDelete()}
          bodyStyle={{ padding: "25px 10px" }}
          onCancel={() => this.setState({ isModal: false })}
          closable={false}
          footer={false}
          width="400px"
        >
          <div className="container-fluid emrfrm">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    id="template_name"
                    className="form-control"
                    placeholder="Care Protocol Name"
                    onChange={this.onChangeTemplateName}
                    name="template_name"
                    maxLength={80}
                  />
                  <label htmlFor="template_name">Care Protocol Name</label>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center mt-2">
                <Button key="back" className="btn btn-outline-secondary px-4 mr-2" onClick={() => this.setState({ isModal: false })}>
                  CANCEL
                </Button>
                <Button
                  key="submit"
                  className="btn btn-primary px-4"
                  type="primary"
                  disabled={this.state.savedisabled}
                  onClick={() => this.templateSubmit()}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          id="alert_confirm_message"
          className="alert_confirm_message"
          title="A minimum of one assessment and one test/medicine is required to save a prescription as care protocol."
          visible={this.state.unfulifilledModal}
          onOk={() => this.setState((prev) => ({ unfulifilledModal: !prev.unfulifilledModal }))}
          bodyStyle={{ padding: 0 + "px" }}
          onCancel={() => this.setState({ isDeleteModl: false })}
          closable={false}
          footer={[
            <Button
              key="back"
              className="btn btn-outline-secondary px-5 cancelOrderSet"
              onClick={() => this.setState((prev) => ({ unfulifilledModal: !prev.unfulifilledModal }))}
            >
              OK
            </Button>,
          ]}
        ></Modal>

        <Modal
          id="templateError"
          title={this.state.alertMessage}
          visible={this.state.istemplateErrorVisible}
          onOk={() => this.handleTemplateErrorOk()}
          onCancel={() => this.handleTemplateErrorCancel()}
          footer={[
            <Button type="button" className="btn btn-outline-primary px-5" onClick={() => this.handleTemplateErrorCancel()}>
              OK
            </Button>,
          ]}
        ></Modal>

        {/* ################################## For Modal ####################################  */}

        {/* For Modal Vitals  */}
        <Modal
          title={this.state.actionText + " Vitals"}
          visible={this.state.addVitalsModal}
          className="copy_vitalsModal emrfrm"
          onCancel={(e) => this.handleModalPopup(e, "addVitalsModal")}
          style={{ top: 40 }}
          footer={[
            <div className="col-12 my-1 text-center">
              <Button form="vitalForm1" type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                SAVE{this.state.spin_loading ? LOADER : ""}
              </Button>
            </div>,
          ]}
          width="840px"
          bodyStyle={{ paddingBottom: "10px" }}
        >
          <Form
            id="vitalForm1"
            className="add-vitals emrfrm emr-form"
            ref={this.vitalFormRef}
            validateMessages={HANDLEVALIDANTMESSAGES}
            onFinish={this.handleVitalAddSubmit}
            layout="vertical"
          >
            <div className="row">
              <div className="col-4">
                <Form.Item
                  name="vitalType"
                // label="Vital Group"
                >
                  <span className="d-none">{this.state.vitalsType}</span>
                  <div className="form-group mb-1 hasdata">
                    <Select name="vitalsType" id="vitalsType" value={this.state.vitalsType} onChange={this.handleVitalSelect}>
                      <Option value="0">Pediatric Vitals</Option>
                      <Option value="1">All Vitals</Option>
                    </Select>
                    <label htmlFor="vitalsType">Vital Group</label>
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-4 antInputItem">
                <Form.Item
                  name="V001"
                  label="Height"
                  rules={[
                    {
                      type: "number",
                      min: 20,
                      max: 299,
                      transform: function (value) {
                        return value === "" || value === undefined ? value : +value;
                      },
                    },
                  ]}
                >
                  <Input addonAfter={"cm"} name="V001" autoComplete="off" value={this.state.V001} onChange={this.handleOnChangeNumbers} />
                </Form.Item>
              </div>
              <div className="col-4 antInputItem">
                <Form.Item
                  name="V002"
                  label="Weight"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      max: 500,
                      transform: function (value) {
                        return value === "" || value === undefined ? value : +value;
                      },
                    },
                  ]}
                >
                  <Input addonAfter={"kg"} name="V002" autoComplete="off" value={this.state.V002} onChange={this.handleOnChangeNumbers} />
                </Form.Item>
              </div>
              {this.state.vitalsType === "1" ? (
                <>
                  <div className="col-4 antInputItem">
                    <Form.Item
                      name="V003"
                      label="BMI"
                      rules={[
                        {
                          type: "number",
                          min: 30,
                          max: 110,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input addonAfter={"kg/m2"} name="V003" autoComplete="off" value={this.state.V003} onChange={this.handleOnChangeNumbers} />
                    </Form.Item>
                  </div>
                  <div className="col-4 antInputItem">
                    <Form.Item
                      name="V004"
                      label="Temperature"
                      rules={[
                        {
                          type: "number",
                          min: 30,
                          max: 110,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input addonAfter={"F"} name="V004" autoComplete="off" value={this.state.V004} onChange={this.handleOnChangeNumbers} />
                    </Form.Item>
                  </div>
                  <div className="col-4 antInputItem multi-col">
                    <Form.Item
                      name="V005"
                      label="Blood Pressure"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 400,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <div className="multi-input-col-inner">
                        <div className="ant-input-wrapper ant-input-group">
                          <div className="ant-input">
                            <Input
                              name="V005"
                              autoComplete="off"
                              maxLength="3"
                              min="0"
                              max="400"
                              value={this.state.V005}
                              onChange={this.handleOnChangeNumbers}
                            />
                            <Input
                              name="V006"
                              autoComplete="off"
                              maxLength="3"
                              min="0"
                              max="400"
                              value={this.state.V006}
                              onChange={this.handleOnChangeNumbers}
                            />
                          </div>
                          <span className="ant-input-group-addon">mmHg</span>
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="col-4 antInputItem">
                    <Form.Item
                      name="V007"
                      label="SP02"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 100,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input addonAfter={"%"} name="V007" autoComplete="off" value={this.state.V007} onChange={this.handleOnChangeNumbers} />
                    </Form.Item>
                  </div>
                  <div className="col-4 antInputItem">
                    <Form.Item
                      name="V008"
                      label="Heart Rate"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 200,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input addonAfter={"bpm"} name="V008" autoComplete="off" value={this.state.V008} onChange={this.handleOnChangeNumbers} />
                    </Form.Item>
                  </div>
                  <div className="col-4 antInputItem">
                    <Form.Item
                      name="V009"
                      label="Pulse Rate"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 200,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input addonAfter={"bpm"} name="V009" autoComplete="off" value={this.state.V009} onChange={this.handleOnChangeNumbers} />
                    </Form.Item>
                  </div>
                  <div className="col-4 antInputItem">
                    <Form.Item
                      name="V010"
                      label="Blood Sugar Fasting"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 200,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input addonAfter={"mg/dL"} name="V010" autoComplete="off" value={this.state.V010} onChange={this.handleOnChangeNumbers} />
                    </Form.Item>
                  </div>
                  <div className="col-4 antInputItem">
                    <Form.Item
                      name="V011"
                      label="Blood Sugar Post Prandial"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 500,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value;
                          },
                        },
                      ]}
                    >
                      <Input addonAfter={"mg/dL"} name="V011" autoComplete="off" value={this.state.V011} onChange={this.handleOnChangeNumbers} />
                    </Form.Item>
                  </div>
                </>
              ) : null}
            </div>
          </Form>
        </Modal>

        {/* For Modal Assessment  */}
        {this.state.showAssessmentModal ? (
          <Modal
            title={this.state.actionText + " Assessment"}
            visible={this.state.showAssessmentModal}
            onCancel={(e) => this.handleModalPopup(e, "showAssessmentModal")}
            footer={false}
            width="850px"
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showAssessmentModal ? (
              <AssessmentForm
                formType={"copy-to-visit"}
                actionText={this.state.actionText}
                updateData={this.updateAssessmentData}
                autofocus={false}
                editData={this.state.editData}
                editIndex={this.state.editIndex}
                addedAssessmentList={this.state.addedAssessmentList}
                handleTemplateRadioSubmit={null}
                clearEdit={this.clearEdit}
              />
            ) : null}
          </Modal>
        ) : null}

        {/* For Modal Medicine  */}
        {this.state.showMedicineModal ? (
          <Modal
            title={this.state.actionText + " Medicine"}
            visible={this.state.showMedicineModal}
            onCancel={(e) => this.handleModalPopup(e, "showMedicineModal")}
            footer={false}
            width="1500px"
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showMedicineModal ? (
              <MedicineForm
                formType={"soap-modal"}
                actionText={this.state.actionText}
                updateData={this.updateMedicineData}
                autofocus={false}
                addedDrugsList={this.state.addedDrugsList}
                addedAssessmentList={this.state.addedAssessmentList}
                medicineFlag={"soapmodal"}
                editData={this.state.editData}
                editIndex={this.state.editIndex}
                constants={this.state.constants}
                patient={this.state.patient}
                clearEdit={this.clearEdit}
                polyPharmacyWarningShown={this.state.polyPharmacyWarningShown}
                updatepolyPharmacyWarningShown={this.polyPharmacyWarningShown}
              />
            ) : null}
          </Modal>
        ) : null}

        {/* For Modal Test  */}
        {this.state.showTestModal ? (
          <Modal
            title={this.state.actionText + " " + " Test/Procedure"}
            visible={this.state.showTestModal}
            onCancel={(e) => this.handleModalPopup(e, "showTestModal")}
            footer={false}
            width="850px"
          // bodyStyle={{ padding: "10px 20px" }}
          >
            {this.state.showTestModal ? (
              <TestForm
                formType={"copy-to-visit"}
                actionText={this.state.actionText}
                updateData={this.updateTestData}
                testAdvisedDuplicateOrders={this.state.testAdvisedDuplicateOrders}
                eventBased={this.state.isEventBased && this.state.isEventBased == 'Y' ? true : false}
                autofocus={false}
                editData={this.state.editData}
                editIndex={this.state.editIndex}
                addedTestList={this.state.addedTestList}
                constants={this.state.constants}
                clearEdit={this.clearEdit}
                hospital_id={this.state.hospital_id}
              />
            ) : null}
          </Modal>
        ) : null}

        {/* || DELETE MODAL */}
        <Modal
          visible={this.state.deleteAssessmentModal}
          onCancel={(e) => this.handleModalPopup(e, "deleteAssessmentModal")}
          style={{ top: 40 }}
          closable={false}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancel-delete-history"
                onClick={(e) => this.handleModalPopup(e, "deleteAssessmentModal")}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 submit-delete-history"
                disabled={this.state.spin_loading}
                id='deleteButton'
                onClick={(e) => this.handleDeletePopSubmit(e)}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        >
          <div className="row">
            <div className="col-12 text-center confirm-message">
              <h6 className="mb-0">Are you sure you want to delete assessment for {this.state.delete_text}?</h6>
            </div>
          </div>
        </Modal>

        {/* For Modal Delete Confirm  */}
        <Modal
          title={false}
          visible={this.state.deleteConfirmModal}
          onCancel={(e) => this.handleModalPopup(e, "deleteConfirmModal")}
          footer={false}
          closable={false}
        >
          <div className="row mb-3">
            <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
          </div>
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleModalPopup(e, "deleteConfirmModal")}>
              CANCEL
            </button>
            <button
              ref={this.deleteButton}
              type="button"
              className="btn btn-primary px-5"
              onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}
            >
              DELETE
            </button>
          </div>
        </Modal>

        <Modal
          title="View Supporting Material"
          visible={this.state.showPdfModal}
          footer={false}
          width={1200}
          onCancel={() => { this.setState({ showPdfModal: false, pdfPath: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {(this.state.showLoader) ? LOADER_RED :
                this.state.pdfPath != null ? <iframe src={this.state.pdfPath + '#toolbar=0'} frameBorder="0" style={{ overflow: 'hidden' }} width="100%" height="700">
                  {LOADER_RED}
                </iframe> : null}
            </div>
          </div>
        </Modal>

        {/** || isPracticing Modal */}
        <Modal title={false} closable={false} visible={this.state.isPracticingModal} footer={false}>
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to Start Practice?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={(e) => this.setState({ isPracticingModal: false })}>
              No
            </div>
            <div className="btn btn-primary px-5" onClick={this.enablePractice}>
              Yes
            </div>
          </div>
        </Modal>

        <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
          title="All Drug Interaction"
          visible={this.state.isModalAllInteractionVisible}
          onOk={() => this.handleAllInteractionOk()}
          onCancel={() => this.handleAllInteractionCancel()}
          footer={false}
          style={{ width: 2000 }}
          zIndex={9999}
        >
          <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${ALL_CIMS_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${this.state.medicineScreen}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
          {/* <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllInteractionCancel(e)}>DON’T PROCEED</button>
                  <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleAllInteractionOk(e)}>PROCEED TO ORDER</button>
              </div> */}
        </Modal>
      </>
    );
  }
}

export default ViewPrescriptionSS;
