import React, { Component } from 'react';
import HLogoImg from "../../assets/images/woodlands-logo.png";
// import CLogoImg from "../../assets/images/logo-myhealthcare.svg";
import CLogoImg from "../../assets/images/logo-myhealthcare-doctor.svg";
import chat from "../../assets/images/ic-chat-pubnub.svg"
import next from "../../assets/images/ic-next.svg"
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import API_SERVICE from '../../utils/apiService';
import VideoPlayer from 'react-video-js-player';
import {
  PRACTICE_STATUS,
  TOGGLE_PRACTICE,
  LOADER_RED,
  LOGOUT,
  USERTYPES,
  MYHC_APPTYPE,
  WOODLANDS,
  MHVCMARKETPLACE,
  MHEA,
  SOAP,
  APPS_FEATURES_LIST,
  CHAT_API,
  HOMECARE_VITALALERTLIST,
  GETGENDER,
  HOMECARE_PATIENTALERTLIST,
  HOMECARE_SAVE_VITAL_ALERT,
  HOMECARE_PATIENTLIST,
  CALL_HOMECARE_PATIENT,
  is_chat_enabled,
  IPD_APP_HOST
} from '../../utils/constant';
import { Link } from 'react-router-dom';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$, vcOpdEnabledSubjectService, assistanceNumberService, hideExtraTab} from '../../utils/rxjs-sharing';
import { practiceService, queuePracticeService } from '../../utils/rxjs-sharing';
import Axios from 'axios';
import { pubnubService$ } from '../../utils/pubnub-sharing';
import Moment from "react-moment";
import moment from 'moment';
import { Dropdown, Menu, Button, Badge } from 'antd';
import patientAlertTone from '../../assets/audio/alert-tone.mp3';
import vitalAlertTone from '../../assets/audio/vital-alert-tone.mp3';
import patientAlarmTone from '../../assets/audio/homecare_patient_alarm.wav';
// import { Modal,} from 'antd';
import { Modal } from "react-bootstrap";

import HelpVideo from './videos/OPDEMRtraining.mp4'
import VideocamIcon from '../../assets/images/videocam-icon.png'



class HeaderComponent extends Component {

  static propTypes = {
    location: PropTypes.object.isRequired
  }

  state = {
    showPatientQueue: false,
    practiceLoader: false,
    hospital_id: null,
    practicing: {
      status: false,
      hospital_id: null
    },
    isDoctorLoggedIn: false,
    soapConfig: null,
    pubNubAlerts: [],
    pubNubTotalCounts: 0,
    pubNubAlertToggle: false,
    vitalAlerts: [],
    patientAlerts: [],
    allHcAppts: [],
    is_vc_opd_enabled: 1,
    logo_src: null,
    helpModal: false,

    video: {
      src: HelpVideo,
      poster: "http://www.example.com/path/to/video_poster.jpg"
    },

    assistance_numer: null,
    hideTab: null
  }

  constructor(props) {
    super(props);
    this.togglePractise = this.togglePractise.bind(this);
    this._headerService = null;
    this.patientToneCount = 0;
    this.vitalToneCount = 0;
  }

  onPlayerReady(player) {
    // console.log("Player is ready: ", player);
    this.player = player;
  }

  onVideoPlay(duration) {
    // console.log("Video played at: ", duration);
  }

  onVideoPause(duration) {
    // console.log("Video paused at: ", duration);
  }

  onVideoTimeUpdate(duration) {
    // console.log("Time updated: ", duration);
  }

  onVideoSeeking(duration) {
    // console.log("Video seeking: ", duration);
  }

  onVideoSeeked(from, to) {
    // console.log(`Video seeked from ${from} to ${to}`);
  }

  onVideoEnd() {
    // console.log("Video ended");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location.pathname);
    }
  }

  onRouteChanged(route) {
    if (route.includes("patient-queue")) {
      this.setState({
        showPatientQueue: false
      });

      if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
        const arrRoute = route.split('/');
        const hid = arrRoute[arrRoute.length - 1].split('?')[0];
        this.checkPractiseStatus(hid);
      }
    } else {
      this.setState({
        showPatientQueue: true
      });
    }
  }

  async checkPractiseStatus(hid) {
    const practise = await API_SERVICE.post(PRACTICE_STATUS, { hospital_id: hid });
    if (practise?.status) {
      let status = false;
      if (typeof practise.is_practicing !== 'undefined' && practise.is_practicing === 'Y') {
        status = true;
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        LS_SERVICE.set('practicing', {
          status: true,
          hospital_id: hid
        });
      }
      else {
        LS_SERVICE.delete('practicing');
        practiceService.init({ initiater: 'SOAP Pages', status: 'stop' });
      }
      this.setState({
        showPatientQueue: false,
        practicing: {
          status: status,
          hospital_id: hid
        }
      });
    }
  }

  componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

    this.checkHospitalLogo();

    this.assistanceNumberServiceSubscription = assistanceNumberService.status().subscribe(async data => {
      let assistance_numer = data.assistance_detail && data.assistance_detail.assistance_number && data.assistance_detail.assistance_number != null ? parseInt(data.assistance_detail.assistance_number) : null;
      await this.setState({ assistance_numer })
      this.checkHospitalLogo();
    })

    this.hideExtraTabSubscription = hideExtraTab.status().subscribe(async data =>{
      let hide_tab = data.hideTab.name
      LS_SERVICE.set("hideTab",hide_tab)
      
    })
    
    if (LS_SERVICE.has('assistance_detail')) {
      let details = LS_SERVICE.get('assistance_detail');
      let assistance_numer = details && details.assistance_number && details.assistance_number != null ? details.assistance_number : null;
      this.setState({ assistance_numer })
    }
    this.onRouteChanged(this.props.location.pathname);
    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      const arrRoute = this.props.location.pathname.split('/');
      const hid = arrRoute[arrRoute.length - 4];
      if (hid)
        this.checkPractiseStatus(hid);
    }


    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      this.setState({ isDoctorLoggedIn: true });
    } else {
      this.setState({ isDoctorLoggedIn: false });
    }

    if (LS_SERVICE.has('soapConfig')) {
      this.setState({
        soapConfig: LS_SERVICE.get('soapConfig')
      });
    }

    if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      this.vcOpdEnabledServiceSubscription = vcOpdEnabledSubjectService.status().subscribe(data => {
        this.setState({ is_vc_opd_enabled: data.isVcOpdEnabled });
      })
    }

    // if (LS_SERVICE.has('soapConfigData')) {
    //   const { location } = props;
    //   const splitData = location.pathname.split('/');
    //   const reference_id = splitData[splitData.length - 1];
    //   const soapConfigData = LS_SERVICE.get('soapConfigData');
    //   const isSoapOrBasic = soapConfigData.find(scd => scd.reference_id === +reference_id).soapConfig;
    //   this.setState({
    //     soapConfig: isSoapOrBasic
    //   });
    // }


    /*this.subscription = practiceService.status().subscribe(result => {
      this.togglePractise(result.status);
    });*/
    this._headerService = headerService$.status().subscribe(success => {
      if (success.soapConfig) {
        this.setState({
          soapConfig: success.soapConfig
        })
      }
      if (success.setHospital) {
        this.setState({
          hospital_id: success.setHospital
        }, this.headerAlertApi)
      }
    });

    if (is_chat_enabled) {
      this.bookingListGetApi();

      this.handlePubNubServiceSubscription = pubnubService$.pubnub_observe().subscribe(success => {
        if (success.pubnub_message_counts) {
          const { pubNubAlerts } = this.state;

          let pubNubTotalCounts = 0;

          pubNubAlerts.forEach(e => {
            e.unread_count = success.pubnub_message_counts[e.booking_id];
            pubNubTotalCounts += +e.unread_count;
          })

          this.setState({
            pubNubAlerts,
            pubNubTotalCounts
          })
        }

        if (success.pubnub_notification_update) {
          this.bookingListGetApi()
        }
      })
    }

    if (LS_SERVICE.has('slot_hospital_id')) {
      this.setState({
        hospital_id: LS_SERVICE.get('slot_hospital_id')
      }, () => this.headerAlertApi())
    }
  }

  componentWillUnmount() {
    this.vcOpdEnabledServiceSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
    this._headerService?.unsubscribe();
    this.assistanceNumberServiceSubscription?.unsubscribe();
    this.hideExtraTabSubscription?.unsubscribe();
    clearTimeout(this.bookingPubnubChatListSubscription);
    if (is_chat_enabled) this.handlePubNubServiceSubscription?.unsubscribe();
  }

  async togglePractise(status) {
    this.setState({ practiceLoader: true });

    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.practicing.hospital_id,
      practice_session: status
    });
    // console.log('>> practice', practice);

    let is_practicing;
    if (practice.data.is_practicing === 'Y') {
      is_practicing = true;
    } else {
      is_practicing = false;
    }

    this.setState({
      practiceLoader: false,
      practicing: {
        status: is_practicing,
        hospital_id: this.state.practicing.hospital_id
      }
    });

    if (is_practicing) {
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.practicing.hospital_id
      });
      queuePracticeService.init({ headerStart: true });
    } else {
      LS_SERVICE.delete('practicing');
      queuePracticeService.init({ headerStart: false });
    }
  }

  //Check Hospitals and set logo as per associations
  checkHospitalLogo = async () => {
    let logo_source = CLogoImg;
    let allHospitals = LS_SERVICE.get('slot_hospitals');
    let facility_id = LS_SERVICE.get('FACILITY_ID');
    var this_facility = allHospitals?.find(o => o.id === facility_id);
    if(this_facility && this_facility.hospital_image && this_facility.hospital_image != null) {
      logo_source = this_facility.hospital_image
    }
    else if(allHospitals?.length == 1){
      logo_source = allHospitals[0].hospital_image
    }
    this.setState({ logo_src: logo_source });
  }

  headerAlertApi = _ => {
    var url;
    var config = LS_SERVICE.get('emr_server_config');
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
      // NO ALERT
    }
    else{
      const { hospital_id } = this.state;
      const vitalAlertUri = Axios.get(HOMECARE_VITALALERTLIST({ hospital_id, doctor_id: LS_SERVICE.get('staff_id') }));
      const patientAlertUri = Axios.get(HOMECARE_PATIENTALERTLIST({ doctor_id: LS_SERVICE.get('staff_id') }));

      Axios.all([vitalAlertUri, patientAlertUri])
        .then(success => {

          const vitalAlerts = success[0]?.data.alerts;
          const patientAlerts = success[1]?.data.alerts;

          this.setState({
            vitalAlerts,
            patientAlerts,
          }, () => {
            if (LS_SERVICE.get('user_type') === USERTYPES.doctor || LS_SERVICE.get('user_type') === USERTYPES.nurse) {
              this.patientAlertTone();
              this.vitalAlertTone();
            }
          });

          this.headerAlertTimer = setTimeout(() => {
            this.headerAlertApi();
            clearTimeout(this.headerAlertTimer);
          }, 15000)
        })
    }
  }

  bookingListGetApi = () => {
    if (LS_SERVICE.has('is_authenticated')) {
      Axios.get(CHAT_API)
        .then(success => {
          pubnubService$.pubnub_active_chats({ pubnubActiveChatData: success.data?.chat_bookings });

          success.data?.chat_bookings?.map(e => e['unread_count'] = 0);

          this.setState({
            pubNubAlerts: success.data.chat_bookings
          })

          this.bookingPubnubChatListSubscription = setTimeout(() => {
            this.bookingListGetApi();
          }, 15000);
        })
    }
  }

  handlePubNubAlertClick = (el, alert) => {

    el.preventDefault();

    const data = {
      id: alert.booking_id,
      patient: {
        dob: alert.dob,
        first_name: alert.first_name,
        gender: alert.gender,
        last_name: alert.last_name,
        uhid: null
      },
    };

    pubnubService$.pubNubBooking_data({ data, show: true, alert: true });

    this.setState({
      pubNubAlertToggle: !this.state.pubNubAlertToggle
    })
  }

  handleLogout = async (e) => {
    e.preventDefault();
    await API_SERVICE.post(TOGGLE_PRACTICE, {
      practice_session: 'stop'
    });
    API_SERVICE.get(LOGOUT)
      .then(success => {
        headerService$.showHeader({ showHeader: 'false' });
        var userType = LS_SERVICE.get("user_type");
        LS_SERVICE.clear();
        // Fallback to IPD dashboard if logout of OPD is hit
        window.open(IPD_APP_HOST + 'logout', '_self');
        if (userType == USERTYPES.superadmin || userType == USERTYPES.admin) {
          this.props.history.push('/admin');
        } else {
          this.props.history.push('/');
        }
      })
  }

  handleShowFullheader = _ => {
    let hideTab = LS_SERVICE.get('hideTab');
    if (MYHC_APPTYPE === WOODLANDS) return true;
    // if (hideTab == "homecare_tabs") return false;
    if (MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA) {
      const { soapConfig } = this.state;
      const { location: { pathname } } = this.props;
      if (pathname !== '/dashboard' && soapConfig === SOAP) return true;
      if (pathname === '/dashboard') return false;
    }
  }

  patientAlertTone = () => {
    const { patientAlerts } = this.state
    // let audio = new Audio();
    // audio.src = patientAlertTone;

    let count = LS_SERVICE.has('patient_alert_count') ? LS_SERVICE.get('patient_alert_count') : 0;

    let audio = document.getElementById('patient_alarm');

    if (patientAlerts.length > 0) {
      if (count < this.patientToneCount) this.patientToneCount = count;
      if (patientAlerts.length > this.patientToneCount) {
        audio.play();
        this.patientToneCount = patientAlerts.length;
        LS_SERVICE.set('patient_alert_count', this.patientToneCount);
      }
    } else {
      this.patientToneCount = 0
    }
  }

  vitalAlertTone = () => {

    const { vitalAlerts } = this.state
    let audio = new Audio();
    audio.src = vitalAlertTone;

    if (vitalAlerts.length != this.vitalToneCount) {
      //audio.play();
      this.vitalToneCount = vitalAlerts.length;
    }
  }

  handleAlertClick = async (data) => {

    const { patient: { id }, test_booking_id, hospital_id } = data;
    var allHcAppts = await Axios.get(HOMECARE_PATIENTLIST({ booking_status: 1, hospital_id: hospital_id, date: moment().format('YYYY-MM-DD'), page:1, per_page:100}));
    var allHcApptsList = allHcAppts?.data?.appointments || []
    var patientData = allHcApptsList?.find(o => o.id === test_booking_id);

    var patient = patientData.patient

    var PARAMS = {
      patient_id: patient.id,
      doctor_id: patientData?.doctor?.id,
      hospital_id: hospital_id,
      reference_id: test_booking_id,
      reference_type: 2,
      patient: patient
    }
    var call_patient_detail = {
      address: patientData?.patient?.address_1,
      booking_code: patientData?.booking_code,
      booking_date: patientData?.booking_date,
      booking_id: patientData?.id,
      booking_status: patientData?.booking_status,
      dob: patientData?.patient?.dob,
      doctor_id: patientData?.doctor?.id,
      doctor_name: patientData?.doctor?.name,
      fullname: patientData?.patient?.full_name,
      gender: patientData?.patient?.genderyy,
      hospital_id: patientData?.test?.hospital_id,
      list: "Homecare",
      mobile_no: patientData?.patient?.mobile_no,
      patient_first_name: patientData?.patient?.first_name,
      patient_id: patientData?.patient?.id,
      patient_last_name: patientData?.patient?.last_name,
      patient_middle_name: patientData?.patient?.middle_name,
      patient_type: patientData?.patient?.patient_type,
      qms_token_id: patientData?.id,
      uhid: patientData?.patient?.uhid,
    }
    LS_SERVICE.set('call_patient_detail', call_patient_detail);
    LS_SERVICE.set('call_patient_id', patientData?.patient?.id);
    LS_SERVICE.set('call_patient_qms_token_id', patientData?.id);
    LS_SERVICE.set('reference_type',2);
    LS_SERVICE.set('reference_id',test_booking_id);
    Axios.post(CALL_HOMECARE_PATIENT, PARAMS).then(response => {
      LS_SERVICE.set('last_called_homecare',patientData);
    }).catch(error => {
      console.log(error)
    });

    let audio = document.getElementById('patient_alarm');
    audio.pause();

    this.props.history.push({
      pathname: `/patient/homecare/details/${hospital_id}/${id}/2/${test_booking_id}`,
      state: { isCallToday: 0 }
    });
  }

  alertVitalSave = (e, data) => {

    e.preventDefault();
    e.stopPropagation();

    const { is_doctor } = this.state;

    const { alert_id } = data;

    const PARAMS = {
      clear_alert: 1,
      alert_id
    }

    Axios.post(HOMECARE_SAVE_VITAL_ALERT({ is_doctor }), PARAMS)
      .then((res) => {
        this.vitalToneCount -= 1;  //for tone
        this.headerAlertApi();
      })
  }
  checkAssistanceNumber(id = null) {
    if (this.state.assistance_numer == null) {
      let details = LS_SERVICE.get('assistance_detail');
      let assistance_numer = details && details.assistance_number && details.assistance_number != null ? details.assistance_number : null;
      this.setState({ assistance_numer })
    }
    if (id != null) {
      let allHospitals = LS_SERVICE.get('slot_hospitals');
      let hospital_is = allHospitals.filter(function (data) {
        return data.id == id;
      });
      let logo_source = hospital_is[0].hospital_image !== "" ? hospital_is[0].hospital_image : CLogoImg;
      let number_is = hospital_is[0].assistance_number != null ? parseInt(hospital_is[0].assistance_number) : null;
      let name_is = hospital_is[0].name != null ? hospital_is[0].name : "";
      this.setState({ logo_src: logo_source, assistance_numer: number_is });
      let assistance_detail = {
        hospital_name: name_is,
        assistance_number: number_is
      }
      LS_SERVICE.set('assistance_detail', assistance_detail);
      LS_SERVICE.set('hideTab', null);

    }
  }
  deleteAssistanceNumber() {
    LS_SERVICE.set('assistance_detail', {});
    this.setState({ assistance_numer: null, logo_src: CLogoImg });
    LS_SERVICE.set('hideTab', null);

  }

  ChangeAssistanceNumber(id) {
    let assistance_detail = {};
    assistance_detail.hospital_name = "";
    assistance_detail.assistance_number = id;
    assistanceNumberService.init({ assistance_detail })
    LS_SERVICE.set('assistance_detail', assistance_detail);
  }

  render() {

    const { pubNubAlerts, pubNubTotalCounts, vitalAlerts, patientAlerts, assistance_numer, hideTab } = this.state;
   
    const vitalAlertsMenu = (
      <Menu className="alert-dropdown">
        <div className="alert-modal vitals modal-header bg-primary dropdown-header" role="heading">
          <p className="modal-title font-weight-bold text-white h6">
            VITALS ALERTS <span className="h5">{vitalAlerts.length}</span>
          </p>
        </div>
        {/* <div className="ad-listwrapper"> */}
        {vitalAlerts.slice(0).reverse().map((vA, i) => (
          <Menu.Item key={i} onClick={() => this.handleAlertClick(vA, 'vital')}>
            {/* <a href="#" className="px-3 py-2 border-bottom dropdown-item" role="button"> */}
            <div className="row">
              <div className="col-md-4">
                <p className="p-o  m-0 ">
                  {vA.patient.full_name !== undefined ? vA.patient.full_name : vA.patient.first_name !== undefined ? vA.patient.first_name : ''}
                </p>
                <p className="p-o  m-0 text-grey">{moment().diff(vA.patient.dob, 'years')}, {GETGENDER(vA.patient.gender)} {vA.patient.uhid !== null ? '| ' + vA.patient.uhid : ''} {vA.patient.relation !== null ? '| ' + vA.patient.relation : ''}</p>
              </div>
              <div className="col-md-4">
                <p className="float-right pt-3">
                  <span className="float-right">
                    {moment(vA.alert_date + ' ' + vA.alert_time).format('D MMM, Y | h:mm A')}
                  </span>
                </p>
              </div>
              <div className="col-md-4">
                <button className="btn btn-outline-primary custom float-right py-0 mt-3"
                  onClick={(eve) => this.alertVitalSave(eve, vA)}>
                  ACKNOWLEDGE
                </button>
              </div>
            </div>
            {/* </a> */}
          </Menu.Item>
        ))}
        {/* </div> */}
      </Menu>
    )

    const patientAlertsMenu = (
      <Menu className="alert-dropdown">
        <div className="alert-modal vitals modal-header bg-warning dropdown-header" role="heading">
          <p className=" modal-title font-weight-bold h6">
            PATIENT ALERTS <span className="h5">{patientAlerts.length}</span>
          </p>
        </div>
        {/* <div className="ad-listwrapper"> */}
        {patientAlerts.map((pA, i) => (
          <Menu.Item key={i} onClick={() => this.handleAlertClick(pA, 'patient')}>
            {/* <a href="#" className="px-3 py-2 border-bottom dropdown-item" role="button"> */}
            <div className="row">
              <div className="col-md-6">
                <p className="p-o m-0">
                  {pA.patient.full_name !== undefined ? pA.patient.full_name : pA.patient.first_name !== undefined ? pA.patient.first_name : ''}
                </p>
                <p className="p-o m-0 text-grey">{moment().diff(pA.patient.dob, 'years')}, {GETGENDER(pA.patient.gender)} {pA.patient.uhid !== null ? '| ' + pA.patient.uhid : ''} {pA.patient.relation !== null ? '| ' + pA.patient.relation : ''}</p>
              </div>
              <div className="col-md-6">
                <p className="float-right pt-3">
                  <span className="float-right">
                    {moment(pA.alert_date + ' ' + pA.alert_time).format('D MMM, Y | h:mm A')}
                  </span>
                </p>
              </div>
            </div>
            {/* </a> */}
          </Menu.Item>
        ))}
        {/* </div> */}
      </Menu>
    )
    var config = LS_SERVICE.get('emr_server_config');
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];

    return (
      <>
        <header id="page_header">
          <nav className="navbar navbar-expand navbar-light bg-white">
            <NavLink className="navbar-brand" to={{
              pathname: '/dashboard'
            }}>
              {/* <img src={CLogoImg} alt="EMR Logo" className="img-fluid" /> */}
              <img src={this.state.logo_src} alt="EMR Logo" className="img-fluid" />
            </NavLink>
            <div className="collapse navbar-collapse">
              {/* /////////////////////////////////// TO BE DELETED - START /////////////////////////////////// */}
              {/* <h1 className="col-8 align-items-center">
                  {LS_SERVICE.get('user_type') == USERTYPES.superadmin ? (<div>{LS_SERVICE.get('user_type')}: Super admin &#128520;</div>):null}
                  {LS_SERVICE.get('user_type') == USERTYPES.admin ? (<div>{LS_SERVICE.get('user_type')}: Hospital admin &#128526;</div>):null}
                  {LS_SERVICE.get('user_type') == USERTYPES.doctor ? (<div>{LS_SERVICE.get('user_type')}: Doctor &#129299;</div>):null}
                </h1> */}
              {/* /////////////////////////////////// TO BE DELETED - END /////////////////////////////////// */}
              {(assistance_numer != null  && assistance_numer != undefined) ?
                <div className='d-flex align-items-center  pl-3'>
                  <span><i className="icon_phone_gray pt-1" style={{ color: '#ccc' }}></i></span>
                  <span className='text-dark'>Helpline :&nbsp;</span>
                  <a className='text-secondary' href={`tel:+${assistance_numer}`} style={{ fontWeight: '500' }}>{assistance_numer}</a>
                </div> : null}
              <ul className="navbar-nav ml-auto align-items-center">
                {LS_SERVICE.get('user_type') === USERTYPES.doctor || LS_SERVICE.get('user_type') === USERTYPES.nurse ? (
                  <>
                    {vitalAlerts?.length > 0 && (
                      <Dropdown overlay={vitalAlertsMenu} trigger={['click']} placement="bottomRight">
                        <Button type="primary" className="btn btn-alert alert-button mr-3" onClick={e => e.preventDefault()}>
                          <Badge count={vitalAlerts.length} style={{ backgroundColor: '#5c5c5c' }}>
                            <a href="#" className="head-example" />
                          </Badge>
                          VITALS ALERTS
                        </Button>
                      </Dropdown>
                    )}
                  </>
                ) : null}

                {LS_SERVICE.get('user_type') === USERTYPES.doctor || LS_SERVICE.get('user_type') === USERTYPES.nurse ? (
                  <>
                    {patientAlerts?.length > 0 && (
                      <Dropdown overlay={patientAlertsMenu} trigger={['click']} placement="bottomRight">
                        <Button className="btn btn-warning alert-button mr-2" onClick={e => e.preventDefault()}>
                          <Badge count={patientAlerts.length} style={{ backgroundColor: '#5c5c5c' }}>
                            <a href="#" className="head-example" />
                          </Badge>
                          PATIENT ALERTS
                        </Button>
                      </Dropdown>
                    )}
                  </>
                ) : null}

                {pubNubAlerts?.length > 0 ? (
                  <li className="nav-item align-self-center">
                    <a className="nav-link" href="#" id="pubnub-dropdown-item" data-toggle="dropdown" aria-expanded="false" style={{ position: 'relative' }}>
                      <span className={`${pubNubTotalCounts > 0 ? 'alert-notify' : 'd-none'}`} id="pb_alertNotify"
                        style={{
                          position: 'absolute',
                          backgroundColor: '#666666',
                          color: '#ffffff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          padding: '0 7px',
                          fontSize: '15px',
                          top: '-5px',
                          right: '10px',
                          fontWeight: 700
                        }} >{pubNubTotalCounts}</span>
                      <img src={chat} alt="Notification icon" className="img-fluid mr-3" style={{ width: '30px' }} />
                    </a>
                    <div className="dropdown-menu p-0 m-0" style={{ position: 'absolute', right: '217px', width: '550px' }} aria-labelledby="pubnub-dropdown-item">
                      <div className="alert-modal vitals modal-header dropdown-header" role="heading" style={{ backgroundColor: '#d24350', color: '#ffffff', padding: '.6rem', borderRadius: 0 }}>
                        <p className=" modal-title font-weight-bold text-white h6">Chat with Patients</p>
                      </div>
                      <div className="navdrop_itemwrapper pubnub">
                        {pubNubAlerts?.map((item, i) => (
                          <a href="#" key={i} className="px-3 py-2 border-bottom dropdown-item" role="button" onClick={(el) => this.handlePubNubAlertClick(el, item)} >
                            <div className="row">
                              <div className="d-flex justify-content-between col">
                                <div className="chat_row_left">
                                  <p className="p-o m-0 font-weight-bold ">{item.patient_name}</p>
                                  <p className="p-o m-0  d-flex">
                                    <span style={{ color: '#666666' }}>Appointments : &nbsp;</span>

                                    <span className="text-dark">
                                      <Moment format="D MMM, Y | h:mm A" parse="YYYY-MM-DD HH:mm" className="text-dark">
                                        {item.booking_date} {item.booking_time}
                                      </Moment>
                                    </span>
                                  </p>
                                </div>
                                <div className="chat_row_right d-flex  align-items-center ">
                                  <div className={`${item.unread_count === 0 ? 'd-none' : ''}`}>{item.unread_count}</div>

                                  <img src={next} alt="next-arrow" />
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                        {/* <a href="#" className="px-3 py-2 border-bottom dropdown-item" role="button" onclick="event.preventDefault(); $().pubnubModal(`{&quot;booking_id&quot;:8712,&quot;patient_name&quot;:&quot;Test P patient&quot;,&quot;last_name&quot;:&quot;p patient&quot;,&quot;first_name&quot;:&quot;test&quot;,&quot;gender&quot;:&quot;Male&quot;,&quot;dob&quot;:&quot;1994-09-03&quot;,&quot;patient_id&quot;:15849,&quot;doctor_id&quot;:1686,&quot;booking_code&quot;:&quot;BANYM6F7ED&quot;,&quot;booking_date&quot;:&quot;2021-01-26&quot;,&quot;booking_time&quot;:&quot;12:10:00&quot;,&quot;timestamp&quot;:null,&quot;is_chat&quot;:1,&quot;unread_count&quot;:0}`, true)">
                          <div className="row">
                            <div className="d-flex justify-content-between col">
                              <div className="chat_row_left">
                                <p className="p-o m-0 font-weight-bold ">test p patient</p>
                                <p className="p-o m-0  d-flex">
                                  <span style={{ color: '#666666' }}>Appointments : &nbsp;</span>

                                  <span className="text-dark">2021-01-2612:10:00</span>
                                </p>
                              </div>
                              <div className="chat_row_right d-flex  align-items-center ">
                                <div data-channel={8712} />
                                <img src={next} alt="next-arrow" />
                              </div>
                            </div>
                          </div>
                        </a> */}
                      </div>
                    </div>
                  </li>
                ) : null}
                <li className="nav-item align-self-center dropdown">
                  <a className="nav-link" href="#" id="user_dropdown_btn" data-toggle="dropdown">
                    <span className="d-none d-md-inline dropdown-toggle mr-2">
                      {
                        LS_SERVICE.get('user_type') === USERTYPES.doctor ? 
                        (LS_SERVICE.get('title') !== null ? LS_SERVICE.get('title') + ". " : "Dr. ") + LS_SERVICE.get('staff_name') 
                        : LS_SERVICE.get('staff_name')
                      }
                    </span>
                    {/* <img src={LS_SERVICE.get('staff_image')} alt="User Image" className="img-fluid profile-image" /> */}
                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      null
                    :
                      <img src={LS_SERVICE.get('staff_image')} alt="User Image" className="img-fluid profile-image" />
                    }
                  </a>
                  <div className="dropdown-menu" aria-labelledby="user_dropdown_btn">
                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      null
                    :
                      <>
                        {LS_SERVICE.get('user_type') == USERTYPES.doctor ? <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/profile'>Profile</NavLink> : null}
                      </>
                    }
                    {/* <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/change-password'>Change Password</NavLink> */}
                    <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/' onClick={this.handleLogout}>Logout</NavLink>
                  </div>
                </li>
              </ul>
            </div>
            {APPS_FEATURES_LIST.header_powered_by && <div className="poweredby">
              Powered By
              <NavLink exact={true} to='/'>
                <img src={CLogoImg} alt="Powered By - MyHealthcare" className="img-fluid" />
              </NavLink>
            </div>}
          </nav>
          <nav className="navbar navbar-expand-lg navbar-dark mb-2">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="main_menu">
              <ul className="navbar-nav mr-auto smooth-scroll">
                <li className="nav-item">
                  <a href={IPD_APP_HOST + `WorkList`} target="_self" activeClassName='active' className="nav-link">
                    My Desk
                  </a>
                </li>

                {LS_SERVICE.get('user_type') === USERTYPES.doctor ? (
                  <>
                    <li className="nav-item">
                      <NavLink exact={true} activeClassName='active' className="nav-link" to='/dashboard' onClick={() => this.deleteAssistanceNumber()}>Dashboard</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink exact={true} activeClassName='active' className="nav-link" to='/patient-search' onClick={() => this.deleteAssistanceNumber()}>Search Patient</NavLink>
                    </li>
                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      null
                    :
                      <>
                        <li className="nav-item">
                          <NavLink exact={true} activeClassName='active' className="nav-link" to='/my-calendar' onClick={() => this.deleteAssistanceNumber()} >My Calendar</NavLink>
                        </li>
                      </>
                    }
                  </>) : null}


                {LS_SERVICE.get('user_type') === USERTYPES.nurse ? (
                  <>
                    <li className="nav-item">
                      <NavLink exact={true} activeClassName='active' className="nav-link" to={'/patient-queue/' + LS_SERVICE.get('slot_hospital_id')}>Patient Queue</NavLink>
                    </li>
                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      null
                    :
                      <>
                        {this.state.is_vc_opd_enabled ? (
                          <li className="nav-item">
                            <NavLink exact={true} activeClassName='active' className="nav-link" to={'/patient-late-alert'}>Late Alert</NavLink>
                          </li>
                        ) : null}
                      </>
                    }
                  </>
                ) : null}


                {LS_SERVICE.get('user_type') === USERTYPES.admin || LS_SERVICE.get('user_type') === USERTYPES.superadmin ? (
                  <>
                    <li className="nav-item dropdown menu-dropdown">
                      {/* <a className="nav-link dropdown-toggle" data-toggle="dropdown" id="template_menu" href="#" aria-expanded="false">Templates</a> */}
                      <NavLink activeClassName='active' className="nav-link dropdown-toggle" data-toggle="dropdown"  to="#" id="template_menu"
                        isActive={(match, location) => {
                          const { pathname } = location;
                          if (pathname.includes('template-list') || pathname.includes('create-template')) return true;
                        }}
                      >
                        Care Protocol
                      </NavLink>
                      <div className="dropdown-menu" aria-labelledby="template_menu">
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/template-list' >Care Protocol List</NavLink>
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/create-template'>Create Care Protocol</NavLink>
                      </div>
                    </li>
                    <li className="nav-item dropdown menu-dropdown">
                      {/* <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="orderset_menu">Order Sets</a> */}
                      <NavLink activeClassName='active' className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" id="orderset_menu"
                        isActive={(match, location) => {
                          const { pathname } = location;
                          if (pathname.includes('order-set-list') || pathname.includes('create-order-set')) return true;
                        }}
                      >
                        Order Sets
                      </NavLink>
                      <div className="dropdown-menu" aria-labelledby="orderset_menu">
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/order-set-list'>Order Set List</NavLink>
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/create-order-set'>Create Order Set</NavLink>
                      </div>
                    </li>
                    {/*    <li className="nav-item">
                   <NavLink exact={true} activeClassName='active' className="nav-link" to='/soap-configuration'>SOAP Configuration</NavLink>
                 </li> */}
                  </>) : null}
                {LS_SERVICE.get('user_type') === USERTYPES.admin || LS_SERVICE.get('user_type') === USERTYPES.superadmin ? (
                  <>
                    <li className="nav-item dropdown menu-dropdown">
                      <NavLink activeClassName='active' className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" id="intuitive_menu"
                        isActive={(match, location) => {
                          const { pathname } = location;
                          if (pathname.includes('intuitive')) return true;
                        }}
                      >
                        Intuitive Data
                      </NavLink>
                      <div className="dropdown-menu" aria-labelledby="intuitive_menu">
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/intuitive-exclusion'>Exclusion</NavLink>
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/intuitive-negetive'>Negetive</NavLink>
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/intuitive-split-word'>Split Word</NavLink>
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/intuitive-varients'>Word Varients</NavLink>
                        <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/diagnosis-keyword'>Diagnosis Keywords</NavLink>
                      </div>
                    </li>
                  </>) : null}
                {this.handleShowFullheader() ? (
                  <>
                    {MYHC_APPTYPE === WOODLANDS ? (
                      <li className="nav-item">
                        <NavLink exact={true} activeClassName='active' className="nav-link" to='/late-alert'>Late Alert</NavLink>
                      </li>
                    ) : null}
                    {LS_SERVICE.get('user_type') == USERTYPES.doctor ? (
                      <>
                        <li className="nav-item dropdown menu-dropdown">
                          {/* <a className="nav-link dropdown-toggle" data-toggle="dropdown" id="template_menu" href="#" aria-expanded="false">Templates</a> */}
                          <NavLink activeClassName='active' className="nav-link dropdown-toggle" data-toggle="dropdown"   to="#" id="template_menu"
                            isActive={(match, location) => {
                              const { pathname } = location;
                              if (pathname.includes('template-list') || pathname.includes('create-template')) return true;
                            }}
                          >
                            Care Protocol
                          </NavLink>
                          <div className="dropdown-menu" aria-labelledby="template_menu">
                            <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/template-list' onClick={() =>this.checkAssistanceNumber()}>Care Protocol List</NavLink>
                            <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/create-template' onClick={() =>this.checkAssistanceNumber()}>Create Care Protocol</NavLink>
                          </div>
                        </li>
                        <li className="nav-item dropdown menu-dropdown">
                          {/* <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="orderset_menu">Order Sets</a> */}
                          <NavLink activeClassName='active' className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" id="orderset_menu"
                            isActive={(match, location) => {
                              const { pathname } = location;
                              if (pathname.includes('order-set-list') || pathname.includes('create-order-set')) return true;
                            }}
                          >
                            Order Sets
                          </NavLink>
                          <div className="dropdown-menu" aria-labelledby="orderset_menu">
                            <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/order-set-list' onClick={() =>this.checkAssistanceNumber()}>Order Set List</NavLink>
                            <NavLink exact={true} activeClassName='active' className="dropdown-item" to='/create-order-set' onClick={() =>this.checkAssistanceNumber()}>Create Order Set</NavLink>
                          </div>
                        </li>
                        <li className="nav-item">
                          <NavLink exact={true} activeClassName='active' className="nav-link" to='/soap-configuration' onClick={() =>this.checkAssistanceNumber()}>EMR Configuration</NavLink>
                        </li>
                        {/* <li className="nav-item">
                          <NavLink exact={true} activeClassName='active' className="nav-link" to='/soap-configuration-list'>SOAP Configuration New</NavLink>
                        </li> */}
                      </>) : null}
                  </>
                ) : null}
              </ul>

              {/* Help button        */}
              <button className="btn btn-primary btn-sm mr-4" onClick={(e) => this.setState({ helpModal: true })}>
                <div className='d-flex align-items-centers'>
                  <div>HELP </div>
                  <div>
                    <img src={VideocamIcon} alt='vid' className='ml-2' style={{ width: '19px', height: '11px' }}></img>
                  </div>
                </div>
                {/* <i className="his-icon icon-videocam ml-2" style={{fontSize:'12px'}}></i> */}

              </button>

              {this.state.isDoctorLoggedIn ? (
                <ul className="navbar-nav  mr-3" id="start_stop_practice">
                  <li className="nav-item p-0">

                    {this.state.practiceLoader ? <button className="btn btn-sm btn-primary text-uppercase">
                      {LOADER_RED}
                    </button> : null}

                    {this.state.showPatientQueue ? <Link id={`${LS_SERVICE.get('slot_hospital_id')}`} to={`/patient-queue/${LS_SERVICE.get('slot_hospital_id')}`} onClick={()=>this.checkAssistanceNumber(parseInt(LS_SERVICE.get('slot_hospital_id')))} className="btn btn-sm btn-primary text-uppercase">
                      Patient Queue
                    </Link> : null}

                    {!this.state.showPatientQueue && !this.state.practiceLoader && !this.state.practicing.status ? <button onClick={() => this.togglePractise('start')} className="btn btn-sm btn-primary text-uppercase">
                      Start Practice
                    </button> : null}

                    {!this.state.showPatientQueue && !this.state.practiceLoader && this.state.practicing.status ? <button onClick={() => this.togglePractise('stop')} className="btn btn-sm btn-primary text-uppercase">
                      Stop Practice
                    </button> : null}
                  </li>
                </ul>) : null}

            </div>
          </nav>
        </header>

        <audio src={patientAlarmTone} id="patient_alarm" loop controls className="d-none" />


        <Modal dialogClassName="modal-xxl modal-video modal-dialog-centered"
          show={this.state.helpModal}
          onHide={(e) => { this.setState({ helpModal: false }) }}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body className="px-2 py-3  d-flex justify-content-center">
            <div className='d-flex justify-content-center'>
              <VideoPlayer
                controls={true}
                src="https://myhealthcare-his.s3.ap-south-1.amazonaws.com/training_video/OPD+EMR+training.mp4"
                poster={this.state.video.poster}
                width="992"
                height="520"
                onReady={this.onPlayerReady.bind(this)}
                onPlay={this.onVideoPlay.bind(this)}
                onPause={this.onVideoPause.bind(this)}
                onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                onSeeking={this.onVideoSeeking.bind(this)}
                onSeeked={this.onVideoSeeked.bind(this)}
                onEnd={this.onVideoEnd.bind(this)}
              />
            </div>

          </Modal.Body>
        </Modal>

      </>



    )
  }
}

export default withRouter(HeaderComponent);